import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const rejectDriveInvite = createAsyncThunk('rejectDriveInvite', async (driveId, { rejectWithValue }) => {

    try {
        await apiService.post(`/drives/${driveId}/reject`)
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const rejectDriveInviteSlice = createSlice({
    name: "Reject Drive Invite",
    initialState: {
        loading: false,
        error: null,
        success: false,
        successMessage: null
    },
    reducers: {
        resetRejectDriveInviteSuccess: (state) => {
            state.successMessage = null;
            state.success = false
        },
        resetRejectDriveInviteError: (state) => {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(rejectDriveInvite.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(rejectDriveInvite.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
            state.successMessage = "Invitation Reject"
        })
        builder.addCase(rejectDriveInvite.rejected, (state, action) => {
            state.loading = false;
        })
    }
})
export const { resetRejectDriveInviteSuccess, resetRejectDriveInviteError } = rejectDriveInviteSlice.actions
export default rejectDriveInviteSlice.reducer