import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getMemberDesignation = createAsyncThunk('getMemberDesignation', async (memberId, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/team-designation`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getMemberDesignationSlice = createSlice({
    name: "Member Designation",
    initialState: {
        loading: false,
        error: null,
        memberDesignation: []
    },
    extraReducers: (builder) => {
        builder.addCase(getMemberDesignation.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getMemberDesignation.fulfilled, (state, action) => {
            state.loading = false;
            state.memberDesignation = action.payload
        })
        builder.addCase(getMemberDesignation.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export default getMemberDesignationSlice.reducer