import { marksType } from "./enums";

export const formatMarksType = (type) => {
    switch (type) {
        case marksType.CGPA:
            return "CGPA";
        case marksType.PERCENTAGE:
            return "%";
        case marksType.GRADE:
            return "Grade";
        default:
            return "NA"
    }
}