import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getCtcStats = createAsyncThunk('getCtcStats', async (year, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/graphs/analytics/job/job-type-ctc-offered/${year}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getCtcStatsSlice = createSlice({
    name: "Ctc Stats Graph",
    initialState: {
        loading: false,
        error: null,
        ctcStats: null
    },
    extraReducers: (builder) => {
        builder.addCase(getCtcStats.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getCtcStats.fulfilled, (state, action) => {
            state.loading = false;
            state.ctcStats = action.payload
        })
        builder.addCase(getCtcStats.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export default getCtcStatsSlice.reducer