import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getCollegeBranch = createAsyncThunk('getCollegeBranch', async (body, { rejectWithValue }) => {
    try {
        const { data } = await apiService.post(`/college-branches`, body)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getCollegeBranchSlice = createSlice({
    name: "Drive Type",
    initialState: {
        loading: false,
        error: null,
        branches: [],
    },
    extraReducers: (builder) => {
        builder.addCase(getCollegeBranch.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getCollegeBranch.fulfilled, (state, action) => {
            state.loading = false;
            state.branches = action.payload.branches
        })
        builder.addCase(getCollegeBranch.rejected, (state, action) => {
            state.loading = false;
        })

    }
})

export default getCollegeBranchSlice.reducer