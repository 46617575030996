import { TemplateRoundStatus } from "../../../utils/enums"
import TemplateRoundManage from "../driveTemplates/TemplateRoundManage"

export const DriveTemplateRoundColumns = [
    {
        id: "title",
        header: "Title",
        cell: ({ row }) => {
            const roundName = row.original.title
            return <p>{roundName}</p>
        }
    },
    {
        id: "round-status",
        header: "Round Statuses",
        cell: ({ row }) => {
            const roundId = row.original.id
            return <TemplateRoundManage type={TemplateRoundStatus.ROUNDSTATUS} roundId={roundId} />
        }
    },
    {
        id: "application-status",
        header: "Application Statuses",
        cell: ({ row }) => {
            const roundId = row.original.id
            return <TemplateRoundManage type={TemplateRoundStatus.APPLICANTSTATUS} roundId={roundId} />

        }
    },
]