import CommonOutletHeader from "./CommonOutletHeader"
import CommonOutletSearch from "./CommonOutletSearch"

const CommonOutlet = ({ title, createButton, selectedValue, children, placeholder, hasSearch, handleSearchChange, filterItems, handleFilterChange, selectPlaceholder }) => {
  return (
    <div>
      <CommonOutletHeader title={title} handleSearchChange={handleSearchChange} createButton={createButton} />
      {
        hasSearch && <CommonOutletSearch selctedValue={selectedValue} selectPlaceholder={selectPlaceholder} handleSearchChange={handleSearchChange} handleFilterChange={handleFilterChange} filterItems={filterItems} placeholder={placeholder} />
      }
      <div>
        {children}
      </div>
    </div>
  )
}
export default CommonOutlet