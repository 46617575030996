export const DriveRoundTemplateStatus = {
    ARCHIVE: "archive",
    ACTIVE: "active",
    DRAFT: "draft"
}

export const ToastEnum = {
    SUCCESS: "success",
    ERROR: "error",
    WARNING: "warning",
}

export const TemplateRoundStatus = {
    ROUNDSTATUS: "round-status",
    APPLICANTSTATUS: "applicant-status",
}

export const RoundStatuses = {
    ONGOING : "Ongoing",
    SCHEDULED : "Scheduled",
    COMPLETED : "Completed"
}

export const ApplicantStatuses = {
    ACCEPTED : "Accepted",
    APPLIED : "Applied",
    SHORTLISTED : "Shortlisted",
    REJECTED : "Rejected",
    WITHDRAWN : "Withdrawn"
}

export const ApplicationStatus = {
    ACCEPT: 'Accept',
    REJECT: 'Reject',
}

export const DriveTypes = {
    OFFCAMPUS : "Off Campus",
    ONCAMPUS : "On Campus",
}

export const networkFilterEnum = {
    Connected: "Accepted",
    NotConnected: "Not Connected",
    InvitationSent: "Approached By Company",
    InvitationReceived: "Approached By College",
}

export const connectionStatusEnum = {
    APPROACHED_BY_COLLEGE: "Approached",
    APPROACHED_BY_COMPANY: "Approached By Company",
    ACCEPTED: "Accepted",
    REJECTED_BY_COMPANY: "Rejected By Company",
    REJECTED_BY_COLLEGE: "Rejected By College",
    NOT_CONNECTED: "Not Connected"
}

export const marksType ={
    NOT_APPLICABLE : 'NOT_APPLICABLE',
    GRADE : 'GRADE',
    CGPA : 'CGPA',
    PERCENTAGE : 'PERCENTAGE',
}

export const driveStatus = {
    ONGOING : 'Ongoing',
    SCHEDULED : "Shceduled",
    COMPLETED : "Completed",
    REQUEST_SENT : "Request Sent",
    REQUEST_RECEIVED : "Request Received",
}