import { useDispatch } from "react-redux"
import CommonAlertDialog from "../../reusable/modals/CommonAlertDialog"
import { Button } from "../../ui/button"
import { withdrawNetwork } from "../../../redux/features/networks/withdrawNetworkSlice"
import { connectionStatusEnum } from "../../../utils/enums"
import { useState } from "react"

const DeleteInviteModal = ({ connectionStatus, connectionId, collegeName }) => {

    const dispatch = useDispatch()
    const [openAlertDialog, setOpenAlertDialog] = useState(false)
    const acceptedInvite = connectionStatus === connectionStatusEnum.ACCEPTED
    const triggerButton = <Button onClick={() => setOpenAlertDialog(true)} className={`${acceptedInvite ? "bg-activeColor" : "bg-primaryButtonColor/70"}`} size="sm">
        {
            acceptedInvite ? "Connected" : "Delete Invite"
        }
    </Button>
    const actionButton = <Button onClick={() => dispatch(withdrawNetwork(connectionId))} variant="primary">Continue</Button>

    return (
        <div onClick={(e) => e.stopPropagation()}>
            {triggerButton}
            <CommonAlertDialog open={openAlertDialog} setOpen={setOpenAlertDialog} title={`Are you sure you want to delete invitation with ${collegeName}`}  actionButton={actionButton} description={"This action can't be undone are you sure you want to delete the connection"} />
        </div>
    )
}
export default DeleteInviteModal