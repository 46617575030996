import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getAnalyticsStats = createAsyncThunk('getAnalyticsStats', async (year, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/graphs/analytics/stats/${year}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getAnalyticsStatsSlice = createSlice({
    name: "AnalyticsStats",
    initialState: {
        loading: false,
        error: null,
        stats: null
    },
    extraReducers: (builder) => {
        builder.addCase(getAnalyticsStats.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getAnalyticsStats.fulfilled, (state, action) => {
            state.loading = false;
            state.stats = action.payload
        })
        builder.addCase(getAnalyticsStats.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export default getAnalyticsStatsSlice.reducer