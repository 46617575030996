import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getDriveDetails = createAsyncThunk('getDriveDetails', async (driveId, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/drives/${driveId}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getDriveDetailsSlice = createSlice({
    name: "Drive Details",
    initialState: {
        loading: false,
        error: null,
        driveDetails: null,
        driveRounds: []
    },
    reducers: {
        udpdateDriveStatus: (state, action) => {
            state.driveDetails["driveStatusId"] = action.payload
        },
        updateDriveRounds: (state, action) => {
            const roundIndex = state.driveRounds.findIndex(r => r.roundId === action.payload.roundId)
            if(roundIndex != -1){
                state.driveRounds[roundIndex]["statusId"] = action.payload.statusId
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDriveDetails.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getDriveDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.driveDetails = action.payload
            state.driveRounds = action.payload.driveRounds
        })
        builder.addCase(getDriveDetails.rejected, (state, action) => {
            state.loading = false;
        })

    }
})

export const { udpdateDriveStatus, updateDriveRounds } = getDriveDetailsSlice.actions
export default getDriveDetailsSlice.reducer