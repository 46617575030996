import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const updateMember = createAsyncThunk('updateMember', async (obj, { rejectWithValue }) => {
    try {
        await apiService.put(`/team/${obj.memberId}`, obj.body)
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const updateMemberSlice = createSlice({
    name: "Update Member",
    initialState: {
        loading: false,
        error: null,
        successMsg: null
    },
    reducers: {
        resetMemberUpdateSuccessMsg: (state, action) => {
            state.successMsg = null
        },
        resetMemberUpdateError: (state, action) => {
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateMember.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(updateMember.fulfilled, (state, action) => {
            state.loading = false;
            state.successMsg = "Member Permissions updated successfully"
        })
        builder.addCase(updateMember.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export const { resetMemberUpdateError, resetMemberUpdateSuccessMsg } = updateMemberSlice.actions
export default updateMemberSlice.reducer