import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const startNextRound = createAsyncThunk('startNextRound', async (driveId, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/drives/${driveId}/start-next-round`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const startNextRoundSlice = createSlice({
    name: "Start next round",
    initialState: {
        loading: false,
        error: null,
        success: null
    },
    reducers: {
        resetStartNextRoundSuccess: (state) => {
            state.success = null;
        },
        resetStartNextRoundError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(startNextRound.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(startNextRound.fulfilled, (state, action) => {
            state.loading = false;
            state.success = "Next Round Started Successfully"
        })
        builder.addCase(startNextRound.rejected, (state, action) => {
            state.loading = false;
        })

    }
})

export const { resetStartNextRoundSuccess, resetStartNextRoundError } = startNextRoundSlice.actions

export default startNextRoundSlice.reducer