import { useDispatch, useSelector } from "react-redux"
import Common from "../reusable/Common"
import { getJobs } from "../../redux/features/jobs/getJobSlice"
import { useEffect } from "react"
import { DataTable } from "../table/DataTable"
import { JobStatusColumns } from "./columns/JobStatusColumns"

const JobStatus = () => {

  const dispatch = useDispatch()
  const { loading, jobs } = useSelector(state => state.jobsReducer)
  useEffect(() => {
    dispatch(getJobs())
  }, [])

  return (
    <div className="col-span-2 row-span-2">
      <Common title={"Job Status"} link={"/jobs"}>
        {
          loading ? <p>Loading</p> : jobs && <DataTable hasClick={false} hasPagination={false} columns={JobStatusColumns} data={jobs} />
        }
      </Common>
    </div>
  )
}
export default JobStatus