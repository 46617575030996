import { RouterFn } from './Routes'
import './App.css';
import { Toaster } from './components/ui/sonner';
import React, { useEffect, useState } from 'react';
import { auth } from './config/firebaseConfig';
import { useDispatch } from 'react-redux';
import { firebaseSetUser, logout } from './redux/features/auth/authSlice';
import apiService from './services/ApiService';
import { useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";

// Initialize Google Analytics only in production
(window.location.hostname === 'company.joinsaarthi.com' || window.location.hostname === 'www.company.joinsaarthi.com') && ReactGA.initialize("G-YMP62ZQG49");



function App() {
// Track the initial page load only in production
(window.location.hostname === 'company.joinsaarthi.com' || window.location.hostname === 'www.company.joinsaarthi.com') && ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);

  const checkUser = () => {
    auth.onAuthStateChanged((async (user) => {
      if (user) {
        try {
          const response = await apiService.get(`/current-user`);
          dispatch(firebaseSetUser(response.data));

          setLoading(false);
        } catch (error) {
          // Handle any errors here
          console.log(error)
          setLoading(false)
        }
      } else {
        setLoading(false);
      }
    }))
  }
  useEffect(() => {
    checkUser()
  }, [dispatch]);

  if (loading) {
    return <div>Loading</div>
  }

  return (
    <React.Fragment>
      <Toaster richColors={true} />
      <RouterFn />
    </React.Fragment>
  );
}

export default App;
