import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const createRoundStatus = createAsyncThunk('createRoundStatus', async (obj, { rejectWithValue }) => {
  try {
    const body = obj.tempRoundStatus.filter((round) => round.id === obj.roundId).map((round) => {
      return {
        title: round.title
      }
    })
    await apiService.post(`/round-templates/${obj.templateId}/rounds/${obj.roundId}/round-statuses`, body)
    return
  } catch (error) {
    console.log(error)
    return rejectWithValue(error)
  }
})

export const createApplicantStatus = createAsyncThunk('createApplicantStatus', async (obj, { rejectWithValue }) => {
  const body = obj.tempApplicantStatus.filter((round) => round.id === obj.roundId).map((round) => {
    return {
      title: round.title
    }
  })
  try {
    await apiService.post(`/round-templates/${obj.templateId}/rounds/${obj.roundId}/applicant-statuses`, body)
    return
  } catch (error) {
    console.log(error)
    return rejectWithValue(error)
  }
})

const createDriveTemplateRoundStatusSlice = createSlice({
  name: "Create Round Status",
  initialState: {
    loading: false,
    error: null,
    success: false,
    successMsg: null,
  },
  reducers: {
    resetRoundStatusSuccessMsg: (state, action) => {
      state.successMsg = null
      state.success = false
    },
    resetRoundStatusError: (state, action) => {
      state.error = null
    },
  },
  extraReducers: (builder) => {

    builder.addCase(createApplicantStatus.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(createApplicantStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.successMsg = "Applicant Status created Succssfully"
    })
    builder.addCase(createApplicantStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.response.data.message
    })

    builder.addCase(createRoundStatus.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(createRoundStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.successMsg = "Round Status created Succssfully"
    })
    builder.addCase(createRoundStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.response.data.message
    })
  }
})
export const { resetRoundStatusSuccessMsg, resetRoundStatusError } = createDriveTemplateRoundStatusSlice.actions
export default createDriveTemplateRoundStatusSlice.reducer