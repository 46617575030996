import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getGenderWiseApplicants = createAsyncThunk('getGenderWiseApplicants', async (year, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/graphs/analytics/job/gender-wise-applications/${year}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getGenderWiseApplicantsSlice = createSlice({
    name: "Gender wise applicants",
    initialState: {
        loading: false,
        error: null,
        genderWiseApplicantsGraph: null
    },
    extraReducers: (builder) => {
        builder.addCase(getGenderWiseApplicants.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getGenderWiseApplicants.fulfilled, (state, action) => {
            state.loading = false;
            state.genderWiseApplicantsGraph = action.payload
        })
        builder.addCase(getGenderWiseApplicants.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export default getGenderWiseApplicantsSlice.reducer