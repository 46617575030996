import { useState, useEffect } from "react";
import { Button } from "../../ui/button";
import CommonModal from "../../reusable/modals/CommonModal";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form } from "../../ui/form";
import InputFormField from "../../reusable/forms/InputFormField";
import { useDispatch, useSelector } from "react-redux";
import { createTemplate, resetError, resetSuccessMsg, resetFormError } from "../../../redux/features/drive-template/createDriveTemplateSlice";
import { useNavigate } from "react-router-dom";
import { Loader } from "lucide-react";

const formSchema = z.object({
  title: z.string().min(1, {
    message: "Title is required",
  }),
});

const CreateTemplateModal = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { success, formError, loading } = useSelector((state) => state.createTemplateReducer);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
    },
  });

  function onSubmit(values) {
    dispatch(createTemplate({ title: values.title }));
  }

  useEffect(() => {
    if (success) {
      form.reset();
      setOpen(false);
      dispatch(resetSuccessMsg());
    }

    if (formError) {
      form.setError("title", {
        type: "manual",
        message: formError,
      });
      dispatch(resetFormError());
    }
  }, [success, formError, dispatch, form]);



  const triggerButton = (
    <Button onClick={() => setOpen(true)} variant="primary" className="rounded-3xl">
      + Create Template
    </Button>
  );

  return (
    <div>
      <CommonModal
        open={open}
        setOpen={setOpen}
        onClose={() => {
          setOpen(false);  // Ensure modal closes on onClose event
          dispatch(resetError());  // Clear any existing errors
        }}
        triggerButton={triggerButton}
        title={"Create Template"}
      >
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <InputFormField
              name="title"
              control={form.control}
              placeholder={"Enter Template title"}
              label={"Template Title"}
              type={"text"}
            />
            <div className="flex justify-end w-full">
              <Button type="submit" variant="primary" className="rounded-3xl shadow-md">
                {
                  loading ? <Loader className="text-white w-4 h-4 animate-spin" /> : "Save"
                }
              </Button>
            </div>
          </form>
        </Form>
      </CommonModal>
    </div>
  );
};

export default CreateTemplateModal;
