import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";
import { v4 as uuidv4 } from 'uuid';

export const archiveTemplate = createAsyncThunk('archiveTemplate', async (roundTemplateId, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/round-templates/${roundTemplateId}/archive`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

export const activateTemplate = createAsyncThunk('activateTemplate', async (roundTemplateId, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/round-templates/${roundTemplateId}/activate`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

const updateDriveTemplateSlice = createSlice({
    name: "Update Template Status",
    initialState: {
        loading: false,
        error: null,
        success: false,
        successMsg: null,
        tempRoundStatus: [],
        tempApplicantStatus: []
    },
    reducers: {
        resetUpdateTemplateStatusSuccessMsg: (state, action) => {
            state.successMsg = null
            state.success = false
        },
        resetUpdateTemplateStatusError: (state, action) => {
            state.error = null
        },
        setTempRoundStatus: (state, action) => {
            state.tempRoundStatus = [...state.tempRoundStatus,
            {
                title: action.payload.title,
                id: action.payload.id,
                statusId: uuidv4()
            }]
        },
        setTempApplicantStatus: (state, action) => {
            state.tempApplicantStatus = [...state.tempApplicantStatus,
            {
                title: action.payload.title,
                id: action.payload.id,
                statusId: uuidv4()

            }]
        },
        resetRoundStatuses: (state, action) => {
            state.tempRoundStatus = []
            state.tempApplicantStatus = []
        },
        deleteTempRoundStatus: (state, action) => {
            const newStatus = state.tempRoundStatus.filter((round) => round.statusId != action.payload)
            state.tempRoundStatus = newStatus
        },
        deleteTempApplicantStatus: (state, action) => {
            const newStatus = state.tempApplicantStatus.filter((round) => round.statusId != action.payload)
            state.tempApplicantStatus = newStatus
        }
    },
    extraReducers: (builder) => {

        builder.addCase(activateTemplate.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(activateTemplate.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
            state.successMsg = "Template activated Succssfully"
        })
        builder.addCase(activateTemplate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data.message
        })

        builder.addCase(archiveTemplate.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(archiveTemplate.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
            state.successMsg = "Template archived Succssfully"
        })
        builder.addCase(archiveTemplate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data.message
        })
    }
})
export const { resetUpdateTemplateStatusSuccessMsg, resetRoundStatuses, resetUpdateTemplateStatusError, setTempRoundStatus, setTempApplicantStatus, deleteTempRoundStatus, deleteTempApplicantStatus } = updateDriveTemplateSlice.actions
export default updateDriveTemplateSlice.reducer