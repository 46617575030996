import { useSelector } from "react-redux"
import { formatDate } from "../../../utils/formatDate"

const JobDetailsInfo = () => {
  const { jobDetails } = useSelector(state => state.jobDetailReducer)

  return (
    <div className="border shadow-xl px-8 py-8 rounded-3xl rounded-tl-none">
      <div className="grid grid-cols-8 gap-x-8">
        <div className="shadow-lg px-4 col-span-5 py-4 flex flex-col gap-y-4 rounded-md border">
          <div className="flex flex-col gap-y-2">
            <h1 className="font-semibold text-primaryButtonColor text-lg">Job Description</h1>
            <p className="">{jobDetails?.description}</p>
          </div>
          <div className="flex flex-col gap-y-2 ">
            <h1 className="font-semibold text-primaryButtonColor text-lg">Eligibilty Criteria</h1>
            <p className="">{jobDetails?.eligibilityCriteria}</p>
          </div>
        </div>
        <div className="shadow-lg col-span-3 px-4 py-4 flex flex-col gap-y-4 rounded-md border">
          <h1 className="font-semibold text-primaryButtonColor text-lg">Key Information</h1>
          <div className="grid grid-cols-2 gap-y-4 gap-x-2 justify-items-stretch">
            <div>
              <p className="text-base font-semibold text-primaryButtonColor">CTC Range</p>
              <p className="font-medium">{jobDetails?.ctcMin} - {jobDetails?.ctcMax}</p>
            </div>
            <div>
              <p className="text-base font-semibold text-primaryButtonColor">Application Deadline</p>
              <p className="font-medium">{formatDate(jobDetails?.applicationDeadline)}</p>
            </div>
            <div>
              <p className="text-base font-semibold text-primaryButtonColor">Total Openings</p>
              <p className="font-medium">{jobDetails?.openings}</p>
            </div>
            <div>
              <p className="text-base font-semibold text-primaryButtonColor">Filled Openings</p>
              <p className="font-medium">{jobDetails?.filled}</p>
            </div>
          </div>
          <div>
            <p className="text-base font-semibold text-primaryButtonColor">Round Template Used</p>
            <p className="font-medium">Mech Engineers Template</p>
          </div>
        </div>

      </div>
    </div>
  )
}
export default JobDetailsInfo