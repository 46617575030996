import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getDriveStatus = createAsyncThunk('getDriveStatus', async (_, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/drive-statuses`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getDriveStatusSlice = createSlice({
    name: "Drive Status",
    initialState: {
        loading: false,
        error: null,
        driveStatuses: [],
    },
    extraReducers: (builder) => {
        builder.addCase(getDriveStatus.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getDriveStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.driveStatuses = action.payload
        })
        builder.addCase(getDriveStatus.rejected, (state, action) => {
            state.loading = false;
        })

    }
})

export default getDriveStatusSlice.reducer