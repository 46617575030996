import { Link } from "react-router-dom"
import { Button } from "../../ui/button"

export const JobApplicantsColumns = [
    {
        id: "name",
        header: "Name",
        cell: ({ row }) => {
            const student = row.original.student
            return <p>{student?.name}</p>
        }
    },
    {
        accessorKey: "email",
        header: "Email",
        cell: ({ row }) => {
            const student = row.original.student
            return <p>{student?.email}</p>
        }
    },
    {
        id: "round_name",
        header: "RoundName",
        cell: ({ row }) => {
            const round = row.original.round
            return <p>{round?.title}</p>
        }
    },
    {
        accessorKey: "applicationStatus",
        header: "Application Status",
        cell : ({row}) => {
            const roundStatus = row.original.roundStatus
            return <p>{roundStatus?.title}</p>
        }
    },
    {
        accessorKey: "college",
        header: "College Name",
    },
    {
        id: "actions",
        cell: ({ row }) => {
            const applicationId = row.original.id
            return <Link to={`/applicant-details/${applicationId}`}>
                <Button variant="primary" size="sm">View Details</Button>
            </Link>
        }
    },
]