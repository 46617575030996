import { Button } from "../../components/ui/button"
import InputFormField from "../../components/reusable/forms/InputFormField"
import { useForm } from "react-hook-form"
import { Form } from "../../components/ui/form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import SelectFormField from "../../components/reusable/forms/SelectFormField"
import TextAreaFormField from "../../components/reusable/forms/TextAreaFormField"
import ToggleButton from "../../components/reusable/forms/ToggleButton"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { format } from "date-fns"
import { resetProfileSuccessMsg, updateProfile } from "../../redux/features/profile/updateProfileSlice"
import { Loader } from "lucide-react"
import snackbar from "../../components/reusable/snackbar"
import { ToastEnum } from "../../utils/enums"

const genderItems = [
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Other",
    value: "Other",
  },
]
const formSchema = z.object({
  username: z.string(),
  email: z.string().email(),
  name: z.string(),
  designation: z.string(),
  phoneNumber: z.string(),
  alternateNumber: z.string(),
  alternateEmail: z.string(),
  address: z.string(),
  gender: z.string(),
  dateOfBirth: z.string()
})
const Profile = () => {

  const { user } = useSelector((state) => state.authReducer)
  const [modifyUsers, setModifyUsers] = useState(user?.manageUsers)
  const [scheduleDrive, setScheduleDrive] = useState(user?.canScheduleDrive)
  const [modifyDrive, setModifyDrive] = useState(user?.canModifyDrive)

  const { loading: updateLoading, successMsg } = useSelector((state) => state.updateProfileReducer)
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: user?.username,
      email: user?.email,
      name: user?.name,
      designation: user?.designation,
      phoneNumber: user?.phoneNumber.substring(3),
      alternateNumber: user?.alternateNumber.substring(3),
      alternateEmail: user?.alternateEmail,
      address: user?.address,
      dateOfBirth: user?.dateOfBirth,
      gender: user?.gender
    }
  })

  const dispatch = useDispatch()

  const onSubmit = (values) => {

    const body = {
      ...values,
      dateOfBirth: format(values.dateOfBirth, "yyyy-MM-dd"),
      alternateNumber: "+91" + values.alternateNumber,
      phoneNumber: "+91" + values.phoneNumber,
      manageUsers: modifyUsers,
      canScheduleDrive: scheduleDrive,
      canModifyDrive: modifyDrive
    }

    dispatch(updateProfile(body))
  }

  useEffect(() => {
    if (successMsg) {
      snackbar(ToastEnum.SUCCESS, successMsg);
      dispatch(resetProfileSuccessMsg())
    }
  }, [successMsg])

  return (
    <div className="">
      <h1 className="font-semibold text-2xl">Profile</h1>

      <div className="flex flex-col gap-y-2 mt-8">
        <Form {...form}>
          <h2 className="font-medium text-lg">System Details</h2>
          <hr />
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2 mt-2">
            <div className="flex items-center justify-between gap-x-10">
              <InputFormField disabled={true} className={"w-1/2"} name="username" control={form.control} placeholder={"Enter your username"} label={"Username"} type={"text"} />
              <InputFormField disabled={true} className={"w-1/2"} name="email" control={form.control} placeholder={"Enter your Email"} label={"Email"} type={"email"} />
            </div>
            <h2 className="font-medium text-lg">Personal Details</h2>
            <hr />

            <div className="flex items-center justify-between gap-x-10">
              <InputFormField className={"w-1/2"} name="name" control={form.control} placeholder={"Sanjay Sharma"} label={"Name"} type={"text"} />
              <InputFormField className={"w-1/2"} name="designation" control={form.control} placeholder={"Sr HR Executive"} label={"Designation"} type={"text"} />
            </div>
            <div className="flex items-center justify-between gap-x-10">
              {/* <DatePickerFormField className={"w-1/2"} name="dateOfBirth" control={form.control} placeholder={"17 feb 2003"} label={"Date of Birth"} /> */}
              <InputFormField className={"w-1/2"} name="dateOfBirth" control={form.control} placeholder={"Sr HR Executive"} label={"Date of Birth"} type={"date"} />
              <SelectFormField className={"w-1/2"} items={genderItems} name="gender" control={form.control} placeholder={"Male"} label={"Gender"} />
            </div>

            <div className="flex items-start justify-between gap-x-10">
              <TextAreaFormField className={"w-1/2"} inputClassName={"h-[215px]"} name="address" control={form.control} placeholder={"Twin tower"} label={"Address"} />
              <div className="w-1/2 flex flex-col gap-y-4">
                <InputFormField name="phoneNumber" control={form.control} placeholder={"Enter phone number"} label={"Phone Number"} type={"text"} />
                <InputFormField name="alternateNumber" control={form.control} placeholder={"Enter alternate phone number"} label={"Alternate Phone Number"} type={"text"} />
                <InputFormField name="alternateEmail" control={form.control} placeholder={"Alternate Email id"} label={"Alternate Email Id"} type={"email"} />
              </div>
            </div>

            <h2 className="font-medium text-lg">Permissions</h2>
            <hr />
            <h2 className="font-medium text-lg text-secondaryColor">User Management</h2>
            <hr />

            <ToggleButton handleToggleChange={(e) => setModifyUsers(e.target.checked)} label={"Modify Users"} isChecked={modifyUsers} />

            <h2 className="font-medium text-lg text-secondaryColor">Drive Management</h2>
            <hr />

            <div className="flex items-center gap-x-10">
              <ToggleButton handleToggleChange={(e) => setScheduleDrive(e.target.checked)} label={"Can Schedule Drive"} isChecked={scheduleDrive} />
              <ToggleButton handleToggleChange={(e) => setModifyDrive(e.target.checked)} label={"Can Modify Drive"} isChecked={modifyDrive} />

            </div>

            <div className="flex justify-end w-full">
              <Button disabled={updateLoading} type="submit" className="rounded-3xl text-lg px-8 py-4 shadow-md" variant="primary">
                {updateLoading ? <><Loader className="animate-spin me-2" size={20} /> Saving</> : "Save"}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}
export default Profile