import { useDispatch, useSelector } from "react-redux"
import Common from "../reusable/Common"
import { getRecentlyConnectedColleges } from "../../redux/features/networks/recentlyConnectedCollegeSlice"
import RecentlyConnectedCollegeCard from "./recently-connected/RecentlyConnectedCollegeCard"
import { useEffect } from "react"
import { connectionStatusEnum } from "../../utils/enums"


const RecentlyConnectedCollege = () => {

  const dispatch = useDispatch()
  const { recentlyConnectedColleges } = useSelector(state => state.recentlyConnectedCollegeReducer)

  useEffect(() => {
    dispatch(getRecentlyConnectedColleges({ connectionStatus: connectionStatusEnum.ACCEPTED, limit: 10 }))
  }, [])

  return (
    <div className="col-span-1 row-span-2">
      <Common title={"Recently Connected Colleges"} link={"/networks"}>
        <div className="grid grid-cols-2 justify-between gap-y-4 gap-x-4">
          {
            recentlyConnectedColleges?.results?.map((item, i) => (
              <RecentlyConnectedCollegeCard key={i} name={item?.college?.title} logo={item?.college.logoUrl} />
            ))
          }
        </div>
      </Common>
    </div>
  )
}
export default RecentlyConnectedCollege