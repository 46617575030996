import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

import { Button } from "../components/ui/button"
import {
  Form,
} from "../components/ui/form"
import InputFormField from "../components/reusable/forms/InputFormField"

import logo from '../assets/logos/logo.png'
import { useDispatch, useSelector } from "react-redux"
import { userLogin } from "../redux/features/auth/authSlice"
import { toast } from "sonner"
import { useEffect } from "react"
import ButtonLoading from "../components/reusable/loaders/ButtonLoading"
import { useNavigate } from "react-router-dom"
const formSchema = z.object({
  email: z.string().email(),
  password: z.string(),
})
export const Login = () => {

  const dispatch = useDispatch()
  const { loginSuccess, loading, error, user } = useSelector(state => state.authReducer)
  const navigate = useNavigate()
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })

  function onSubmit(values) {
    dispatch(userLogin(values))
  }

  useEffect(() => {
    if (loginSuccess) {
      toast.success("Logged in successfully")
    } if (error) {
      toast.error(error)
    }
  }, [loginSuccess, error])


  useEffect(() => {
    if (user) {
      navigate("/")
    }
  }, [user])

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="shadow-lg border rounded-2xl px-6 py-6">
        <div className="flex flex-col items-center gap-y-4 justify-center">
          <img src={logo} className="max-w-[50%]" alt="" />
          <h1 className="text-primaryButtonColor font-bold text-xl">WELCOME BACK</h1>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-6">
            <InputFormField name="email" control={form.control} placeholder={"Enter your Email"} label={"Email"} type={"email"} />
            <InputFormField name="password" control={form.control} placeholder={"Enter your Password"} label={"Password"} type={"password"} />
            <p className="text-primaryButtonColor text-sm cursor-pointer hover:underline">Forgot Password?</p>
            {
              loading ? <ButtonLoading /> :
                <Button type="submit" variant="primary" className="w-full mt-4 py-5 text-base">Submit</Button>
            }
          </form>
        </Form>
      </div>
    </div>
  );
}
