import { ChevronLeft, ChevronRight } from "lucide-react"
import CommonSelect from "../reusable/dropdowns/CommonSelect"
import { Button } from "../ui/button"

const DataTablePagination = ({ table }) => {

    const onPageSizeChange = (value) => {
        table.setPageSize(Number(value))
    }

    return (
        <div className="flex justify-end">
            <div className="flex items-center gap-x-4">
                <CommonSelect items={[10, 20, 30, 40, 50]} className="w-[100px]" defaultValue={table.getState().pagination.pageSize} handleSelectChange={onPageSizeChange} hideNone={true} selectPlaceholder={""} />

                {/* <p>Showing </p> */}
                <div className="flex items-center gap-x-1" >
                    <Button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} variant="outline" className="px-2 py-4" size="sm">
                        <ChevronLeft className="w-6 h-6" />
                    </Button>
                    <Button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} variant="outline" className="px-2 py-4" size="sm">
                        <ChevronRight className="w-6 h-6" />
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default DataTablePagination