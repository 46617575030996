import StatusButton from "../driveTemplates/StatusButtons"

export const DriveTemplateColumns = [
    {
        id: "title",
        header: "Title",
        cell: ({ row }) => {
            const driveRoundTemplate = row.original
            return <p>{driveRoundTemplate.title}</p>
        }
    },
    {
        id: "status",
        header: "Status",
        cell: ({ row }) => {
            const driveRoundTemplate = row.original
            return (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p style={{ flex: 1 }}>{driveRoundTemplate.status}</p>
                    <StatusButton status={driveRoundTemplate.status} id={driveRoundTemplate.id} />
                </div>
            )
        }
    }
]
