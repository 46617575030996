import { useDispatch, useSelector } from "react-redux"
import DriveDetailsHeader from "../../components/drives/drive-details/DriveDetailsHeader"
import DriveDetailsTabs from "../../components/reusable/tabs/DriveDetailsTabs"
import { useEffect, useState } from "react"
import { getDriveDetails } from "../../redux/features/drives/getDriveDetailsSlice"
import { useParams } from "react-router-dom"
import DriveDetailLoading from "../../components/reusable/loaders/DriveDetailLoading"
import { getDriveApplications } from "../../redux/features/drives/getDriveApplicationsSlice"
import { getJobLocation } from "../../redux/features/jobs/getJobLocationsSlice"
import { getDriveStatus } from "../../redux/features/drives/getDriveStatusSlice"
import snackbar from "../../components/reusable/snackbar"
import { ToastEnum } from "../../utils/enums"
import { resetupdateDriveError, resetupdateDriveSuccess } from "../../redux/features/drives/updateDriveSlice"
import { resetStartDriveError, resetStartDriveSuccess } from "../../../src/redux/features/drives/startDriveSlice"
import { resetEndDriveError, resetEndDriveSuccess } from "../../../src/redux/features/drives/endDriveSlice"
import { resetStartNextRoundError, resetStartNextRoundSuccess } from "../../../src/redux/features/drives/startNextRoundSlice"

const DriveDetails = () => {

    const dispatch = useDispatch()
    const { driveId } = useParams()
    const { loading } = useSelector(state => state.driveDetailsReducer)
    const { successMessage, error } = useSelector(state => state.updateDriveReducer)
    const { success: startDriveSuccess,error : startDriveError } = useSelector(state => state.startDriveReducer)
    const { success: startNextRoundSuccess,error : startNextRoundError } = useSelector(state => state.startNextRoundReducer)
    const { success: endDriveSuccess,error : endDriveError } = useSelector(state => state.endDriveReducer)

    const [pagination, setPagination] = useState({
        pageSize: 10,
        pageIndex: 0
    })

    useEffect(() => {
        if (successMessage) {
            snackbar(ToastEnum.SUCCESS, successMessage)
            dispatch(resetupdateDriveSuccess())
        }
        if (startDriveSuccess) {
            snackbar(ToastEnum.SUCCESS, startDriveSuccess)
            dispatch(resetStartDriveSuccess())
        }
        if (endDriveSuccess) {
            snackbar(ToastEnum.SUCCESS, endDriveSuccess)
            dispatch(resetEndDriveSuccess())
        }
        if (startNextRoundSuccess) {
            snackbar(ToastEnum.SUCCESS, startNextRoundSuccess)
            dispatch(resetStartNextRoundSuccess())
        }
        if (error) {
            snackbar(ToastEnum.ERROR, error)
            dispatch(resetupdateDriveError())
        }
        if (startDriveError) {
            snackbar(ToastEnum.ERROR, startDriveError)
            dispatch(resetStartDriveError())
        }
        if (startNextRoundError) {
            snackbar(ToastEnum.ERROR, startNextRoundError)
            dispatch(resetStartNextRoundError())
        }
        if (endDriveError) {
            snackbar(ToastEnum.ERROR, endDriveError)
            dispatch(resetEndDriveError())
        }
        dispatch(getDriveDetails(driveId))
    }, [successMessage, error, startDriveSuccess, endDriveSuccess, startNextRoundSuccess])

    useEffect(() => {
        dispatch(getDriveApplications({ driveId: driveId, query: {} }))
        dispatch(getJobLocation())
        dispatch(getDriveStatus())
    }, [])

    return (
        <div>
            {
                loading ? <DriveDetailLoading /> :
                    <>
                        <DriveDetailsHeader />
                        <DriveDetailsTabs pagination={pagination} setPagination={setPagination} />
                    </>
            }
        </div>
    )
}
export default DriveDetails