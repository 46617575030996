import { useDispatch, useSelector } from "react-redux"
import JobDetailsHeader from "../../components/jobs/job-details/JobDetailsHeader"
import JobDetailsTabs from "../../components/reusable/tabs/JobDetailsTabs"
import { getJobDetails } from "../../redux/features/jobs/getJobDetailsSlice"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import JobDetailLoading from "../../components/reusable/loaders/JobDetailLoading"
import { getJobApplications } from "../../redux/features/jobs/getJobApplicationSlice"

const JobDetails = () => {

  const { jobId } = useParams()
  const { jobDetails, loading } = useSelector(state => state.jobDetailReducer)
  const dispatch = useDispatch()

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0 
  })
  useEffect(() => {
    dispatch(getJobDetails(jobId))
    dispatch(getJobApplications(jobId))
  }, [])

  return (
    <div>
      {
        loading ? <JobDetailLoading /> :
          <>
            <JobDetailsHeader />
            <JobDetailsTabs pagination={pagination} setPagination={setPagination} />
          </>
      }
    </div>
  )
}
export default JobDetails