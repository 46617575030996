import { useSelector } from "react-redux"
import { Icon } from "../../ui/icon"
import { Icons } from "../../../utils/icons"
import { useNavigate } from "react-router-dom"
import navigateBack from "../../../utils/backNavigateUtil"
import KeyvalueCol from "../../reusable/keyvalue/keyvaluecol"
import { Badge } from "../../ui/badge"
import { formatDate } from "../../../utils/formatDate"

const DriveDetailsHeader = () => {

  const { driveDetails } = useSelector(state => state.driveDetailsReducer)
  const navigate = useNavigate()

  return (
    <div className="relative">
      <div className="flex items-center cursor-pointer hover:bg-primaryButtonColor/90 justify-center absolute -top-2 -left-4 w-10 h-10 rounded-full bg-primaryButtonColor p-2">
        <Icon onClick={() => navigateBack(navigate)} icon={Icons.BACKARROW} className="text-lightColor w-5 h-5" />
      </div>
      <div className="grid grid-cols-3 gap-x-6">
        <div className="col-span-2 shadow-lg rounded-lg px-6 py-6 border">
          <div className="flex flex-col">
            <h1 className="text-2xl font-semibold">{driveDetails?.title}</h1>
            <p className="font-medium">{driveDetails?.colleges?.map(college => college ? college.title : "").join(', ')}</p>

            <div className="flex justify-end">
              <div className="bg-successColor rounded-2xl px-4 py-1 text-lightColor text-sm">
                <p className="">{driveDetails?.driveStatus}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="shadow-lg rounded-lg px-6 py-6 border">
          <div className="flex flex-col gap-y-2">
            <div className="flex items-center justify-between">
              <KeyvalueCol title={"Drive Type"} value={driveDetails?.driveType} />
              <KeyvalueCol title={"Date of Visit"} value={formatDate(driveDetails?.dateOfVisit)} />
              <KeyvalueCol title={"Drive Schedule"} value={`${formatDate(driveDetails?.startDate)} - ${formatDate(driveDetails?.endDate)}`} />
            </div>
            <div className="flex items-center gap-x-2">
              <KeyvalueCol title={"CTC Range"} value={`${driveDetails?.ctcMin} - ${driveDetails?.ctcMax}`} />
              {/* <KeyvalueCol title={"Preffered Branches"} /> */}

              <div className="flex flex-col">
                <p className="text-sm font-semibold text-primaryButtonColor">Preffered Branches</p>
                <div className="flex items-center gap-x-2">
                  {
                    driveDetails?.prefferedBranches.map((branch, i) => (
                      <Badge>
                        {branch?.split(' ')[0]}
                      </Badge>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DriveDetailsHeader