import { z } from "zod";
import { jobTitleSchema } from "./job-title-schema";
import { DriveTypes } from "../utils/enums";
import { branchSchema } from "./branch-schema";
import { collegeSchema } from "./college-schema";

// Define the initial drive schema
const baseDriveSchema = z.object({
    drive_name: z.string().min(1, {
        message: "Drive Title is required"
    }),
    start_date: z.string({
        invalid_type_error: "Please select a valid date"
    }),
    end_date: z.string({
        invalid_type_error: "Please select a valid date"
    }),
    date_of_visit: z.string({
        invalid_type_error: "Please select a valid date"
    }),
    ctc_min: z.string().min(1, {
        message: "Min ctc is required"
    }),
    ctc_max: z.string().min(1, {
        message: "Max ctc is required"
    }),
    description: z.string().min(1, {
        message: "Description is required"
    }),
    openings: z.string().min(1, {
        message: "Openings is required"
    }),
    eligibility_criteria: z.string().min(1, {
        message: "Eligibility Criteria is required"
    }),
    drive_type: z.string().min(1, {
        message: "Select the type of Drive"
    }),
    round_template_id: z.string().min(1, {
        message: "Select Round template"
    }),
    preffered_branches: z.array(branchSchema).min(1, {
        message: "Preferred Branch is required"
    }),
    job_titles: z.array(jobTitleSchema).min(1, {
        message: "Select at least one Job"
    }),
    college_ids: z.union([z.string(), z.array(collegeSchema)])
});

const conditionalSchema = baseDriveSchema.superRefine((data, ctx) => {
    if (data.drive_type === DriveTypes.ONCAMPUS) {
        if (!data.college_ids || typeof data.college_ids !== 'string') {
            ctx.addIssue({
                path: ['college_ids'],
                message: "Select a college for on-campus drive",
                code: z.ZodIssueCode.custom,
            });
        }
    } else if (data.drive_type === DriveTypes.OFFCAMPUS) {
        if (!data.college_ids || !Array.isArray(data.college_ids)) {
            ctx.addIssue({
                path: ['college_ids'],
                message: "Select colleges for off-campus drive",
                code: z.ZodIssueCode.custom,
            });
        }
    }
});

export { conditionalSchema };
