import * as React from "react"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { Day, DayPicker } from "react-day-picker"

import { cn } from "../../lib/utils"
import { buttonVariants } from "./button"
import { Skeleton } from "./skeleton"
import { format } from "date-fns"

function Calendar({
  className,
  classNames,
  handleMonthChange,
  dates,
  loading,
  showOutsideDays = true,
  ...props
}) {

  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    const updatedArray = [];
    dates?.forEach((item) => {
      const dateObj = new Date(item);
      updatedArray.push(dateObj);
      setSelected(updatedArray);
    })
  }, [dates])

  return (
    (<DayPicker
      selected={selected}
      showOutsideDays={showOutsideDays}
      onMonthChange={handleMonthChange}
      className={cn("p-3", className)}
      classNames={{
        months: "w-full flex",
        month: "space-y-4 w-full",
        caption: "w-full flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium text-primaryButtonColor",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "ghost" }),
          "h-7 w-7 bg-transparent p-0 opacity-100 hover:opacity-50"
        ),
        nav_button_previous: "absolute left-1 text-primaryButtonColor",
        nav_button_next: "absolute right-1 text-primaryButtonColor",
        table: "w-full border-collapse space-y-1",
        head_row: "flex w-full justify-evenly",
        head_cell:
          "rounded-md w-9 font-normal text-[0.8rem] text-primaryButtonColor",
        row: "flex w-full mt-2 justify-evenly",
        cell: "overflow-clip rounded-full h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent focus-within:relative focus-within:z-20",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
        ),
        tbody: "w-full",
        thead: "w-full",
        day_range_end: "day-range-end",
        day_selected:
          "bg-primaryButtonColor text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        //day_today: "bg-accent text-accent-foreground",
        day_outside:
          "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
        IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
        Day: ({ ...props }) => {
          if (loading) {
            return <Skeleton className="size-full rounded-full" />
          }
          return <Day {...props} />
        }
      }}
      {...props} />)
  );
}
Calendar.displayName = "Calendar"

export { Calendar }
