import { DataTable } from "../../components/table/DataTable"
import { getRecentlyConnectedColleges } from "../../redux/features/networks/recentlyConnectedCollegeSlice"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NetworkColumns } from "../../components/networks/columns/NetworkColumns"
import CommonOutlet from "../../components/reusable/layout/CommonOutlet"
import ListLoading from "../../components/reusable/loaders/ListLoading"
import { useNavigate } from "react-router-dom"
import { ToastEnum, networkFilterEnum } from "../../utils/enums"
import { resetInvitationError, resetInvitationSuccessMsg } from "../../redux/features/networks/createNetworkSlice"
import snackbar from "../../components/reusable/snackbar"
import { resetWithdrawError, resetWithdrawSuccessMsg } from "../../redux/features/networks/withdrawNetworkSlice"
import { resetConnectionStatusError, resetConnectionStatusSuccessMsg } from "../../redux/features/networks/updateConnectionStatusSlice"

const Networks = () => {

  const { successMsg: createSuccessMsg, error: createError } = useSelector(state => state.createNetworkReducer)
  const { successMsg: withdrawSuccessMsg, error: withdrawError } = useSelector(state => state.withdrawNetworkReducer)
  const { successMsg: updateStatusSuccessMsg, error: updateStatusError } = useSelector(state => state.updateConnectionStatusReducer)

  const { recentlyConnectedColleges, loading } = useSelector(state => state.recentlyConnectedCollegeReducer)
  const dispatch = useDispatch()
  const [name, setName] = useState("")
  const timeoutRef = useRef(null)

  const [connectionStatus, setConnectionStatus] = useState(null)
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0
  })

  useEffect(() => {
    if (createSuccessMsg) {
      snackbar(ToastEnum.SUCCESS, createSuccessMsg)
      dispatch(resetInvitationSuccessMsg())
    }
    if (createError) {
      snackbar(ToastEnum.ERROR, createError)
      dispatch(resetInvitationError())
    }
    if (withdrawSuccessMsg) {
      snackbar(ToastEnum.SUCCESS, withdrawSuccessMsg)
      dispatch(resetWithdrawSuccessMsg())
    }
    if (withdrawError) {
      snackbar(ToastEnum.ERROR, withdrawError)
      dispatch(resetWithdrawError())
    }
    if (updateStatusSuccessMsg) {
      snackbar(ToastEnum.SUCCESS, updateStatusSuccessMsg)
      dispatch(resetConnectionStatusSuccessMsg())
    }
    if (withdrawError) {
      snackbar(ToastEnum.ERROR, withdrawError)
      dispatch(resetConnectionStatusError())
    }

    const { pageSize, pageIndex } = pagination
    dispatch(getRecentlyConnectedColleges({ connectionStatus, limit: pageIndex === 0 ? pageSize : pageSize * (pageIndex + 1), offset: pageSize * pageIndex }))
  }, [createSuccessMsg, createError, withdrawSuccessMsg, withdrawError, pagination, updateStatusSuccessMsg, updateStatusError])

  const handleSearchChange = (e) => {
    const debounceDelay = 500;
    setName(e.target.value)
    // Clear previous timeout if exists
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    const { pageSize, pageIndex } = pagination
    // Set new timeout
    timeoutRef.current = setTimeout(() => {
      handleSearchChange.timeoutId = setTimeout(() => {
        dispatch(getRecentlyConnectedColleges({ name: e.target.value, connectionStatus, limit: pageIndex === 0 ? pageSize : pageSize * (pageIndex + 1), offset: pageSize * pageIndex }))
      }, debounceDelay);
    }, debounceDelay);
  }

  const formatLabel = (label) => {
    return label.replace(/([A-Z])/g, ' $1').trim();
  };

  const connectionStatusFilterItems = Object.entries(networkFilterEnum).map(([key, value]) => ({
    label: formatLabel(key),
    value: value
  }));

  const navigate = useNavigate()
  const handleRowClick = (row) => {
    const path = `/college-details/${row.college.id}`;
    navigate(path);
  };

  const statusFilterChange = (value) => {
    const { pageIndex, pageSize } = pagination
    setConnectionStatus(value)
    dispatch(getRecentlyConnectedColleges({ connectionStatus: value, limit: pageSize, offset: pageSize * pageIndex }))
  }

  return (
    <div>
      <CommonOutlet
        hasSearch={true}
        placeholder={"Search for colleges"}
        title={"Network"}
        selectPlaceholder={"Filter by Connectin status"}
        handleFilterChange={statusFilterChange}
        handleSearchChange={handleSearchChange}
        filterItems={connectionStatusFilterItems}
      >
        {
          loading ? <ListLoading /> : recentlyConnectedColleges && <DataTable hasClick={true} onClickRoute={handleRowClick} pagination={pagination} setPagination={setPagination} columns={NetworkColumns} data={recentlyConnectedColleges} />
        }
      </CommonOutlet>

    </div>
  )
}
export default Networks