import { Input } from "../../ui/input"
import CommonSelect from "../dropdowns/CommonSelect"

const CommonOutletSearch = ({ placeholder, handleSearchChange, selectedValue, filterItems, handleFilterChange, selectPlaceholder }) => {

    return (
        <div className="bg-secondaryColor/30 rounded-md px-2 py-2 mt-4 flex items-center gap-x-4">
            <Input onChange={handleSearchChange} placeholder={placeholder} className="w-[400px] bg-lightColor py-2" />
            <CommonSelect value={selectedValue} items={filterItems} selectPlaceholder={selectPlaceholder} handleSelectChange={handleFilterChange} />
        </div>
    )
}
export default CommonOutletSearch