import { useForm } from "react-hook-form"
import CommonModal from "../../reusable/modals/CommonModal"
import { Button } from "../../ui/button"
import { Form } from "../../ui/form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import TextAreaFormField from "../../reusable/forms/TextAreaFormField"
import { Icon } from "../../ui/icon"
import { Icons } from "../../../utils/icons"
import { useDispatch, useSelector } from "react-redux"
import { createNetwork } from "../../../redux/features/networks/createNetworkSlice"
import ButtonLoading from "../../reusable/loaders/ButtonLoading"

const formSchema = z.object({
    description: z.string().min(1, {
        message: "Description is required"
    }),
    collegeId: z.string()
})
const SendInviteModal = ({ collegeName, collegeId }) => {

    const { loading } = useSelector(state => state.createNetworkReducer)
    const dispatch = useDispatch()
    const triggerButton = <Button variant="primary" size="sm">Connect</Button>

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            description: "",
            collegeId: collegeId
        }
    })

    const onSubmit = (values) => {
        dispatch(createNetwork(values))
    }
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <CommonModal title={`Send Invitation to ${collegeName}`} triggerButton={triggerButton}>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                        <TextAreaFormField name="description" control={form.control} placeholder={"Enter Invitation body"} label={"Invitation Body"} />

                        <div className="flex w-full justify-between items-center">
                            {/* <Button type="" variant="light" className="border rounded-3xl shadow-md flex items-center gap-x-2">
                                <Icon icon={Icons.UPLOAD} />
                                Upload attachment
                            </Button> */}
                            {
                                loading ? <ButtonLoading /> :
                                    <Button type="submit" variant="primary" className="rounded-3xl shadow-md">
                                        Send Invite
                                    </Button>
                            }
                        </div>
                    </form>
                </Form>
            </CommonModal>
        </div>
    )
}
export default SendInviteModal