import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from "../../ui/form";
  import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "../../ui/select";
  
  const SelectFormFieldDisable = ({
    control,
    name,
    handleSelectChange,
    index,
    placeholder,
    label,
    className,
    items,
    selectedItems = [],
  }) => {
    return (
      <FormField
        control={control}
        name={name}
        render={({ field }) => (
          <FormItem className={className}>
            <FormLabel>{label}</FormLabel>
            <Select
            onValueChange={(value) => {
              field.onChange(value); // Set the selected value to the form field
              if (handleSelectChange) {
                handleSelectChange(value, index);
              }
            }}
            defaultValue={field.value} 
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder={placeholder} />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {items.map((item, i) => (
                  <SelectItem
                    key={i}
                    value={item.value}
                    disabled={selectedItems.includes(item.value)}
                  >
                    {item.value}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  };
  export default SelectFormFieldDisable;
  