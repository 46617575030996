import { useDispatch, useSelector } from "react-redux"
import { Button } from "../../ui/button"
import { startDrive } from "../../../../src/redux/features/drives/startDriveSlice";
import { startNextRound } from "../../../../src/redux/features/drives/startNextRoundSlice";
import { endDrive } from "../../../../src/redux/features/drives/endDriveSlice";
import ButtonLoading from "../../reusable/loaders/ButtonLoading";
import { Loader } from "lucide-react";

const DriveDetailsInfo = () => {
  const { driveDetails } = useSelector(state => state.driveDetailsReducer)
  const { loading: startDriveLoading } = useSelector(state => state.startDriveReducer)
  const { loading: startNextRoundLoading } = useSelector(state => state.startNextRoundReducer)
  const { loading: endDriveLoading } = useSelector(state => state.endDriveReducer)

  const currentRoundId = driveDetails?.currentRoundId;
  const statusObj = driveDetails?.driveRounds.find((round) => round.roundId === currentRoundId)
  const round = driveDetails?.roundTemplate?.rounds.find((round) => round.id === currentRoundId);
  const isLastRound = currentRoundId && currentRoundId === driveDetails.driveRounds[driveDetails.driveRounds.length - 1].roundId
  const statusName = round?.roundStatuses?.find((status) => status.id === statusObj.statusId);

  const dispatch = useDispatch()

  const handleDriveRound = () => {
    if (isLastRound) {
      return dispatch(endDrive(driveDetails.id))
    }
    !currentRoundId ? dispatch(startDrive(driveDetails.id)) : dispatch(startNextRound(driveDetails.id))
  }

  return (
    <div className="border shadow-xl px-8 py-8 rounded-3xl rounded-tl-none">
      <div className="grid grid-cols-8 gap-x-8">
        <div className="shadow-lg px-4 col-span-5 py-4 flex flex-col gap-y-4 rounded-md border">
          <div className="flex flex-col gap-y-2">
            <h1 className="font-semibold text-primaryButtonColor text-lg">Job Description</h1>
            <p className="">{driveDetails?.description}</p>
          </div>
          <div className="flex flex-col gap-y-2 ">
            <h1 className="font-semibold text-primaryButtonColor text-lg">Eligibilty Criteria</h1>
            <p className="">{driveDetails?.eligibilityCriteria}</p>
          </div>
        </div>
        <div className="shadow-lg col-span-3 px-4 py-4 flex flex-col gap-y-4 rounded-md border">
          <h1 className="font-semibold text-primaryButtonColor text-lg">Key Information</h1>
          <div>
            <p className="text-base font-semibold text-primaryButtonColor">Current Round</p>
            <p className="font-medium">{driveDetails?.currentRound?.title}</p>
          </div>
          <div>
            <p className="text-base font-semibold text-primaryButtonColor">Round Status</p>
            <p className="font-medium">{statusName?.title}</p>
          </div>

          <Button onClick={handleDriveRound} variant="primary" className="w-fit rounded-3xl">
            {startDriveLoading || startNextRoundLoading || endDriveLoading ? <Loader className="animate-spin text-white" /> : isLastRound ? "End Drive" : driveDetails?.currentRoundId ? "Start Next Round" : "Start Drive"}
          </Button>

          <div>
            <p className="text-base font-semibold text-primaryButtonColor">Collaborators</p>
            {
              driveDetails?.collaborators?.map((collaborator, i) => {
                return <p key={i} className="font-medium underline text-accentColor">{collaborator.name}</p>
              })
            }
          </div>
        </div>

      </div>
    </div>
  )
}
export default DriveDetailsInfo