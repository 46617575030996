import { useSelector } from "react-redux";
import CommonGraphCard from "../reusable/CommonGraphCard";
import {
    Chart as ChartJs,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import GraphLoader from "../reusable/loaders/GraphLoader";
ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const JobApplicantsGraph = () => {
    const { jobApplicantsGraph, loading } = useSelector(state => state.getJobApplicantsReducer)

    const labels = jobApplicantsGraph?.trends.slice(1).map(item => item[0]);
    const applicantsData = jobApplicantsGraph?.trends.slice(1).map(item => item[1]);

    const data = {
        labels,
        datasets: [
            {
                label: jobApplicantsGraph?.trends[0][1],
                data: applicantsData,
                backgroundColor: '#FEB8B9',
                borderColor: '#D77275',
                borderWidth: 1
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                min: 0
            }
        }
    };
    return (
        <div>
            <CommonGraphCard loading={loading} loader={<GraphLoader />} title={"Jobs Statistics"}>
                <Bar data={data} options={options} />
            </CommonGraphCard>
        </div>
    )
}
export default JobApplicantsGraph