export const CampusRecruitmentColumns = [
    {
        accessorKey: "colleges",
        header: "College Name",
        cell: ({ row }) => {
            const college = row.original.colleges[0]
            return college ? <p>{college.title}</p> : <p>-</p>
        }
    },
    // {
    //     accessorKey: "title",
    //     header: "Drive Name",
    // },
    {
        accessorKey: "currentRound",
        header: "Ongoing Round",
        cell: ({ row }) => {
            const currentRound = row.original.currentRound
            return <p>{currentRound ? currentRound.title : "NA"}</p>
        }
    },
]