import logo from '../../assets/logos/logo.png'
import profilePic from '../../assets/images/profilePic.png'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/features/auth/authSlice'
import { auth } from '../../config/firebaseConfig'
import CommonDropdown from './dropdowns/CommonDropdown'
const Navbar = ({showProfilebadge=true}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.authReducer)
  const handleLogout = async () => {
    await auth.signOut()
    dispatch(logout())
  }
  const dropdownItems = [
    {
      name: "Profile",
      func: () => navigate("/profile")
    },
    {
      name: "My Team",
      func: () => navigate("/my-team")
    },
    {
      name: "Signout",
      func: () => handleLogout()
    },
  ]
  return (
    <nav className="bg-white z-[40] h-16 flex items-center fixed top-0 left-8 right-8">
      <div className="flex items-center w-full justify-between">
        <div className="flex items-center gap-x-2">
          <img className="max-w-[120px]" src={logo} alt="" />
        </div>
        <div className="flex items-center gap-x-2">
          <div className="flex flex-col items-end">
            <p className="text-lg font-semibold">{user?.name}</p>
            <p className="text-sm font-light">{user?.email}</p>
            {/* <NavbarSelect /> */}
          </div>
          {showProfilebadge&&
          <CommonDropdown items={dropdownItems}>
            <img className='max-w-[60px]' src={profilePic} alt="" />
          </CommonDropdown>}
        </div>
      </div>

    </nav>
  )
}
export default Navbar