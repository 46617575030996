import navigateBack from "../../utils/backNavigateUtil"
import { Icons } from "../../utils/icons"
import { useNavigate } from "react-router-dom"
import { Icon } from "../ui/icon"
import { useSelector } from "react-redux"

const NetworkDetailsHeader = () => {

    const { networkDetails } = useSelector(state => state.networkDetailsReducer)

    const navigate = useNavigate()
    return (
        <div className="relative">
            <div className="flex items-center cursor-pointer hover:bg-primaryButtonColor/90 justify-center absolute -top-2 -left-4 w-10 h-10 rounded-full bg-primaryButtonColor p-2">
                <Icon onClick={() => navigateBack(navigate)} icon={Icons.BACKARROW} className="text-lightColor w-5 h-5" />
            </div>
            <div className="shadow-lg rounded-lg px-6 py-6 border">
                <div className="flex items-center gap-x-2">
                    <img className="w-20 h-20 rounded-full object-cover" src={networkDetails?.college.logoUrl} alt="" />
                    <div className="flex flex-col">
                        <h1 className="text-2xl font-semibold">{networkDetails?.college?.title}</h1>
                        <p>{networkDetails?.college?.address}</p>
                    </div>
                </div>
                <div className="flex justify-end -mt-4">
                    <div className="bg-successColor rounded-2xl px-4 py-1 text-lightColor text-sm">
                        <p className="">{networkDetails?.connectionStatus}</p>
                    </div>
                </div>
            </div>
        </div>)
}
export default NetworkDetailsHeader