const RecentlyConnectedCollegeCard = ({ logo, name }) => {
    return (
        <div className="flex items-center gap-x-4">
            {
                logo ?
                    <img className="w-10 h-10 object-cover rounded-full flex-shrink-0" src={logo} /> :
                    <div className="w-10 h-10 bg-gray-600 rounded-full flex-shrink-0">

                    </div>
            }
            <p className="font-medium text-sm line-clamp-2">{name}</p>
        </div>
    )
}
export default RecentlyConnectedCollegeCard