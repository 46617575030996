import { cn } from "../../../lib/utils"
import { Icons } from "../../../utils/icons"
import { Button } from "../../ui/button"
import { Calendar } from "../../ui/calendar"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/form"
import { Icon } from "../../ui/icon"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../../ui/popover"
import { addYears, format } from "date-fns"
const DatePickerFormField = ({ control, name, placeholder, label, className }) => {

    const today = new Date();
    const oneYearFromToday = addYears(today, 1);

    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem className={cn("flex flex-col", className)}>
                    <FormLabel>{label}</FormLabel>
                    <Popover>
                        <PopoverTrigger asChild>
                            <FormControl>
                                <Button
                                    variant={"outline"}
                                    className={cn(
                                        "font-normal flex items-center justify-between",
                                        !field.value && "text-muted-foreground",
                                    )}
                                >
                                    {field.value ? (
                                        format(field.value, "PPP")
                                    ) : (
                                        <span>{placeholder}</span>
                                    )}
                                    <Icon icon={Icons.CALENDAR} />
                                </Button>
                            </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                                mode="single"
                                selected={field.value}
                                onSelect={field.onChange}
                                disabled={(date) =>
                                    date < today || date > oneYearFromToday
                                }
                                initialFocus
                            />
                        </PopoverContent>
                    </Popover>
                    <FormMessage />
                </FormItem>
            )}
        />)
}
export default DatePickerFormField