import { useDispatch, useSelector } from "react-redux"
import { DataTable } from "../../table/DataTable"
import { DriveStatusColumns } from "../columns/DriveStatusColumns"
import { Button } from "../../ui/button"
import CommonSelect from "../../reusable/dropdowns/CommonSelect"
import { Link } from "react-router-dom"
import { udpdateDriveStatus, updateDriveRounds } from "../../../redux/features/drives/getDriveDetailsSlice"
import { updateDrive } from "../../../redux/features/drives/updateDriveSlice"
import { updateDriveRoundStatus } from "../../../redux/features/drives/updateDriveRoundStatusSlice"
import { Loader } from "lucide-react"

const DriveStatusInfo = () => {

  const { driveDetails, loading, driveRounds } = useSelector(state => state.driveDetailsReducer)
  const { driveStatuses } = useSelector(state => state.driveStatusReducer)
  const { loading: roundStatusUpdateLoading } = useSelector(state => state.updateDriveRoundStatusReducer)

  const dispatch = useDispatch()
  const statusItems = driveStatuses.map((status) => {
    return {
      value: status.id,
      label: status.label
    }
  })

  const statusFilterChange = (value) => {
    dispatch(udpdateDriveStatus(value))
  }

  const saveChanges = () => {
    dispatch(updateDrive({ driveId: driveDetails.id, statusId: driveDetails.driveStatusId }))
    dispatch(updateDriveRoundStatus({ driveId: driveDetails.id, body: driveRounds }))
  }
  return (
    <div className="border shadow-xl px-8 py-8 rounded-3xl rounded-tl-none">
      {
        loading ? <p>Loading</p> :
          <>
            <div className="flex justify-between">
              <div className="flex flex-col gap-y-4">
                <div>
                  <h1 className="font-semibold text-primaryButtonColor text-lg">Drive Status</h1>
                  <CommonSelect defaultValue={driveDetails?.driveStatusId} hideNone={true} handleSelectChange={statusFilterChange} selectPlaceholder={"Status"} items={statusItems} />
                </div>
                <div>
                  <h1 className="font-semibold text-primaryButtonColor text-lg">Drive Template</h1>
                  <Link to={`/templates/${driveDetails?.roundTemplate?.id}`} className="font-medium underline text-accentColor">{driveDetails?.roundTemplate?.title}</Link>
                </div>

                <div>
                  <h1 className="font-semibold text-primaryButtonColor text-lg">Current Round</h1>
                  <p className="font-medium">{driveDetails?.currentRound ? driveDetails?.currentRound?.title : "The drive has not yet started"}</p>
                </div>
              </div>

              <div className="flex flex-col gap-y-2">
                <h1 className="font-semibold text-primaryButtonColor text-lg">Round Status</h1>

                {
                  !loading && driveDetails?.roundTemplate?.rounds && <DataTable hasClick={false} hasPagination={false} columns={DriveStatusColumns} data={driveDetails?.roundTemplate?.rounds} />
                }

                <div className="flex justify-end">
                  <Button disabled={roundStatusUpdateLoading} onClick={saveChanges} variant="primary" className="rounded-3xl shadow-md hover:shadow-none">
                    {
                      roundStatusUpdateLoading ? <Loader className="animate-spin" /> : "Save Changes"
                    }
                  </Button>
                </div>
              </div>
            </div>
          </>

      }
    </div>
  )
}
export default DriveStatusInfo

export const DriveStatusSelect = ({ items, roundId }) => {

  const { driveRounds } = useSelector(state => state.driveDetailsReducer)
  const round = driveRounds.find(r => r.roundId === roundId)
  const dispatch = useDispatch()
  const handleFilterChange = (value) => {
    dispatch(updateDriveRounds({ roundId, statusId: value }))
  }

  return (
    <CommonSelect hideNone={true} defaultValue={round?.statusId} items={items} selectPlaceholder={"Status"} handleSelectChange={handleFilterChange} />
  )
}