import { getDriveApplications } from "../../../redux/features/drives/getDriveApplicationsSlice"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { DataTable } from "../../table/DataTable"
import { DriveApplicantsColumns } from "../columns/DriveApplicantsColumns"
import CommonOutletSearch from "../../reusable/layout/CommonOutletSearch"

const DriveApplicantsInfo = ({ pagination, setPagination }) => {

  const { jobApplications, loading } = useSelector(state => state.driveApplicationReducer)

  return (
    <div className="border shadow-xl px-8 py-4 rounded-3xl rounded-tl-none">
      <CommonOutletSearch placeholder={"Search for applicants"} selectPlaceholder={"Filter by: College Name"} filterItems={[]} />
      {
        !loading && jobApplications && <DataTable hasClick={false} pagination={pagination} setPagination={setPagination} columns={DriveApplicantsColumns} data={jobApplications} />
      }
    </div>
  )
}
export default DriveApplicantsInfo