import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getNotifications = createAsyncThunk('getNotifications', async (query, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get("/notifications")
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getNotificationSlice = createSlice({
    name: "Notfications",
    initialState: {
        loading: false,
        error: null,
        notifications: []
    },
    extraReducers: (builder) => {
        builder.addCase(getNotifications.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getNotifications.fulfilled, (state, action) => {
            state.loading = false;
            state.notifications = action.payload
        })
        builder.addCase(getNotifications.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export default getNotificationSlice.reducer