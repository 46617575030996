import { useSelector } from "react-redux"
import CommonOutletSearch from "../../reusable/layout/CommonOutletSearch"
import { JobApplicantsColumns } from "../columns/JobApplicantsColumns"
import { DataTable } from "../../table/DataTable"

const JobDetailsApplicants = ({ pagination, setPagination }) => {
  const { jobApplications, loading } = useSelector(state => state.jobApplicationReducer)

  return (
    <div className="border shadow-xl px-8 py-4 rounded-3xl rounded-tl-none">
      <CommonOutletSearch placeholder={"Search for applicants"} selectPlaceholder={"Filter by: College Name"} filterItems={[]} />
      {
        !loading && jobApplications && <DataTable hasClick={false} pagination={pagination} setPagination={setPagination} columns={JobApplicantsColumns} data={jobApplications} />
      }
    </div>)
}
export default JobDetailsApplicants