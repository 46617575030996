import { useDispatch, useSelector } from "react-redux"
import Common from "../reusable/Common"
import { useEffect } from "react"
import { getJobTypeCtc } from "../../redux/features/analytics/getJobTypeCtcSlice"

import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
const ReportsBarChart = () => {

  const dispatch = useDispatch()
  const { ctcGraph, loading } = useSelector(state => state.jobTypeCtcReducer)
  useEffect(() => {
    dispatch(getJobTypeCtc())
  }, [])

  const data = {
    labels: ctcGraph?.map((item) => item.title),
    datasets: [
      {
        label: "Max Ctc",
        data: ctcGraph?.map((item) => item.ctcMax),
        backgroundColor: "#FEB8B9",
      },
      {
        label: "Min Ctc",
        data: ctcGraph?.map((item) => item.ctcMin),
        backgroundColor: "#D77275",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Ctc",
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Job Type",
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    barThickness: 50
  };
  return (
    <div className="col-span-2 row-span-2">
      <Common title={"Reports"} link={"/reports"}>
        <div className="h-full">
          <Bar data={data} options={options} />
        </div>
      </Common>
    </div>
  )
}
export default ReportsBarChart