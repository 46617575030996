import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";
import { connectionStatusEnum } from "../../../utils/enums";

export const updateConnectionStatus = createAsyncThunk('updateConnectionStatus', async (obj, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/network/${obj.connectionId}/${obj.type}`)
        return data;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const updateConnectionStatusSlice = createSlice({
    name: "Update network connection status",
    initialState: {
        loading: false,
        error: null,
        successMsg: null,
        success: null
    },
    reducers: {
        resetConnectionStatusSuccessMsg: (state, action) => {
            state.successMsg = null
            state.success = false
        },
        resetConnectionStatusError: (state, action) => {
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateConnectionStatus.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(updateConnectionStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.successMsg = action.payload.connectionStatus === connectionStatusEnum.ACCEPTED ? "Invitation Accepted Successfully" : "Invitation Rejected Successfully"
        })
        builder.addCase(updateConnectionStatus.rejected, (state, action) => {
            state.loading = false;
        })
    }
})
export const { resetConnectionStatusSuccessMsg, resetConnectionStatusError } = updateConnectionStatusSlice.actions
export default updateConnectionStatusSlice.reducer