import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const updateProfile = createAsyncThunk('updateProfile', async (body, { rejectWithValue }) => {
    try {
        await apiService.put("/current-user", body)
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

const updateProfileSlice = createSlice({
    name: "Update Profile",
    initialState: {
        loading: false,
        error: null,
        successMsg: null
    },
    reducers: {
        resetProfileSuccessMsg: (state, action) => {
            state.successMsg = null
        },
        resetProfileError: (state, action) => {
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateProfile.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(updateProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.successMsg = "Profile Updated Successfully"
        })
        builder.addCase(updateProfile.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export const { resetProfileSuccessMsg, resetProfileError } = updateProfileSlice.actions
export default updateProfileSlice.reducer