import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getCampusRecruitment = createAsyncThunk('getCampusRecruitment', async (query, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get("/drives", query)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getCampusRecruitmentSlice = createSlice({
    name: "CampusRecruitment",
    initialState: {
        loading: false,
        error: null,
        drives: [],
    },
    extraReducers: (builder) => {
        builder.addCase(getCampusRecruitment.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getCampusRecruitment.fulfilled, (state, action) => {
            state.loading = false;
            state.drives = action.payload
        })
        builder.addCase(getCampusRecruitment.rejected, (state, action) => {
            state.loading = false;
        })

    }
})

export default getCampusRecruitmentSlice.reducer