import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";
export const createTemplateRound = createAsyncThunk('createTemplateRound', async (obj, { rejectWithValue }) => {
    try {
        await apiService.post(`/round-templates/${obj.roundTemplateId}/rounds`, obj.body)
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

const createTemplateRoundSlice = createSlice({
    name: "Create Template Round",
    initialState: {
        loading: false,
        error: null,
        success: false,
        successMsg: null,
    },
    reducers: {
        resetSuccessMsg: (state, action) => {
            state.successMsg = null
            state.success = false
        },
        resetError: (state, action) => {
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createTemplateRound.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(createTemplateRound.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
            state.successMsg = "Round created Succssfully"
        })
        builder.addCase(createTemplateRound.rejected, (state, action) => {
            state.loading = false;
        })
    }
})
export const { resetSuccessMsg, resetError } = createTemplateRoundSlice.actions

export default createTemplateRoundSlice.reducer