import { useNavigate } from "react-router-dom"
import { DriveRoundTemplateStatus } from "../../../utils/enums"
import { Button } from "../../ui/button"
import { useDispatch, useSelector } from "react-redux"
import { activateTemplate, archiveTemplate } from "../../../redux/features/drive-template/updateDriveTemplateStatusSlice"

const StatusButton = ({ status, id }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { loading, success, error } = useSelector(state => state.driveTemplateReducer)

    const updateTemplateStatus = () => {
        if (status === DriveRoundTemplateStatus.DRAFT) {
            dispatch(activateTemplate(id));
        } else if (status === DriveRoundTemplateStatus.ACTIVE) {
            dispatch(archiveTemplate(id));
        }
    }

    return (
        <div className="flex items-center gap-x-6">
            {
                status === DriveRoundTemplateStatus.DRAFT ?
                    <Button onClick={updateTemplateStatus} size="sm" className="bg-activeColor hover:bg-activeColor/90">Activate</Button> :
                    status === DriveRoundTemplateStatus.ACTIVE ?
                        <Button onClick={updateTemplateStatus} variant="primary" size="sm" className="bg-archiveColor hover:bg-archiveColor/90">Archive</Button> :
                        <Button variant="light" size="sm">Archived</Button>
            }
            <Button variant="primary" size="sm" onClick={() => navigate(`/templates/${id}`)}>View Details</Button>
        </div>
    )
}
export default StatusButton