import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const createJob = createAsyncThunk('createJob', async (body, { rejectWithValue }) => {

    try {
        await apiService.post("/jobs", body)
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const createJobSlice = createSlice({
    name: "Create Jobs",
    initialState: {
        loading: false,
        error: null,
        success: false,
        successMessage: null
    },
    reducers: {
        resetCreateJobSuccess: (state) => {
            state.successMessage = null;
            state.success = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createJob.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(createJob.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
            state.successMessage = "Job Created Successfully"
        })
        builder.addCase(createJob.rejected, (state, action) => {
            state.loading = false;
        })
    }
})
export const { resetCreateJobSuccess } = createJobSlice.actions
export default createJobSlice.reducer