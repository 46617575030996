import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getJobs = createAsyncThunk('getJobs', async (query, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get("/jobs", query)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getJobslice = createSlice({
    name: "Jobs",
    initialState: {
        loading: false,
        error: null,
        jobs: []
    },
    extraReducers: (builder) => {
        builder.addCase(getJobs.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getJobs.fulfilled, (state, action) => {
            state.loading = false;
            state.jobs = action.payload
        })
        builder.addCase(getJobs.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export default getJobslice.reducer