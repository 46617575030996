const CommonGraphCard = ({ title, children, loading, loader }) => {
  return (
    <div className="border shadow-xl rounded-md p-4 h-full">
      <h1 className="font-semibold text-primaryButtonColor text-lg">{title}</h1>
      {
        loading ? loader :
          <div className="mt-4">
            {children}
          </div>
      }
    </div>
  )
}
export default CommonGraphCard