import { useLocation, useNavigate } from "react-router-dom"
import SidebarItem from "./SidebarItem"
import { sidebarItems } from "../../lib/sidebarItems"
import { useState } from "react"
import { cn } from "../../lib/utils"
import '../../App.css'
import { closeDrawer, openDrawer } from "../../redux/features/sidebarSlice"
import { useDispatch, useSelector } from "react-redux"
const Sidebar = () => {
  const { pathname } = useLocation()
  const { open } = useSelector(state => state.sidebarReducer)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleDrawer = (value) => {
    if (value) {
      dispatch(openDrawer())
    } else {
      dispatch(closeDrawer())
    }
  }
  return (
    <div onMouseEnter={() => handleDrawer(true)} onMouseLeave={() => handleDrawer(false)} className={cn(`fixed h-[88vh] transition-all duration-700 bg-white shadow-lg border rounded-lg py-8 px-6 mt-10 overflow-hidden`, open ? "sidebar-expand" : "sidebar-collapsed")}>
      <div className={cn("flex flex-col",open ? "gap-y-5" : "gap-y-12")}>
        {sidebarItems.map((item, index) => (
          <SidebarItem pathname={pathname} item={item} navigate={navigate} key={index} />
        ))}
      </div>
    </div>)
}
export default Sidebar