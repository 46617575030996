import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getJobWiseOpenings = createAsyncThunk('getJobWiseOpenings', async (year, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/graphs/analytics/job/openings-per-job-title/${year}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getJobWiseOpeningsSlice = createSlice({
    name: "Job Openings Graph",
    initialState: {
        loading: false,
        error: null,
        jobOpeningsGraph: null
    },
    extraReducers: (builder) => {
        builder.addCase(getJobWiseOpenings.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getJobWiseOpenings.fulfilled, (state, action) => {
            state.loading = false;
            state.jobOpeningsGraph = action.payload
        })
        builder.addCase(getJobWiseOpenings.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export default getJobWiseOpeningsSlice.reducer