import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/form"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select"

const SelectFormField = ({ mandatory,control, name, multiple = false, placeholder, label, className, items }) => {

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel>{label} {mandatory ? <span className="text-red-500">*</span> : ""}</FormLabel>
          <Select onValueChange={field.onChange} defaultValue={field.value}>
            <FormControl>
              <SelectTrigger>
                <SelectValue multiple={multiple} placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {
                items.map((item, i) => {
                  return <SelectItem key={i} value={item.value}>{item.label}</SelectItem>
                })
              }
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />)
}
export default SelectFormField