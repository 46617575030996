import { driveStatus } from "./enums"

export const formatDriveStatus = (status) => {
    switch (status) {
        case driveStatus.REQUEST_RECEIVED:
            return driveStatus.REQUEST_SENT
        case driveStatus.REQUEST_SENT:
            return driveStatus.REQUEST_RECEIVED
        default:
            return status
    }
}