import { Form } from "../../components/ui/form";
import InputFormField from "../../components/reusable/forms/InputFormField";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFieldArray } from "react-hook-form";
import TextAreaFormField from "../../components/reusable/forms/TextAreaFormField";
import ButtonLoading from "../../components/reusable/loaders/ButtonLoading";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import { useState } from "react";
import MultiSelectFormField from "../../components/reusable/forms/MultiSelectFormField ";
import SelectFormField from "../../components/reusable/forms/SelectFormField";
import SelectFormFieldDisable from "../../components/reusable/forms/SelectFormFieldDisable";
import { useDispatch, useSelector } from "react-redux";
import { createJobForm, fetchJobFormCollegeDetails } from "../../redux/features/jobs_form/createJobFormSlice";
import { Input } from "../../components/ui/input";
import { toast } from "sonner";
import { useEffect } from "react";
import {
  recruitmentMode,
  OPTIONS,
} from "../../utils/dataModel";
import { useParams } from "react-router-dom";
import { IoIosInformationCircleOutline } from "react-icons/io";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip"



const optionSchema = z.object({
  label: z.string(),
  value: z.string(),
});

const stopObj = z.object({
  branch: z.string(),
  hiring: z.string(),
});
const formSchema = z.object({
  companyName: z.string().min(2, {
    message: "Company name must be at least 2 characters long",
  }),
  file: z.any().optional().nullable(),
  sector: z
    .string()
    .min(2, {
      message: "Sector must be at least 2 characters long",
    })
    .max(50, {
      message: "Sector cannot be more than 50 characters long",
    }),
  annualTurnover: z.string().min(5, {
    message: "Annual turnover must be a positive number",
  }),
  address: z
    .string()
    .min(2, {
      message: "Address must be at least 2 characters long",
    })
    .max(50, {
      message: "Address cannot be more than 50 characters long",
    }),
  jobProfile: z
    .string()
    .min(2, {
      message: "Job profile must be at least 2 characters long",
    })
    .max(50, {
      message: "Job profile cannot be more than 50 characters long",
    }),
  eligibilityCriteria: z
    .string()
    .min(2, {
      message: "Eligibility criteria must be at least 2 characters long",
    })
    .max(50, {
      message: "Eligibility criteria cannot be more than 50 characters long",
    }),
  designation: z
    .string()
    .min(2, {
      message: "Designation must be at least 2 characters long",
    })
    .max(50, {
      message: "Designation cannot be more than 50 characters long",
    }),
  locationOfPosting: z
    .string()
    .min(2, {
      message: "Location of posting must be at least 2 characters long",
    })
    .max(50, {
      message: "Location of posting cannot be more than 50 characters long",
    }),
  branch: z.array(optionSchema).min(1, {
    message: "At least one branch must be selected",
  }),
  modeOfRecruitment: z
    .string()
    .min(2, {
      message: "Mode of recruitment must be at least 2 characters long",
    })
    .max(50, {
      message: "Mode of recruitment cannot be more than 50 characters long",
    }),
  jobDescription: z.string().min(2, {
    message: "Job description is required",
  }),
  selectionProcedure: z.array(z.string()).min(1, {
    message: "There must be at least one round in the selection procedure",
  }),
  ctcOffered: z.string().min(2, {
    message: "CTC offered must be a positive number",
  }),
  contactName: z
    .string()
    .min(2, {
      message: "Contact name must be at least 2 characters long",
    })
    .max(50, {
      message: "Contact name cannot be more than 50 characters long",
    }),
  contactEmail: z.string().email({
    message: "Invalid email address",
  }),
  contactPhoneNumber: z
    .string()
    .min(10, {
      message: "Contact phone number must be 10 characters long",
    })
    .max(10, {
      message: "Contact phone number must be 10 characters long",
    }),
  targetPool: z.array(stopObj).min(1, {
    message: "At least one target pool must be selected",
  }),
});

const JobFormPage = () => {

  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const {collegeId} = useParams();
  const { successMessage, loading, error, college } = useSelector(
    (state) => state.createJobFormReducer
  );

  useEffect(() => {
    dispatch(fetchJobFormCollegeDetails(collegeId))
  }, [])
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      companyName: "",
      sector: "",
      annualTurnover: "",
      address: "",
      file: null,
      ctcOffered: "",
      jobProfile: "",
      designation: "",
      eligibilityCriteria: "",
      locationOfPosting: "",
      branch: [],
      modeOfRecruitment: "",
      jobDescription: "",
      contactName: "",
      contactPhoneNumber: "",
      contactEmail: "",
      selectionProcedure: [],
      targetPool: [],
    },
  });
  const { control, handleSubmit,reset } = form;
  const { fields, append } = useFieldArray({
    control,
    name: "targetPool",
  });

  const { fields: selectionProcedureFields, append: appendSelectionProcedure } =
    useFieldArray({
      control,
      name: "selectionProcedure",
    });

  const uploadFile = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const onSubmit = (values) => {
    const branchValues = values.branch.map((option) => option.value);

    const currentSelectedFile = selectedFile;

    const formData = new FormData();
    formData.append("file", currentSelectedFile);
    formData.append("nameOfCompany", values.companyName);
    formData.append("sector", values.sector);
    formData.append("annualTurnover", values.annualTurnover);
    formData.append("address", values.address);
    formData.append("branch", JSON.stringify(branchValues));
    formData.append("jobProfile", values.jobProfile);
    formData.append("designation", values.designation);
    formData.append("locationOfPosting", values.locationOfPosting);
    formData.append("mode_of_recruitment", values.modeOfRecruitment);
    formData.append(
      "selectionProcedure",
      JSON.stringify(values.selectionProcedure)
    );
    formData.append("ctcOffered", values.ctcOffered);
    formData.append("contactName", values.contactName);
    formData.append("contactEmail", values.contactEmail);
    formData.append("contactPhoneNumber", values.contactPhoneNumber);
    formData.append("targetPool", JSON.stringify(values.targetPool));
    formData.append("jobDescription", values.jobDescription);
    formData.append("eligibilityCriteria", values.eligibilityCriteria);
    formData.append("institutionId", collegeId);

    dispatch(createJobForm({ body: formData }));
  };

  useEffect(() => {
    if (successMessage) {
      toast.success("Form Submitted successfully");
    }
    if (error) {
      toast.error(error);
    }
  }, [successMessage, error]);

  const onError = (errors) => {
    console.log(errors);
  };

  const handleSelectChange = (value, index) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = [...prevSelectedItems];
      newSelectedItems[index] = value;
      return newSelectedItems;
    });
  };

  const handleAddSelect = () => {
    append({ branch: "", hiring: "" });
  };

  // Use slice to exclude the first element from the fields array
  const dynamicFields = fields.slice(1);

  const handleAddSelectionProcedure = () => {
    appendSelectionProcedure("");
  };

  const branches = college?.coursesOffered.map(option =>
    typeof option === 'string' ? { value: option, label: option } : option
);

  if (loading) {
    return <div>Loading</div>
  }
  return (
    <>
      <section className="container">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit, onError)}
            className="space-y-4 mt-6"
          >
            <section id="company Details">
              <div className="bg-white rounded-lg shadow-lg p-4">
                <h1 className="text-2xl font-bold">Job Form</h1>
                <p className="text-xl font-semibold">
                  Shared by {college.title}
                </p>
              </div>

              <div className="p-4">
                <p className="text-xl font-semibold border-b-2 border-[#666666]-500 border-solid">
                  Company Details
                </p>
                <div className="flex flex-row justify-between">
                  <div className="space-y-4 w-full md:w-1/2 pr-4">
                    <InputFormField
                      name="companyName"
                      control={form.control}
                      placeholder=""
                      label="Name of Company"
                      type="text"
                      className="flex items-center w-full"
                    />
                    <InputFormField
                      name="sector"
                      control={form.control}
                      placeholder=""
                      label="Sector"
                      type="text"
                      className="flex items-center w-full"
                    />
                    <InputFormField
                      name="annualTurnover"
                      control={form.control}
                      placeholder=""
                      label="Average Turnover"
                      type="number"
                      className="flex items-center w-full"
                    />
                  </div>
                  <div className="w-full md:w-1/2">
                    <TextAreaFormField
                      name="address"
                      control={form.control}
                      placeholder=""
                      label="Address"
                      className="flex flex-row w-full"
                    />
                    <div className="flex items-center mt-6">
                      <Label htmlFor="picture" className="w-[50%]">
                        Company Logo<span className="text-red-500">*</span>
                      </Label>
                      <Input
                        id="picture"
                        type="file"
                        onChange={uploadFile}
                        name="file"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="Job Details">
              <div className="p-4">
                <p className="text-xl font-semibold border-b-2 border-[#666666]-500 border-solid">
                  Job Details
                </p>

                <div className="flex flex-row justify-between">
                  <div className="space-y-4 w-full md:w-1/2 pr-4">
                    <InputFormField
                      name="jobProfile"
                      control={form.control}
                      placeholder=""
                      label="Job Profile"
                      type="text"
                      className="flex items-center w-full"
                    />
                    <TextAreaFormField
                      name="jobDescription"
                      control={form.control}
                      placeholder=""
                      label="Job Description"
                      className="flex flex-row w-full"
                    />
                    <InputFormField
                      name="designation"
                      control={form.control}
                      placeholder=""
                      label="Designation"
                      type="text"
                      className="flex items-center w-full"
                    />
                    <TextAreaFormField
                      name="eligibilityCriteria"
                      control={form.control}
                      placeholder=""
                      label="Eligibility Criteria"
                      className="flex flex-row w-full"
                    />
                  </div>
                  <div className="w-full md:w-1/2">
                    <div className="flex items-center w-full mb-4 mt-4">
                      <Label className="w-full">
                        Branches<span className="text-red-500">*</span>
                      </Label>
                      <div className="w-full">
                        <MultiSelectFormField
                          className={"w-full"}
                          name={"branch"}
                          control={form.control}
                          placeholder="Select Prefered Branches for drive"
                          options={branches}
                        />
                      </div>
                    </div>
                    <div className="flex items-center w-full mb-4">
                      <Label className="w-full flex items-center">
                        Mode of recruitment 
                        <span className="text-red-500 ml-1">*</span>
                        <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger onClick={event => event.preventDefault()}>
                            <span className="text-red-500 ml-1 flex items-center text-xl">
                              <IoIosInformationCircleOutline />
                            </span>
                          </TooltipTrigger>
                          <TooltipContent className="w-72">
                            <p className="mb-1"><strong>Virtual :</strong> Drive will be conducted entirely online.</p>
                            <p className="mb-1"><strong>In-Person :</strong> Drive will be conducted at a physical location.</p>
                            <p><strong>Hybrid :</strong> Drive will be will be a mix of online and in-person.</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                      </Label>
                      <div className="w-full">
                        <SelectFormField
                          name="modeOfRecruitment"
                          items={recruitmentMode}
                          control={form.control}
                          placeholder="Select Mode"
                          handleSelectChange={handleSelectChange}
                          className="flex items-center w-full"
                        />
                      </div>
                    </div>
                    <InputFormField
                      name="locationOfPosting"
                      control={form.control}
                      placeholder=""
                      label="Location of Posting"
                      type="text"
                      className="flex items-center w-full"
                    />
                    <InputFormField
                      name="ctcOffered"
                      control={form.control}
                      placeholder=""
                      label="CTC offered"
                      type="number"
                      className="flex items-center w-full"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section id="Selection Procedure">
              <div className="p-4">
                <p className="text-xl font-semibold border-b-2 border-[#666666]-500 border-solid">
                  Selection Procedure
                </p>

                <div className="flex flex-row justify-between">
                  <div className="space-y-4 w-full md:w-1/2 pr-4">
                    <InputFormField
                      key={selectionProcedureFields[0]?.id || "first"}
                      name={`selectionProcedure.0`}
                      control={form.control}
                      placeholder=""
                      label={`Round 1 Name`}
                      type="text"
                      className="flex items-center w-full"
                    />
                    {selectionProcedureFields.slice(1).map((field, index) => (
                      <InputFormField
                        key={field.id}
                        name={`selectionProcedure.${index + 1}`}
                        control={form.control}
                        placeholder=""
                        label={`Round ${index + 2} Name`}
                        type="text"
                        className="flex items-center w-full"
                      />
                    ))}
                  </div>
                  <div className="w-full md:w-1/2 flex items-center">
                    <div>
                      <Button
                        variant="primary"
                        className="w-full py-5"
                        type="button"
                        onClick={handleAddSelectionProcedure}
                      >
                        <p>+ Add new round</p>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="Point of Contact">
              <div className="p-4">
                <p className="text-xl font-semibold border-b-2 border-[#666666]-500 border-solid">
                  Point of Contact
                </p>

                <div className="flex flex-row justify-between">
                  <div className="space-y-4 w-full md:w-1/2 pr-4">
                    <InputFormField
                      name="contactName"
                      control={form.control}
                      placeholder=""
                      label="Name of POC"
                      type="text"
                      className="flex items-center w-full"
                    />
                    <InputFormField
                      name="contactPhoneNumber"
                      control={form.control}
                      placeholder=""
                      label="Phone number"
                      type="text"
                      className="flex items-center w-full"
                    />
                  </div>
                  <div className="w-full md:w-1/2">
                    <InputFormField
                      name="contactEmail"
                      control={form.control}
                      placeholder=""
                      label="Email id"
                      type="text"
                      className="flex items-center w-full"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section id="Target Pool">
              <div className="p-4">
                <p className="text-xl font-semibold border-b-2 border-[#666666]-500 border-solid">
                  Target Pool
                </p>

                <div className="flex flex-row justify-between">
                  <div className="space-y-4 w-full md:w-1/2 pr-4">
                    <div className="flex gap-2">
                      <div className="flex items-center w-full mb-4">
                        <Label className="w-full">
                          {`Branch`}
                          <span className="text-red-500">*</span>
                        </Label>
                        <div className="w-full">
                          <SelectFormFieldDisable
                            name={`targetPool.0.branch`}
                            items={branches}
                            control={form.control}
                            placeholder=""
                            className="flex items-center w-full"
                            handleSelectChange={handleSelectChange}
                            selectedItems={selectedItems}
                            index={0}
                          />
                        </div>
                      </div>
                      <div className="flex items-center w-full mb-4">
                        <InputFormField
                          name={`targetPool.0.hiring`}
                          control={form.control}
                          placeholder="10"
                          label="Hiring"
                          type="number"
                          className="flex items-center w-full"
                        />
                      </div>
                    </div>
                    {dynamicFields.map((field, index) => (
                      <div className="flex gap-2" key={field.id}>
                        <div className="flex items-center w-full mb-4">
                          <Label className="w-full">
                            {`Branch`}
                            <span className="text-red-500">*</span>
                          </Label>
                          <div className="w-full">
                            <SelectFormFieldDisable
                              name={`targetPool.${index + 1}.branch`}
                              items={branches}
                              control={form.control}
                              placeholder=""
                              className="flex items-center w-full"
                              handleSelectChange={handleSelectChange}
                              selectedItems={selectedItems}
                              index={index + 1}
                            />
                          </div>
                        </div>
                        <div className="flex items-center w-full mb-4">
                          <InputFormField
                            name={`targetPool.${index + 1}.hiring`}
                            control={form.control}
                            placeholder="10"
                            label="Hiring"
                            type="number"
                            className="flex items-center w-full"
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="w-full md:w-1/2 flex items-center">
                    <div>
                      <Button
                        variant="primary"
                        className="w-full py-5"
                        type="button"
                        onClick={handleAddSelect}
                      >
                        <p>+ Add new branch</p>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="flex justify-end">
              {loading ? (
                <ButtonLoading />
              ) : (
                <Button
                  type="submit"
                  variant="primary"
                  className=" mt-4 p-5 text-base"
                >
                  Submit
                </Button>
              )}
            </div>
          </form>
        </Form>
      </section>
    </>
  );
};
export default JobFormPage;
