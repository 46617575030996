import { Skeleton } from "../../ui/skeleton"

const UpcomingEventsLoader = () => {
    return (
        <div className="flex h-full items-center flex-col gap-y-4">
            {
                [1, 2, 3, 4].map((_, i) => {
                    return <Skeleton className="w-full h-10 rounded-md" key={i} />
                })
            }
        </div>
    )
}
export default UpcomingEventsLoader