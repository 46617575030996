import { useEffect, useState } from "react"
import { DriveTemplateColumns } from "../../components/drives/columns/DriveTemplateColumns"
import { useDispatch, useSelector } from "react-redux"
import { DataTable } from "../../components/table/DataTable"
import CommonOutlet from "../../components/reusable/layout/CommonOutlet"
import CreateTemplateModal from "../../components/drives/modals/CreateTemplateModal"
import { getDriveTemplates } from "../../redux/features/drive-template/getDriveTemplateDetailsSlice"
import snackbar from "../../components/reusable/snackbar"
import { ToastEnum } from "../../utils/enums"
import { resetSuccessMsg } from "../../redux/features/drive-template/createDriveTemplateSlice"
import ListLoading from "../../components/reusable/loaders/ListLoading"
import { resetUpdateTemplateStatusError, resetUpdateTemplateStatusSuccessMsg } from "../../redux/features/drive-template/updateDriveTemplateStatusSlice"

const DriveTemplates = () => {
    const { driveTemplates, loading, error } = useSelector((state) => state.driveTemplateReducer);
    const { success: createSuccess, successMsg: createSuccessMsg } = useSelector((state) => state.createTemplateReducer);
    const { successMsg: updateSuccessMsg, error: updateError } = useSelector((state) => state.updateDriveTemplateReducer);
    const dispatch = useDispatch();

    const [pagination, setPagination] = useState({
        pageSize: 10,
        pageIndex: 0
    })
    useEffect(() => {
        if (createSuccess) {
            snackbar(ToastEnum.SUCCESS, createSuccessMsg)
            dispatch(resetSuccessMsg())
        }
        if (updateSuccessMsg) {
            snackbar(ToastEnum.SUCCESS, updateSuccessMsg)
            dispatch(resetUpdateTemplateStatusSuccessMsg())
        }
        if (updateError) {
            snackbar(ToastEnum.ERROR, updateError)
            dispatch(resetUpdateTemplateStatusError())
        }
        const { pageIndex, pageSize } = pagination

        dispatch(getDriveTemplates({ limit: pageSize, offset: pageSize * pageIndex }))
    }, [createSuccess, updateSuccessMsg, updateError, pagination])

    return (
        <div>
            <CommonOutlet
                title={"Drive Templates"}
                createButton={
                    <CreateTemplateModal />
                }>
                {
                    loading ? <ListLoading /> : driveTemplates && <DataTable hasClick={false} pagination={pagination} setPagination={setPagination} columns={DriveTemplateColumns} data={driveTemplates} />
                }
            </CommonOutlet>
        </div>
    )
}
export default DriveTemplates