import { Icons } from "../../../utils/icons"
import navigateBack from "../../../utils/backNavigateUtil"
import { Icon } from "../../ui/icon"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

const JobDetailsHeader = () => {

    const { jobDetails } = useSelector(state => state.jobDetailReducer)

    const navigate = useNavigate()
    return (
        <div className="relative">
            <div className="flex items-center cursor-pointer hover:bg-primaryButtonColor/90 justify-center absolute -top-2 -left-4 w-10 h-10 rounded-full bg-primaryButtonColor p-2">
                <Icon onClick={() => navigateBack(navigate)} icon={Icons.BACKARROW} className="text-lightColor w-5 h-5" />
            </div>
            <div className="shadow-lg rounded-lg px-6 py-6 border">
                <div className="flex flex-col">
                    <h1 className="text-2xl font-semibold">{jobDetails?.title}</h1>
                    {
                        jobDetails?.location.length > 1 ? <p>{jobDetails?.location} +{jobDetails?.location.length - 1} more</p> :
                            <p>{jobDetails?.location[0]}</p>
                    }
                    <div className="flex justify-end">
                        <div className="bg-successColor rounded-2xl px-4 py-1 text-lightColor text-sm">
                            <p className="">{jobDetails?.openings - jobDetails?.filled} openings left</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default JobDetailsHeader