import { useState } from "react"
import { Calendar } from "../../components/ui/calendar"
import { useDispatch, useSelector } from "react-redux"
import { format, lastDayOfMonth } from "date-fns"
import { useEffect } from "react"
import { getEvents } from "../../redux/features/drives/getEventsSlice"
const EventsCalendar = () => {

    const [driveDates, setDriveDates] = useState([]);
    const { loading, events } = useSelector(state => state.eventsReducer)
    const dispatch = useDispatch()
    const today = new Date()

    const firstDateOfMonth = format(today, 'yyyy-MM-01')
    const lastDateOfMonth = format(lastDayOfMonth(today), 'yyyy-MM-dd')

    useEffect(() => {
        dispatch(getEvents({ eventsAfterDate: firstDateOfMonth, eventsBeforeDate: lastDateOfMonth }))
    }, [])

    const handleMonthChange = (value) => {
        const firstDay = format(value, "yyyy-MM-01")
        const lastDay = format(lastDayOfMonth(value), "yyyy-MM-dd")
        dispatch(getEvents({ eventsAfterDate: firstDay, eventsBeforeDate: lastDay }))
    }

    useEffect(() => {
        let dates = [];
        Array.isArray(events) && events.forEach((item) => {
            item.startDate && dates.push(item.startDate);
            item.endDate && dates.push(item.endDate);
            item.driveDate && dates.push(item.driveDate);
        })
        setDriveDates(dates);

    }, [events])
    return (
        <div className="col-span-1 row-span-2 border shadow-lg rounded-2xl">
            <div className="flex items-center w-full h-full justify-center">
                <Calendar
                    mode="multiple"
                    dates={driveDates}
                    loading={loading}
                    handleMonthChange={handleMonthChange}
                    className="w-full"
                />
            </div>
        </div>
    )
}
export default EventsCalendar