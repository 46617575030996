import { ReactComponent as homeIcon } from '../assets/icons/home/homeIcon.svg'
import { ReactComponent as analyticsIcon } from '../assets/icons/analytics/analyticsIcon.svg'
import { ReactComponent as jobsIcon } from '../assets/icons/jobs/jobsIcon.svg'
import { ReactComponent as networksIcon } from '../assets/icons/networks/networksIcons.svg'
import { ReactComponent as driveIcon } from '../assets/icons/drives/driveIcon.svg'
import { ReactComponent as templateIcon } from '../assets/icons/templates/templateIcon.svg'
import { ReactComponent as deleteIcon } from '../assets/icons/deleteIcon.svg'
import { ReactComponent as editIcon } from '../assets/icons/editIcon.svg'
import { ReactComponent as calendarIcon } from '../assets/icons/calendarIcon.svg'
import { ReactComponent as backArrowIcon } from '../assets/icons/arrowBack.svg'
import { ReactComponent as uploadIcon } from '../assets/icons/upload.svg'

export const Icons = {
    HOME: homeIcon,
    ANALYTIC: analyticsIcon,
    JOBS: jobsIcon,
    NETWORKS: networksIcon,
    DRIVES: driveIcon,
    TEMPALTES: templateIcon,
    EDIT: editIcon,
    DELETE: deleteIcon,
    CALENDAR: calendarIcon,
    BACKARROW: backArrowIcon,
    UPLOAD: uploadIcon,
}