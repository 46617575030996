import DriveRoundDetails from "../../drives/driveTemplates/template-details/DriveRoundDetails"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../ui/tabs"

import "./tabs.css"
const CommonTabs = () => {

    return (
        <div className="w-full mt-6">
            <Tabs defaultValue="rounds">
                <TabsList className="w-full py-4 px-0">
                    <TabsTrigger className="w-1/3 py-2 text-lg border-2 bg-grayColor text-black" value="rounds">Rounds</TabsTrigger>
                    <TabsTrigger className="w-1/3 py-2 text-lg border-2 bg-grayColor text-black" value="jobs">Used in Jobs</TabsTrigger>
                    <TabsTrigger className="w-1/3 py-2 text-lg border-2 bg-grayColor text-black" value="drives">Used in Drives</TabsTrigger>
                </TabsList>
                <TabsContent className="mt-0" value="rounds">
                    <DriveRoundDetails />
                </TabsContent>
                <TabsContent className="mt-0" value="jobs">Jobs table goes here.</TabsContent>
                <TabsContent className="mt-0" value="drives">Drives table goes here.</TabsContent>
            </Tabs>

        </div>
    )
}
export default CommonTabs