import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";
export const createTemplate = createAsyncThunk('createTemplate', async (body, { rejectWithValue }) => {
    try {
        await apiService.post(`/round-templates`, body)
    } catch (error) {
        console.log(error)
        return rejectWithValue(error.response.data.message || error.message)
    }
})

const createDriveTemplateSlice = createSlice({
    name: "Create Template",
    initialState: {
        loading: false,
        success: false,
        successMsg: null,
        formError : null,
    },
    reducers: {
        resetSuccessMsg: (state, action) => {
            state.successMsg = null
            state.success = false
        },
        resetFormError: (state) => { // Add resetFormError action
            state.formError = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createTemplate.pending, (state, action) => {
            state.loading = true;
            state.formError = null;
        })
        builder.addCase(createTemplate.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
            state.successMsg = "Template created Succssfully"
        })
        builder.addCase(createTemplate.rejected, (state, action) => {
            state.loading = false;
            state.formError = action.payload;
        })
    }
})
export const { resetSuccessMsg, resetError ,resetFormError } = createDriveTemplateSlice.actions

export default createDriveTemplateSlice.reducer