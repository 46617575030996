import CommonSelect from "../../reusable/dropdowns/CommonSelect"
import { DriveStatusSelect } from "../drive-details/DriveStatusInfo"

export const DriveStatusColumns = [
    {
        accessorKey: "title",
        header: "Title",
    },
    {
        id: "round-statuses",
        header: "Round Statuses",
        cell: ({ row }) => {
            const roundStatuses = row.original.roundStatuses
            const items = roundStatuses?.map((status) => {
                return {
                    label: status.title,
                    value: status.id
                }
            })
            return <DriveStatusSelect roundId={row.original.id} items={items} />
        }
    },
]