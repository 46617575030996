import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { auth } from "../../../config/firebaseConfig";
import { AuthErrorCodes, signInWithEmailAndPassword } from "firebase/auth";
import { AxiosError } from "axios";

export const userLogin = createAsyncThunk('userLogin', async (userDetails, { rejectWithValue }) => {
    try {
        await signInWithEmailAndPassword(auth, userDetails.email, userDetails.password)
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const authSlice = createSlice({
    name: "auth",
    initialState: {
        loading: false,
        error: null,
        loginSuccess: false,
        user: auth.currentUser
    },
    reducers: {
        logout: (state) => {
            state.user = null
        },
        firebaseSetUser: (state, action) => {
            state.user = action.payload
            if (state.user != null) {
                state.loginSuccess = true;
            } else {
                state.loginSuccess = false;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(userLogin.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(userLogin.fulfilled, (state, action) => {
            state.loading = false;
            state.loginSuccess = true
        })
        builder.addCase(userLogin.rejected, (state, action) => {
            state.loading = false;
            switch (action.payload.code) {
                case AuthErrorCodes.USER_DELETED:
                    state.error = "User does not exist"
                    break;
                case AuthErrorCodes.INVALID_EMAIL:
                    state.error = "User does not exist"
                    break;
                case AuthErrorCodes.INVALID_PASSWORD:
                    state.error = "Wrong password"
                    break;
                case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
                    state.error = "Too many failed login attempts. Login is temporarily blocked"
                    break;
                default:
                    state.error = action.payload.message;
                    if (action.payload instanceof AxiosError) {
                        state.error = action.payload.response.data.message;
                    }
            }
        })
    }
})

export const { firebaseSetUser, logout } = authSlice.actions
export default authSlice.reducer