import { useDispatch } from "react-redux"
import CommonAlertDialog from "../../reusable/modals/CommonAlertDialog"
import { Button } from "../../ui/button"
import { withdrawNetwork } from "../../../redux/features/networks/withdrawNetworkSlice"
import { useState } from "react"

const WithdrawInviteModal = ({ connectionId, collegeName }) => {

    const dispatch = useDispatch()
    const [openAlertDialog, setOpenAlertDialog] = useState(false)

    const triggerButton = <Button onClick={() => setOpenAlertDialog(true)} className="bg-archiveColor" size="sm">Withdraw Invitation</Button>
    const actionButton = <Button onClick={() => dispatch(withdrawNetwork(connectionId))} variant="primary">Continue</Button>

    return (
        <div onClick={(e) => e.stopPropagation()}>
            {triggerButton}

            <CommonAlertDialog open={openAlertDialog} setOpen={setOpenAlertDialog} title={`Are you sure you want to withdraw invitation with ${collegeName}`} actionButton={actionButton} description={"This action can't be undone are you sure you want to withdraw your connection"} />
        </div>
    )
}
export default WithdrawInviteModal