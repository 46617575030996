import { useDispatch, useSelector } from "react-redux"
import { DataTable } from "../../table/DataTable"
import { LinkedJobsColumns } from "../columns/LinkedJobsColumns"
import CommonOutletSearch from "../../reusable/layout/CommonOutletSearch"
import ListLoading from "../../reusable/loaders/ListLoading"
import { getJobs } from "../../../redux/features/jobs/getJobSlice"
import { useRef, useState } from "react"

const DriveLinkedJobs = () => {

  const [title, setTitle] = useState('')
  const timeoutRef = useRef(null)
  const { driveDetails, loading } = useSelector(state => state.driveDetailsReducer)

  const { locations } = useSelector(state => state.jobLocationReducer)

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0
  })
  const dispatch = useDispatch()
  const handleJobsSearchChange = (e) => {
    const debounceDelay = 500;
    setTitle(e.target.value)
    // Clear previous timeout if exists
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    // Set new timeout
    timeoutRef.current = setTimeout(() => {
      handleJobsSearchChange.timeoutId = setTimeout(() => {
        dispatch(getJobs({ title: e.target.value }))
      }, debounceDelay);
    }, debounceDelay);
  }

  const handleLocationFilterChange = (value) => {
    dispatch(getJobs({ title: title, location: value }))
  }
  return (
    <div className="border shadow-xl px-8 py-4 rounded-3xl rounded-tl-none">
      <CommonOutletSearch
        placeholder={"Search for jobs"}
        selectPlaceholder={"Filter by Location"}
        handleSearchChange={handleJobsSearchChange}
        handleFilterChange={handleLocationFilterChange}
        filterItems={locations} />
      {
        loading ? <ListLoading /> :
          !loading && driveDetails?.jobs && <DataTable hasClick={false} pagination={pagination} setPagination={setPagination} columns={LinkedJobsColumns} data={driveDetails?.jobs} />
      }
    </div>
  )
}
export default DriveLinkedJobs