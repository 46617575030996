import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const endDrive = createAsyncThunk('endDrive', async (driveId, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/drives/${driveId}/end`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const endDriveSlice = createSlice({
    name: "End Drive",
    initialState: {
        loading: false,
        error: null,
        success: null
    },
    reducers: {
        resetEndDriveSuccess: (state) => {
            state.success = null;
        },
        resetEndDriveError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(endDrive.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(endDrive.fulfilled, (state, action) => {
            state.loading = false;
            state.success = "Drive ended Successfully"
        })
        builder.addCase(endDrive.rejected, (state, action) => {
            state.loading = false;
        })

    }
})

export const { resetEndDriveSuccess, resetEndDriveError } = endDriveSlice.actions

export default endDriveSlice.reducer