import { Skeleton } from "../../ui/skeleton"

const AnalyticsStatsLoader = () => {
  return (
    <div className="grid grid-cols-6 gap-6">
        {
            [1,2,3,4,5,6].map((_,i) => (
                <Skeleton className="w-full h-[120px] rounded-md" key={i} />
            ))
        }
    </div>
  )
}
export default AnalyticsStatsLoader