import { Skeleton } from "../../ui/skeleton"

const GraphLoader = () => {
  return (
    <div className="flex items-center gap-x-6">
        {
            [1,2,3,4,5,6].map((_,i) => (
                <Skeleton key={i} className="h-[320px] w-20" />
            ))
        }
    </div>
  )
}
export default GraphLoader