import { Skeleton } from "../../ui/skeleton"

const NetworkDetailsLoader = () => {
    return (
        <div className="flex flex-col gap-y-6">
            <div className="shadow-lg rounded-lg px-6 py-6 border">
                <Skeleton className="h-8" />
                <Skeleton className="h-6 mt-4" />
            </div>
            <div className="grid grid-cols-8 gap-x-8">
                <div className="col-span-3 border shadow-lg p-6 h-[50vh] rounded-md">
                    <Skeleton className="h-8" />
                    <div className="mt-8 flex flex-col gap-y-4">
                        {
                            [1, 2, 3, 4, 5, 6].map((_, i) => (
                                <Skeleton key={i} className="h-6" />
                            ))
                        }
                    </div>
                </div>
                <div className="col-span-5 border shadow-lg p-6 h-[50vh] rounded-md">
                    <div className="flex flex-col gap-y-6">
                        {
                            [1, 2, 3].map((_, i) => (
                                <div key={i} className="flex flex-col gap-y-3">
                                    <Skeleton className="h-8" />
                                    <Skeleton className="h-6" />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NetworkDetailsLoader