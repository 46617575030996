import { DataTable } from "../../../table/DataTable"
import { useSelector } from "react-redux"
import { DriveTemplateRoundColumns } from "../../columns/DriveTemplateRoundColumns"
import ListLoading from "../../../reusable/loaders/ListLoading"

const DriveRoundDetails = () => {

    const { loading, templateDetails, rounds } = useSelector(state => state.driveTemplateReducer)

    return (
        <div>
            {
                loading ? <ListLoading /> :
                    <div>

                        {
                            templateDetails && <DataTable hasPagination={false} columns={DriveTemplateRoundColumns} data={rounds} />
                        }
                    </div>
            }
        </div>
    )
}
export default DriveRoundDetails