import { resetMemberUpdateError, resetMemberUpdateSuccessMsg, updateMember } from "../../redux/features/team/updateMemberSlice";
import { getMemberDetails } from "../../redux/features/team/getMemberDetailsSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import InputFormField from "../../components/reusable/forms/InputFormField";
import ToggleButton from "../../components/reusable/forms/ToggleButton";
import { Button } from "../../components/ui/button";
import { Form } from "../../components/ui/form";
import { ToastEnum } from "../../utils/enums";
import snackbar from "../../components/reusable/snackbar";

const formSchema = z.object({
    username: z.string(),
    email: z.string().email(),
    name: z.string(),
    designation: z.string(),
    phoneNumber: z.string().min(1,{
        message : "Invalid Phone Number"
    }).max(10,{
        message : "Invalid Phone Number"
    }),
});

const TeamDetails = () => {
    const { memberId } = useParams();
    const dispatch = useDispatch();
    const { memberDetails, loading } = useSelector((state) => state.getMemberDetailsReducer);
    const { user } = useSelector((state) => state.authReducer)
    const { successMsg, error } = useSelector(state => state.udpateMemberReducer)

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            username: "",
            email: "",
            name: "",
            designation: "",
            phoneNumber: "",
        },
    });

    const { reset } = form;

    useEffect(() => {
        if (successMsg) {
            snackbar(ToastEnum.SUCCESS, successMsg)
            dispatch(resetMemberUpdateSuccessMsg())
        }
        if (error) {
            snackbar(ToastEnum.ERROR, error)
            dispatch(resetMemberUpdateError())
        }
        dispatch(getMemberDetails(memberId));
    }, [memberId, successMsg, error]);

    useEffect(() => {
        if (memberDetails) {
            reset({
                username: memberDetails.username,
                email: memberDetails.email,
                name: memberDetails.name,
                designation: memberDetails.designation,
                phoneNumber: memberDetails.phoneNumber,
            });
            setModifyUsers(memberDetails.manageUsers);
            setScheduleDrive(memberDetails.canScheduleDrive);
            setModifyDrive(memberDetails.canModifyDrive);
        }
    }, [memberDetails, reset]);

    const [modifyUsers, setModifyUsers] = useState(false);
    const [scheduleDrive, setScheduleDrive] = useState(false);
    const [modifyDrive, setModifyDrive] = useState(false);

    const onSubmit = (values) => {
        const body = {
            ...values,
            manageUsers: modifyUsers,
            canScheduleDrive: scheduleDrive,
            canModifyDrive: modifyDrive,
            phoneNumber: "+91" + values.phoneNumber
        };

        dispatch(updateMember({ memberId: memberId, body: body }));
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="flex flex-col gap-y-2 mt-8">
            <Form {...form}>
                <form onError={(error) => console.log(error)} onSubmit={form.handleSubmit(onSubmit)} className="space-y-2 mt-2">
                    <h2 className="font-semibold">System Details</h2>
                    <hr />
                    <div className="flex items-center justify-between gap-x-10">
                        <InputFormField disabled={true} className={"w-1/2"} name="username" control={form.control} placeholder={"Enter your username"} label={"Username"} type={"text"} />
                        <InputFormField disabled={true} className={"w-1/2"} name="email" control={form.control} placeholder={"Enter your Email"} label={"Email"} type={"email"} />
                    </div>
                    <h2 className="font-semibold">Personal Details</h2>
                    <hr />
                    <div className="flex items-center justify-between gap-x-10">
                        <InputFormField disabled={user?.manageUsers ? false : true} className={"w-1/2"} name="name" control={form.control} placeholder={"Sanjay Sharma"} label={"Name"} type={"text"} />
                        <InputFormField className={"w-1/2"} name="designation" control={form.control} placeholder={"Sr HR Executive"} label={"Designation"} type={"text"} />
                    </div>
                    <div className="w-1/2 flex flex-col gap-y-4">
                        <InputFormField disabled={user?.manageUsers ? false : true} name="phoneNumber" control={form.control} placeholder={"Enter Phone Number"} label={"Phone Number"} type={"text"} />
                    </div>
                    <h2 className="font-semibold">Permissions</h2>
                    <hr />
                    <h2 className="font-medium text-secondaryColor">User Management</h2>
                    <hr />
                    <ToggleButton handleToggleChange={(e) => setModifyUsers(e.target.checked)} label={"Modify Users"} isChecked={modifyUsers} />
                    <h2 className="font-medium text-secondaryColor">Drive Management</h2>
                    <hr />
                    <div className="flex items-center gap-x-10">
                        <ToggleButton handleToggleChange={(e) => setScheduleDrive(e.target.checked)} label={"Can Schedule Drive"} isChecked={scheduleDrive} />
                        <ToggleButton handleToggleChange={(e) => setModifyDrive(e.target.checked)} label={"Can Modify Drive"} isChecked={modifyDrive} />
                    </div>
                    <div className="flex justify-end w-full">
                        <Button disabled={user?.manageUsers ? false : true} type="submit" className="rounded-3xl text-lg px-8 py-4 shadow-md" variant="primary">
                            Save
                        </Button>
                    </div>
                </form>
            </Form>
        </div>
    );
};

export default TeamDetails;
