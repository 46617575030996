import { cn } from "../../../lib/utils";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../ui/select";

const CommonSelect = ({ items, value, defaultValue, hideNone = false, selectPlaceholder, handleSelectChange, className }) => {

    return (
        <div>
            <Select defaultValue={defaultValue} onValueChange={handleSelectChange}>
                <SelectTrigger className={cn("w-[180px]", className)}>
                    <SelectValue value={value} placeholder={selectPlaceholder} />
                </SelectTrigger>
                <SelectContent>
                    {
                        !hideNone && <SelectItem value={null}>None</SelectItem>
                    }
                    {
                        items.map((item, i) => {
                            const itemValue = typeof item === "object" ? item.value ?? item : item;
                            const itemTitle = typeof item === "object" ? item.label ?? item : item;
                            return <SelectItem key={i} value={itemValue}>{itemTitle}</SelectItem>
                        })
                    }
                </SelectContent>
            </Select>

        </div>
    )
}
export default CommonSelect