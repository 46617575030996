import CommonModal from "../../reusable/modals/CommonModal"
import { Button } from "../../ui/button"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import {
    Form,
} from "../../ui/form"
import InputFormField from "../../reusable/forms/InputFormField"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import TextAreaFormField from "../../reusable/forms/TextAreaFormField"
import DatePickerFormField from "../../reusable/forms/DatePickerFormField"
import SelectFormField from "../../reusable/forms/SelectFormField"
import { createJob } from "../../../redux/features/jobs/createJobsSlice"
import ButtonLoading from "../../reusable/loaders/ButtonLoading"
import { format } from "date-fns"

const formSchema = z.object({
    title: z.string().min(1, {
        message: "Job Title is required"
    }),
    jobType: z.string().min(1, {
        message: "Job Type is required"
    }),
    location: z.string().min(1, {
        message: "location is required"
    }),
    openings: z.string().min(1, {
        message: "Number of openings is required"
    }),
    ctcMin: z.string(),
    ctcMax: z.string(),
    applicationDeadline: z.string().min(1,{
        message: "Application deadline is required"
    }),
    roundTemplateId: z.string().min(1, {
        message: "Round Template is required"
    }),
    description: z.string().min(1, {
        message: "Description is required"
    }),
    eligibilityCriteria: z.string().min(1, {
        message: "Eligibility Criteria is required"
    }),
})
const CreateJobModal = ({ templateDropdownItems }) => {

    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()
    const { loading } = useSelector(state => state.createJobReducer)
    const defaultForm = {
        title: "",
        jobType: "",
        location: "",
        openings: "",
        ctcMin: "",
        ctcMax: "",
        applicationDeadline: "",
        roundTemplateId: "",
        description: "",
        eligibilityCriteria: "",
    }
    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: defaultForm
    })

    function onSubmit(values) {
        const formattedValues = {
            ...values,
            location: [values.location],
            applicationDeadline: format(values.applicationDeadline, "yyyy-MM-dd"),
        };
        dispatch(createJob(formattedValues))

        form.reset(defaultForm)
    }
    const triggerButton = <Button variant="primary" className="rounded-3xl">
        + Create Job
    </Button>

    const jobTypeItems = [
        {
            label: "Part Time",
            value: "Part-Time",
        },
        {
            label: "Full Time",
            value: "Full-Time",
        },
        {
            label: "Internship",
            value: "Internship",
        }
    ]
    return (
        <div>
            <CommonModal className={"max-w-2xl h-[70%] overflow-y-scroll"} triggerButton={triggerButton} title={"Create Job"}>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                        <div className="flex items-center gap-x-4">
                            <InputFormField className={"w-1/2"} name="title" control={form.control} placeholder={"Enter Job Title"} label={"Job Title"} type={"text"} />
                            <SelectFormField items={jobTypeItems} name={"jobType"} control={form.control} placeholder={"Select Type of Job"} label={"Job Type"} className={"w-1/2"} mandatory={true}/>
                        </div>
                        <div className="flex items-center gap-x-4">
                            <InputFormField className={"w-1/2"} name={"location"} control={form.control} placeholder={"Enter Job location"} label={"Location"} type={"text"} />
                            <SelectFormField items={templateDropdownItems} name={"roundTemplateId"} control={form.control} placeholder={"Select Template"} label={"Template"} className={"w-1/2"} mandatory={true} />
                        </div>
                        <div className="flex items-center gap-x-4">
                            <InputFormField className="w-1/2" name={"ctcMin"} control={form.control} label={"Min Ctc"} placeholder={"Enter Min ctc"} mandatory={false}/>
                            <InputFormField className="w-1/2" name={"ctcMax"} control={form.control} label={"Max Ctc"} placeholder={"Enter Max ctc"} mandatory={false}/>
                        </div>
                        <div className="flex items-center gap-x-4">
                            {/* <DatePickerFormField className={"w-1/2"} name={"applicationDeadline"} control={form.control} label={"Application Deadline"} placeholder={"Enter Application Deadline"} /> */}
                            <InputFormField className="w-1/2" name={"applicationDeadline"} control={form.control} label={"Application Deadline"} placeholder={"Enter application deadline"} type={"date"} />
                            <InputFormField className="w-1/2" name={"openings"} control={form.control} label={"Openings"} placeholder={"Enter Number of openings"} />
                        </div>
                        <div className="flex items-center gap-x-4">
                            <TextAreaFormField className="w-1/2" name={"description"} control={form.control} label={"Description"} placeholder={"Enter description of the job"} />
                            <TextAreaFormField className="w-1/2" name={"eligibilityCriteria"} control={form.control} label={"Eligibility Criteria"} placeholder={"Enter eligibility criteria of the job"} />
                        </div>
                        <div className="flex justify-end w-full">
                            {
                                loading ? <ButtonLoading /> :
                                    <Button type="submit" variant="primary" className="rounded-3xl shadow-md">Save</Button>
                            }
                        </div>
                    </form>
                </Form>
            </CommonModal>
        </div>
    )
}
export default CreateJobModal