import Title from "./Title"

const Common = ({ title, link, isTitle = true, hideButton = false, children }) => {
    return (
        <div className="border shadow-lg rounded-2xl px-4 py-6 h-full overflow-y-auto">
            {
                isTitle &&
                <Title hideButton={hideButton} title={title} link={link} />
            }
            <div className="mt-4 h-[85%]">
                {children}
            </div>
        </div>
    )
}
export default Common