import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../../ui/dropdown-menu"

const CommonDropdown = ({ children, open, setOpen, items }) => {
    return (
        <div>
            <DropdownMenu modal={false} open={open} onOpenChange={setOpen}>
                <DropdownMenuTrigger>
                    {children}
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    {
                        items.map((item, i) => (
                            <DropdownMenuItem onClick={(e) => {
                                item.func()
                            }} key={i}>{item.name}</DropdownMenuItem>
                        ))
                    }
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    )
}
export default CommonDropdown