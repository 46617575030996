import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const updateDrive = createAsyncThunk('updateDrive', async (obj, { rejectWithValue }) => {
    try {
        await apiService.patch(`/drives/${obj.driveId}`, {
            statusId: obj.statusId
        })
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const updateDriveSlice = createSlice({
    name: "update Drive",
    initialState: {
        loading: false,
        error: null,
        success: false,
        successMessage: null
    },
    reducers: {
        resetupdateDriveSuccess: (state) => {
            state.successMessage = null;
            state.success = false
        },
        resetupdateDriveError: (state) => {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateDrive.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(updateDrive.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
            state.successMessage = "Drive Updated Successfully"
        })
        builder.addCase(updateDrive.rejected, (state, action) => {
            state.loading = false;
        })
    }
})
export const { resetupdateDriveSuccess, resetupdateDriveError } = updateDriveSlice.actions
export default updateDriveSlice.reducer