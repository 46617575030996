import { cn } from "../../../lib/utils"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/form"
import { Textarea } from "../../ui/textarea"
const TextAreaFormField = ({ name, control, placeholder, inputClassName, label, className }) => {
    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem className={className}>
                    <FormLabel className="mt-2 w-[50%]">{label}<span className="text-red-500">*</span></FormLabel>
                    <FormControl>
                        <Textarea
                            placeholder={placeholder}
                            className={cn(`resize-none`, inputClassName)}
                            {...field}
                        />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />)
}
export default TextAreaFormField