import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const acceptDriveInvite = createAsyncThunk('acceptDriveInvite', async (obj, { rejectWithValue }) => {

    try {
        await apiService.post(`/drives/${obj.driveId}/accept`, obj.body)
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

const acceptDriveInviteSlice = createSlice({
    name: "Accept Drive Invite",
    initialState: {
        loading: false,
        error: null,
        success: false,
        successMessage: null
    },
    reducers: {
        resetAcceptDriveInviteSuccess: (state) => {
            state.successMessage = null;
            state.success = false
        },
        resetAcceptDriveInviteError: (state) => {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(acceptDriveInvite.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(acceptDriveInvite.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
            state.successMessage = "Drive Created Successfully"
        })
        builder.addCase(acceptDriveInvite.rejected, (state, action) => {
            state.loading = false;
        })
    }
})
export const { resetAcceptDriveInviteSuccess, resetAcceptDriveInviteError } = acceptDriveInviteSlice.actions
export default acceptDriveInviteSlice.reducer