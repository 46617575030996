import { Skeleton } from "../../ui/skeleton"

const ApplicationDetailsLoading = () => {
  return (
    <div className="flex flex-col gap-y-8">
      <div className="grid grid-cols-3 gap-x-6">
        <div className="col-span-2 shadow-lg rounded-lg px-6 py-6 border">
          <div className="flex items-center gap-x-4">
            <Skeleton className="h-16 w-16 rounded-full" />
            <div className="space-y-2">
              <Skeleton className="h-8 w-[250px]" />
              <Skeleton className="h-6 mt-4 w-full" />
            </div>
          </div>
        </div>

        <div className="shadow-lg rounded-lg flex flex-col gap-y-4 px-6 py-6 border">
          <div className="flex flex-col gap-y-2">
            <Skeleton className="h-6" />
            <Skeleton className="h-6" />
          </div>
        </div>
      </div>

      <div className="border shadow-xl px-8 py-8 rounded-3xl">
        <div className="grid grid-cols-8 gap-x-8">
          <Skeleton className="col-span-5 h-[50vh]" />
          <Skeleton className="col-span-3 h-[50vh]" />
        </div>
      </div>
    </div>)
}
export default ApplicationDetailsLoading