import { useSelector } from "react-redux"
import { cn } from "../../lib/utils"
import { Icon } from "../ui/icon"
import { useState } from "react"

const SidebarItem = ({ item, pathname, navigate }) => {
  const { open } = useSelector(state => state.sidebarReducer)

  const isActive = item.link === "/" ? pathname === "/" : pathname.startsWith(item.link)

  return (
    <div onClick={() => navigate(item.link)} className={cn("flex items-center gap-x-4 cursor-pointer group hover:bg-primaryButtonColor ", isActive && open ? "bg-primaryButtonColor" : "bg-transparent", open && "px-4 rounded-md py-3")}>
      <Icon className={cn(" w-6 h-6 group-hover:text-lightColor", isActive && open ? "text-lightColor" : isActive && !open ? "text-primaryButtonColor" : "text-secondaryColor")} icon={item.icon} />
      {open && <p className={cn("font-semibold group-hover:text-lightColor", isActive ? "text-lightColor" : "text-secondaryColor")}>{item.label}</p>}
    </div>
  )
}
export default SidebarItem