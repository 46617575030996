import { Link } from "react-router-dom"
import { Button } from "../../ui/button"

export const MyTeamColumns = [
    {
        accessorKey: "name",
        header: "Name"
    },
    {
        accessorKey: "email",
        header: "Email"
    },
    {
        accessorKey: "designation",
        header: "Designation"
    },
    {
        id: "actions",
        cell: ({ row }) => {
            const memberId = row.original.id
            return <Link to={`/team-details/${memberId}`}>
                <Button size="sm" variant="primary">
                    View Details
                </Button>
            </Link>
        }
    },
]