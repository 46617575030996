import AnalyticsHeader from "../../components/analytics/AnalyticsHeader"
import { getAnalyticsStats } from "../../redux/features/analytics/getAnalyticsStatsSlice"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { getHiringGraph } from "../../redux/features/analytics/getHiringGraphSlice"
import { getCtcStats } from "../../redux/features/analytics/getCtcStatsSlice"
import HiringGraph from "../../components/analytics/HiringGraph"
import CtcStatsGraph from "../../components/analytics/CtcStatsGraph"
import { getJobWiseApplicants } from "../../redux/features/analytics/getJobWiseApplicantsSlice"
import { getJobWiseOpenings } from "../../redux/features/analytics/getJobOpeningsGraphSlice"
import JobsOpeningsApplicantsGraph from "../../components/analytics/JobOpeningsGraph"
import JobApplicantsGraph from "../../components/analytics/JobApplicantsGraph"
import JobsFilledGraph from "../../components/analytics/JobsFilledGraph"
import GenderApplicantsGraph from "../../components/analytics/GenderApplicantsGraph"
import NewRecurringDrivesGraph from "../../components/analytics/NewRecurringDrivesGraph"
import { getJobsFilled } from "../../redux/features/analytics/getJobsFilledSlice"
import { getGenderWiseApplicants } from "../../redux/features/analytics/getGenderWiseApplicantsSlice"
import { getNewRecurringDrive } from "../../redux/features/analytics/getNewRecurringDriveSlice"
import AnalyticsNetwork from "../../components/analytics/AnalyticsNetwork"
import { getAnalyticsNetwork } from "../../redux/features/analytics/getAnalyticsNetworkSlice"

const Analytics = () => {

  const date = new Date()
  const dispatch = useDispatch()
  const [selectedYear, setSelectedYear] = useState(date.getFullYear())
  useEffect(() => {
    dispatch(getAnalyticsStats(selectedYear))
    dispatch(getHiringGraph(selectedYear))
    dispatch(getCtcStats(selectedYear))
    dispatch(getJobWiseApplicants(selectedYear))
    dispatch(getJobWiseOpenings(selectedYear))
    dispatch(getJobsFilled(selectedYear))
    dispatch(getGenderWiseApplicants(selectedYear))
    dispatch(getNewRecurringDrive(selectedYear))
    dispatch(getAnalyticsNetwork())
  }, [selectedYear])

  const changeSelectedYear = (year) => {
    setSelectedYear(year)
  }

  return (
    <div className="flex flex-col gap-y-6">
      <AnalyticsHeader changeSelectedYear={changeSelectedYear} />
      <div className="grid grid-cols-2 gap-6">
        <HiringGraph />
        <CtcStatsGraph />
      </div>
      <div className="grid grid-cols-2 gap-6">
        <JobsOpeningsApplicantsGraph />
        <JobApplicantsGraph />
      </div>
      <div className="grid grid-cols-4 gap-6">
        <JobsFilledGraph />
        <GenderApplicantsGraph />
        <NewRecurringDrivesGraph />
        <AnalyticsNetwork />
      </div>

    </div>
  )
}
export default Analytics