import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
    name: "Sidebar",
    initialState: {
        open: false
    },
    reducers: {
        closeDrawer: (state) => {
            state.open = false
        },
        openDrawer: (state) => {
            state.open = true
        }
    }
})
export const {closeDrawer,openDrawer} = sidebarSlice.actions
export default sidebarSlice.reducer