import { Link } from "react-router-dom"
import { Button } from "../../ui/button"

export const LinkedJobsColumns = [
    {
        accessorKey: "title",
        header: "Title"
    },
    {
        accessorKey: "openings",
        header: "Openings"
    },
    {
        accessorKey: "filled",
        header: "Filled"
    },
    {
        accessorKey: "location",
        header: "Location",
        cell: ({ row }) => {
            const location = row.getValue("location")
            return location.length > 1 ? <p>{location[0]} + {location.length - 1} more</p> : <p>{location[0]}</p>
        }
    },
    {
        accessorKey: "ctcMax",
        header: "CTC"
    },
    {
        accessorKey: "totalApplications",
        header: "No of Applications",
    },
    {
        id: "actions",
        cell: ({ row }) => {
            return <Link to={`/job-details/${row.original.id}`}>
                <Button variant="primary" size="sm">View Details</Button>
            </Link>
        }
    },
]