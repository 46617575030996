import { LinkedJobsColumns } from "../../components/drives/columns/LinkedJobsColumns"
import CommonOutlet from "../../components/reusable/layout/CommonOutlet"
import { getJobLocation } from "../../redux/features/jobs/getJobLocationsSlice"
import { getJobs } from "../../redux/features/jobs/getJobSlice"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import CreateJobModal from "../../components/jobs/modals/CreateJobModal"
import { DataTable } from "../../components/table/DataTable"
import ListLoading from "../../components/reusable/loaders/ListLoading"
import { getDriveTemplates } from "../../redux/features/drive-template/getDriveTemplateDetailsSlice"
import snackbar from "../../components/reusable/snackbar"
import { ToastEnum } from "../../utils/enums"
import { resetCreateJobSuccess } from "../../redux/features/jobs/createJobsSlice"

const Jobs = () => {
  const [title, setTitle] = useState("")
  const dispatch = useDispatch()
  const timeoutRef = useRef(null)
  const { locations } = useSelector(state => state.jobLocationReducer)
  const { jobs, loading } = useSelector(state => state.jobsReducer)
  const { driveTemplates } = useSelector(state => state.driveTemplateReducer)
  const { successMessage, error } = useSelector(state => state.createJobReducer)

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0
  })
  useEffect(() => {
    if (successMessage) {
      snackbar(ToastEnum.SUCCESS, successMessage)
      dispatch(resetCreateJobSuccess())
    }
    if (error) {
      snackbar(ToastEnum.ERROR)
    }
    dispatch(getJobs())
    dispatch(getJobLocation())
    dispatch(getDriveTemplates())
  }, [successMessage, error])

  const templateItems = driveTemplates?.results?.map((template) => {
    return {
      value: template.id,
      label: template.title
    }
  })
  const navigate = useNavigate()
  const handleRowClick = (row) => {
    const path = `/job-details/${row.id}`;
    navigate(path);
  };

  const handleSearchChange = (e) => {
    const debounceDelay = 500;
    setTitle(e.target.value)
    // Clear previous timeout if exists
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    // Set new timeout
    timeoutRef.current = setTimeout(() => {
      handleSearchChange.timeoutId = setTimeout(() => {
        dispatch(getJobs({ title: e.target.value }))
      }, debounceDelay);
    }, debounceDelay);
  }

  const locationFilterChange = (value) => {
    dispatch(getJobs({ title: title, location: value }))
  }
  return (
    <div>
      <CommonOutlet
        hasSearch={true}
        placeholder={"Search for Jobs"}
        title={"Jobs Databases"}
        selectPlaceholder={"Filter by Location"}
        handleFilterChange={locationFilterChange}
        handleSearchChange={handleSearchChange}
        filterItems={locations}
        createButton={
          <CreateJobModal templateDropdownItems={templateItems} />
        }>
        {
          loading ? <ListLoading /> : jobs && <DataTable hasClick={false} pagination={pagination} setPagination={setPagination} onClickRoute={handleRowClick} columns={LinkedJobsColumns} data={jobs} />
        }
      </CommonOutlet>
    </div>)
}
export default Jobs