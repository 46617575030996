import Notifications from "../../components/home/Notifications"
import JobStatus from "../../components/home/JobStatus"
import RecentlyConnectedCollege from "../../components/home/RecentlyConnectedCollege"
import EventsCalendar from "../../components/home/EventsCalendar"
import CampusRecruitment from "../../components/home/CampusRecruitment"
import ReportsBarChart from "../../components/home/ReportsBarChart"
import UpcomingEvents from "../../components/home/UpcomingEvents"

const Home = () => {

  return (
    <div className="h-full">
      <div className="grid grid-cols-3 grid-rows-6 max-h-[1080px] gap-x-6 gap-y-6">
        <JobStatus />
        <Notifications />
        <RecentlyConnectedCollege />
        <CampusRecruitment />
        <EventsCalendar />
        <ReportsBarChart />
        <UpcomingEvents />
      </div>
    </div>
  )
}
export default Home