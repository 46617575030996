export const JobStatusColumns = [
    {
        accessorKey: "title",
        header: "Job name",
    },
    {
        accessorKey: "location",
        header: "Job location",
        cell: ({ row }) => {
            const jobs = row.original
            return jobs.location.length > 1 ? <p>{jobs.location} + {jobs.location - 1} more</p> : <p>{jobs.location}</p>
        }

    },
    {
        accessorKey: "openings",
        header: "Openings",
    },
    {
        accessorKey: "filled",
        header: "Filled",
    },
]