import { Skeleton } from "../../ui/skeleton"

const ListLoading = () => {
    return (
        <div className="flex flex-col gap-y-4 mt-4">
            {
                [1, 2, 3, 4, 5, 6, 7].map((_, i) => (
                    <Skeleton key={i} className="h-[40px] rounded-md" />
                ))
            }
        </div>
    )
}
export default ListLoading