import { Icons } from "../utils/icons";

export const sidebarItems = [
    {
        label: "Home",
        link: "/",
        icon : Icons.HOME,
    },
    {
        label: "Analytics",
        link: "/analytics",
        icon : Icons.ANALYTIC,
    },
    {
        label: "Jobs",
        link: "/jobs",
        icon : Icons.JOBS,
    },
    {
        label: "Networks",
        link: "/networks",
        icon : Icons.NETWORKS,
    },
    {
        label: "Drives",
        link: "/drives",
        icon : Icons.DRIVES,
    },
    {
        label: "Templates",
        link: "/templates",
        icon : Icons.TEMPALTES,
    },
]