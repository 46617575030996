import { Loader } from "lucide-react"
import { Button } from "../../ui/button"

const ButtonLoading = () => {
  return (
    <Button variant="primary" disabled={true} className="w-full py-5 mt-4">
        <Loader className="animate-spin" size={16}/>
    </Button>
  )
}
export default ButtonLoading