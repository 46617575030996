import JobDetailsApplicants from "../../jobs/job-details/JobDetailsApplicants"
import JobDetailsInfo from "../../jobs/job-details/JobDetailsInfo"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../ui/tabs"

import "./tabs.css"
const JobDetailsTabs = ({ pagination, setPagination }) => {
  return (
    <div className="mt-6 drive-details">
      <Tabs defaultValue="job-details">
        <TabsList className="py-4 px-0">
          <TabsTrigger className="py-2 px-6 text-base  bg-accentColor text-lightColor rounded-none rounded-tl-xl" value="job-details">Job Details</TabsTrigger>
          <TabsTrigger className="py-2 px-6 text-base  bg-accentColor text-lightColor rounded-none rounded-tr-xl" value="applicants">Applicants</TabsTrigger>

        </TabsList>
        <TabsContent className="mt-0" value="job-details">
          <JobDetailsInfo  />
        </TabsContent>
        <TabsContent className="mt-0" value="applicants">
          <JobDetailsApplicants pagination={pagination} setPagination={setPagination} />
        </TabsContent>
      </Tabs>

    </div>)
}
export default JobDetailsTabs