import { Button } from "../ui/button"

const AnalyticsHeaderCard = ({ title, count }) => {
    const formatLabel = (label) => {
        return label
            .replace(/([A-Z])/g, ' $1')
            .trim()
            .replace(/^./, str => str.toUpperCase());
    };

    return (
        <div className="shadow-md rounded-md p-4 flex justify-between flex-col gap-y-2 h-full">
            <h2 className="font-medium">{formatLabel(title)}</h2>
            <span className="text-4xl font-bold text-primaryButtonColor">
                {count}
            </span>
            {/* <Button variant="primary" size="sm" className="w-fit">
                Know more
            </Button> */}
        </div>
    )
}
export default AnalyticsHeaderCard