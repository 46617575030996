import { ToastEnum } from "../../utils/enums";
import { toast } from "sonner";

const snackbar = (type, message) => {
    switch (type) {
        case ToastEnum.SUCCESS:
            return toast.success(message);
        case ToastEnum.ERROR:
            return toast.error(message);
        case ToastEnum.WARNING:
            return toast.warning(message);

        default:
            return <div></div>
    }
}
export default snackbar