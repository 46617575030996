import { Button } from "../../ui/button"
import CommonModal from "../../reusable/modals/CommonModal"

import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import {
    Form,
} from "../../ui/form"
import InputFormField from "../../reusable/forms/InputFormField"
import { useState } from "react"
import { createTemplateRound } from "../../../redux/features/drive-template/createTemplateRoundSlice"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Loader } from "lucide-react"

const formSchema = z.object({
    title: z.string().min(1, {
        message: "Title is required"
    }),
})
const CreateRoundModal = () => {

    const { loading } = useSelector(state => state.createTemplateRoundReducer)
    const { id } = useParams()
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()
    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            title: "",
        },
    })

    function onSubmit(values) {
        dispatch(createTemplateRound({ roundTemplateId: id, body: { title: values.title } }))
    }

    const triggerButton = <Button variant="primary" className="rounded-3xl">
        + Create Round
    </Button>

    return (
        <div>
            <CommonModal triggerButton={triggerButton} title={"Create Round"}>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                        <InputFormField name="title" control={form.control} placeholder={"Enter Round title"} label={"Round title*"} type={"text"} />
                        <div className="flex justify-end w-full">
                            {
                                loading ?

                                    <Button type="submit" variant="primary" className="rounded-3xl shadow-md">
                                        <Loader className="animate-spin text-white" size={18} />
                                    </Button> :
                                    <Button type="submit" variant="primary" className="rounded-3xl shadow-md">Save</Button>
                            }
                        </div>
                    </form>
                </Form>
            </CommonModal>
        </div>
    )
}
export default CreateRoundModal