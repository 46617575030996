import {
    Chart as ChartJs,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import CommonGraphCard from "../reusable/CommonGraphCard";
import { useSelector } from "react-redux";
import GraphLoader from "../reusable/loaders/GraphLoader";

ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
const CtcStatsGraph = () => {

    const { ctcStats, loading } = useSelector(state => state.ctcStatsReducer)

    const labels = ctcStats?.trends.slice(1).map(item => item[0]);
    const minCtcData = ctcStats?.trends.slice(1).map(item => item[1]);
    const maxCtcData = ctcStats?.trends.slice(1).map(item => item[2]);

    const data = {
        labels: labels,
        datasets: [
            {
                label: ctcStats?.trends[0][1],
                data: minCtcData,
                backgroundColor: "#FEB8B9",
            },
            {
                label: ctcStats?.trends[0][2],
                data: maxCtcData,
                backgroundColor: "#D77275",
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Ctc",
                },
            },
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Job Title",
                },
            },
        },
        responsive: true,

    };
    return (
        <div>
            <CommonGraphCard loading={loading} loader={<GraphLoader />} title={"Ctc Statistics"}>

                <Bar data={data} options={options} />

            </CommonGraphCard>
        </div>
    )
}
export default CtcStatsGraph