import { driveStatus } from "../../../../src/utils/enums"
import { formatDriveStatus } from "../../../../src/utils/formatDriveStatus"
import DriveInvitationDropdown from "../modals/DriveInvitationDropdown"

export const DriveColumns = ({ jobTitleItems, templateItems }) => [
    {
        accessorKey: "title",
        header: "Title",
    },
    {
        accessorKey: "openings",
        header: "Openings",
    },
    {
        accessorKey: "driveStatus",
        header: "Status",
        cell: ({ row }) => {
            const driveStatus = formatDriveStatus(row.original.driveStatus)
            return <p>{driveStatus}</p>
        }
    },
    {
        accessorKey: "driveType",
        header: "Type",
    },
    {
        id: "collaborators",
        header: "Collaborators",
        cell: ({ row }) => {
            const collaborators = row.original.collaborators
            return collaborators.length > 1 ? <p>{collaborators[0].name} +{collaborators.length - 1} more</p> : collaborators.length > 0 ? <p>{collaborators[0].name}</p> : <p>-</p>
        }
    },
    {
        id: "actions",
        cell: ({ row }) => {
            const driveId = row.original.id;
            const college = row.original?.colleges && row.original.colleges?.length > 0 && row.original.colleges[0]

            return row.original.driveStatus === driveStatus.REQUEST_SENT && <DriveInvitationDropdown jobTitleItems={jobTitleItems} templateItems={templateItems} branchDropdownItems={[]} drive={row.original} college={college} driveId={driveId} />
        }
    },
]