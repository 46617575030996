import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getDriveTemplates = createAsyncThunk('getDriveTemplates', async (query, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/round-templates`, query)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})
export const getDriveTemplateDetails = createAsyncThunk('getDriveTemplateDetails', async (templateId, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/round-templates/${templateId}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})





const getDriveTemplateDetailsSlice = createSlice({
    name: "Template Details",
    initialState: {
        loading: false,
        error: null,
        success: false,
        successMsg: null,
        driveTemplates: [],
        templateDetails: null,
        rounds: [],
        roundStatuses: [],
        roundApplicantStatuses: []
    },
    reducers: {
        setRoundStatus: (state, action) => {
            const round = state.rounds.find((round) => round.id === action.payload)
            state.roundStatuses = round.roundStatuses
        },
        setApplicantStatus: (state, action) => {
            const round = state.rounds.find((round) => round.id === action.payload)
            state.roundApplicantStatuses = round.applicantStatuses
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDriveTemplates.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getDriveTemplates.fulfilled, (state, action) => {
            state.loading = false;
            state.driveTemplates = action.payload
        })
        builder.addCase(getDriveTemplates.rejected, (state, action) => {
            state.loading = false;
        })

        builder.addCase(getDriveTemplateDetails.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getDriveTemplateDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.templateDetails = action.payload
            state.rounds = action.payload.rounds
        })
        builder.addCase(getDriveTemplateDetails.rejected, (state, action) => {
            state.loading = false;
        })

    }
})
export const { setRoundStatus, setApplicantStatus } = getDriveTemplateDetailsSlice.actions
export default getDriveTemplateDetailsSlice.reducer