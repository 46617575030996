import { Outlet } from "react-router-dom"
import Sidebar from "../../components/reusable/Sidebar"
import Navbar from "../../components/reusable/Navbar"
import { cn } from "../../lib/utils"
import { useSelector } from "react-redux"

const Layout = ({showSidebar=true}) => {
    const { open } = useSelector(state => state.sidebarReducer)

    return (
        <div className="flex px-8 py-8">
            <div className={showSidebar?"mr-8":""}>
                {showSidebar && <Sidebar />}
                <Navbar showProfilebadge={showSidebar} />
            </div>
            <div className={cn("w-full mt-10",open ? 'layout-collapsed' : 'layout-expand')}>
                <Outlet />
            </div>
        </div>
    )
}
export default Layout