import { useNavigate } from "react-router-dom"
import { Button } from "../ui/button"

const Title = ({ title, link, hideButton }) => {
    const navigate = useNavigate()
    return (
        <div className="flex items-center justify-between">
            <h1 className="font-bold text-primaryTextColor text-xl">{title}</h1>
            <Button onClick={() => navigate(link)} variant="primary" className={`py-0 ${hideButton && 'hidden'}`} size="sm">
                See All
            </Button>
        </div>)
}
export default Title