import { Button } from "../../ui/button"
import { connectionStatusEnum } from "../../../utils/enums"
import SendInviteModal from "../modals/SendInviteModal"
import WithdrawInviteModal from "../modals/WithdrawInviteModal"
import DeleteInviteModal from "../modals/DeleteInviteModal"
import ViewInvitationModal from "../modals/ViewInvitationModal"

export const NetworkColumns = [
    {
        id: "logo",
        header: "Logo",
        cell: ({ row }) => {
            const college = row.original.college
            return <img className="w-10 h-10 rounded-full object-cover" src={college.logoUrl} alt="" />
        }
    },
    {
        id: "name",
        header: "Name",
        cell: ({ row }) => {
            const college = row.original.college
            return <p>{college.title}</p>
        }
    },
    {
        accessorKey: "connectionStatus",
        header: "Connection Status",
    },
    {
        id: "actions",
        cell: ({ row }) => {
            const connectionStatus = row.original.connectionStatus
            const college = row.original.college
            switch (connectionStatus) {
                case connectionStatusEnum.ACCEPTED:
                    return <DeleteInviteModal connectionStatus={connectionStatusEnum.ACCEPTED} connectionId={row.original.id} collegeName={college.title} />

                case connectionStatusEnum.NOT_CONNECTED:
                    return <SendInviteModal collegeId={college.id} collegeName={college.title} />

                case connectionStatusEnum.APPROACHED_BY_COLLEGE:
                    return <ViewInvitationModal description={row.original.description} collegeId={college.id} connectionId={row.original.id} collegeName={college.title} />

                case connectionStatusEnum.APPROACHED_BY_COMPANY:
                    return <WithdrawInviteModal connectionId={row.original.id} collegeName={college.title} />

                case connectionStatusEnum.REJECTED_BY_COMPANY:
                    return <DeleteInviteModal connectionStatus={connectionStatusEnum.REJECTED_BY_COMPANY} connectionId={row.original.id} collegeName={college.title} />
            }
        }
    },
]