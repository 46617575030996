import DriveApplicantsInfo from "../../drives/drive-details/DriveApplicantsInfo"
import DriveDetailsInfo from "../../drives/drive-details/DriveDetailsInfo"
import DriveInviteDetails from "../../drives/drive-details/DriveInviteDetails"
import DriveLinkedJobs from "../../drives/drive-details/DriveLinkedJobs"
import DriveStatusInfo from "../../drives/drive-details/DriveStatusInfo"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../ui/tabs"

import "./tabs.css"
const DriveDetailsTabs = ({ pagination, setPagination }) => {
  return (
    <div className="mt-6 drive-details">
      <Tabs defaultValue="drive-details">
        <TabsList className="py-4 px-0">
          <TabsTrigger className="py-3 px-6 text-base rounded-none bg-accentColor hover:bg-primaryButtonColor text-lightColor rounded-tl-xl" value="drive-details">Drive Details</TabsTrigger>
          <TabsTrigger className="py-3 px-6 text-base rounded-none bg-accentColor hover:bg-primaryButtonColor text-lightColor" value="applicants">Applicants</TabsTrigger>
          <TabsTrigger className="py-3 px-6 text-base rounded-none bg-accentColor hover:bg-primaryButtonColor text-lightColor" value="drive-status">Drive Status</TabsTrigger>
          <TabsTrigger className="py-3 px-6 text-base rounded-none bg-accentColor hover:bg-primaryButtonColor text-lightColor" value="linked-jobs">Linked Jobs</TabsTrigger>
          <TabsTrigger className="py-3 px-6 text-base rounded-none bg-accentColor hover:bg-primaryButtonColor text-lightColor rounded-tr-xl" value="invite-details">Invite Details</TabsTrigger>
        </TabsList>
        <TabsContent className="mt-0" value="drive-details">
          <DriveDetailsInfo />
        </TabsContent>
        <TabsContent className="mt-0" value="applicants">
          <DriveApplicantsInfo pagination={pagination} setPagination={setPagination} />
        </TabsContent>
        <TabsContent className="mt-0" value="drive-status">
          <DriveStatusInfo />
        </TabsContent>
        <TabsContent className="mt-0" value="linked-jobs">
          <DriveLinkedJobs />
        </TabsContent>
        <TabsContent className="mt-0" value="invite-details">
          <DriveInviteDetails />
        </TabsContent>
      </Tabs>

    </div>)
}
export default DriveDetailsTabs