import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const updateDriveRoundStatus = createAsyncThunk('updateDriveRoundStatus', async (obj, { rejectWithValue }) => {

    try {
        await apiService.put(`/drives/${obj.driveId}/rounds`, obj.body)
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const updateDriveRoundStatusSlice = createSlice({
    name: "update Drive Round Status",
    initialState: {
        loading: false,
        error: null,
        success: false,
        successMessage: null
    },
    reducers: {
        resetupdateDriveRoundStatusSuccess: (state) => {
            state.successMessage = null;
            state.success = false
        },
        resetupdateDriveRoundStatusError: (state) => {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateDriveRoundStatus.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(updateDriveRoundStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
            state.successMessage = "Drive Round Status Updated Successfully"
        })
        builder.addCase(updateDriveRoundStatus.rejected, (state, action) => {
            state.loading = false;
        })
    }
})
export const { resetupdateDriveRoundStatusSuccess, resetupdateDriveRoundStatusError } = updateDriveRoundStatusSlice.actions
export default updateDriveRoundStatusSlice.reducer