import { useDispatch, useSelector } from "react-redux"
import Common from "../reusable/Common"
import { useEffect } from "react"
import { getNotifications } from "../../redux/features/notifications/getNotificationSlice"

const Notifications = () => {

  const dispatch = useDispatch()
  const { notifications } = useSelector(state => state.notificationReducer)
  useEffect(() => {
    dispatch(getNotifications())
  }, [])

  return (
    <div className="col-span-1 row-span-2">
      <Common hideButton={true} title={"Notifications"} link={"/notifications"}>
        <div className="flex flex-col gap-y-2">
          {
            notifications.map((notification, i) => (
              <div key={i} className="border py-2 px-2 rounded-md shadow-lg">
                <p className="font-medium text-sm">{notification?.title}</p>
              </div>
            ))
          }
        </div>
      </Common>
    </div>
  )
}
export default Notifications