
export const Icon = ({ icon: IconComponent, className = "", onClick, ...props }) => {
    return <div
        onClick={onClick}
        style={{
            ...props.style,
        }}
        className={`align-middle justify-center flex items-center ${className}`}
    >
        <IconComponent style={{ fill: props.style?.color || "black", ...props.style }} />
    </div>
};