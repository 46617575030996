import { cn } from "../../../lib/utils"
import MultipleSelector from "../../ui/multi-select"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/form"

const MultiSelectFormField = ({ control, options, className, placeholder, name, label, field }) => {
    return (
        <FormField control={control} name={name} render={({ field }) => (
            <FormItem className={className}>
                <FormLabel>{label}</FormLabel>
                <FormControl>
                    <MultipleSelector
                        {...field}
                        selectFirstItem={false}
                        options={options}
                        placeholder={placeholder}
                        emptyIndicator={
                            <p className={cn("text-center text-lg leading-10 text-gray-600 dark:text-gray-400")}>
                                no results found.
                            </p>
                        }
                    />
                </FormControl>
                <FormMessage />
            </FormItem>
        )}>
        </FormField>

    )
}
export default MultiSelectFormField