import { useSelector } from "react-redux";
import CommonGraphCard from "../reusable/CommonGraphCard";
import {
    Chart as ChartJs,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import GraphLoader from "../reusable/loaders/GraphLoader";
ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
const JobsOpeningsApplicantsGraph = () => {


    const { jobOpeningsGraph,loading } = useSelector(state => state.getJobOpeningsReducer)

    const labels = jobOpeningsGraph?.trends.slice(1).map(item => item[0]);
    const openingsData = jobOpeningsGraph?.trends.slice(1).map(item => item[1]);

    const data = {
        labels,
        datasets: [
            {
                label: "Openings",
                data: openingsData,
                backgroundColor: '#FEB8B9',
                borderColor: '#D77275',
                borderWidth: 1
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                min: 0
            }
        }
    };
    return (
        <div>
            <CommonGraphCard loading={loading} loader={<GraphLoader />} title={"Jobs Statistics"}>
                <Bar data={data} options={options} />
            </CommonGraphCard>
        </div>
    )
}
export default JobsOpeningsApplicantsGraph