import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getJobsFilled = createAsyncThunk('getJobsFilled', async (year, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/graphs/analytics/job/total-jobs-filled/${year}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getJobsFilledSlice = createSlice({
    name: "Jobs Filled",
    initialState: {
        loading: false,
        error: null,
        jobsFilledGraph: null
    },
    extraReducers: (builder) => {
        builder.addCase(getJobsFilled.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getJobsFilled.fulfilled, (state, action) => {
            state.loading = false;
            state.jobsFilledGraph = action.payload
        })
        builder.addCase(getJobsFilled.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export default getJobsFilledSlice.reducer