import { Skeleton } from "../../ui/skeleton"

const JobDetailLoading = () => {
  return (
    <div className="flex flex-col gap-y-4">
      <div className="shadow-lg rounded-lg px-6 py-6 border">
        <Skeleton className="h-8" />
        <Skeleton className="h-6 mt-4" />
      </div>
      <div className="grid grid-cols-5 w-[40%] mt-6 gap-x-2">
        {
          [1, 2].map((_, i) => (
            <Skeleton key={i} className="h-10" />
          ))
        }
      </div>
      <div className="border shadow-xl px-8 py-8 rounded-3xl rounded-tl-none">
        <div className="grid grid-cols-8 gap-x-8">
          <Skeleton className="col-span-5 h-[50vh]" />
          <Skeleton className="col-span-3 h-[50vh]" />
        </div>
      </div>
    </div>)
}
export default JobDetailLoading