import { useDispatch, useSelector } from "react-redux"
import Common from "../reusable/Common"
import { getCampusRecruitment } from "../../redux/features/drives/getCampusRecruitmentSlice"
import { useEffect } from "react"
import { CampusRecruitmentColumns } from "./columns/CampusRecruitmentColumns"
import { DataTable } from "../table/DataTable"

const CampusRecruitment = () => {

  const dispatch = useDispatch()
  const { loading, drives } = useSelector(state => state.campusRecruitmentReducer)
  useEffect(() => {
    dispatch(getCampusRecruitment({ name: '' }))
  }, [])
  return (
    <div className="col-span-1 row-span-2">
      <Common title={"Campus Recruitment"} link={"/drives"}>
        {
          loading ? <p>Loading</p> : drives && <DataTable hasPagination={false} hasClick={false} columns={CampusRecruitmentColumns} data={drives} />
        }
      </Common>
    </div>
  )
}
export default CampusRecruitment