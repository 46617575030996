import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const withdrawNetwork = createAsyncThunk('withdrawNetwork', async (connectionId, { rejectWithValue }) => {
    try {
        await apiService.delete(`/network/${connectionId}`)
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const withdrawNetworkSlice = createSlice({
    name: "Create network",
    initialState: {
        loading: false,
        error: null,
        successMsg: null,
        success: null
    },
    reducers: {
        resetWithdrawSuccessMsg: (state, action) => {
            state.successMsg = null
            state.success = false
        },
        resetWithdrawError: (state, action) => {
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(withdrawNetwork.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(withdrawNetwork.fulfilled, (state, action) => {
            state.loading = false;
            state.successMsg = "Invitation withdrawn successfully"
        })
        builder.addCase(withdrawNetwork.rejected, (state, action) => {
            state.loading = false;
        })
    }
})
export const { resetWithdrawSuccessMsg, resetWithdrawError } = withdrawNetworkSlice.actions
export default withdrawNetworkSlice.reducer