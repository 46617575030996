import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getJobDetails = createAsyncThunk('getJobDetails', async (jobId, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/jobs/${jobId}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getJobDetailslice = createSlice({
    name: "Job Details",
    initialState: {
        loading: false,
        error: null,
        jobDetails: null
    },
    extraReducers: (builder) => {
        builder.addCase(getJobDetails.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getJobDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.jobDetails = action.payload
        })
        builder.addCase(getJobDetails.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export default getJobDetailslice.reducer