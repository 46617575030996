import { useDispatch, useSelector } from "react-redux"
import ApplicantDetailsHeader from "../components/applicant-details/ApplicantDetailsHeader"
import ApplicantsInfo from "../components/applicant-details/ApplicantsInfo"
import { getApplicationDetails } from "../redux/features/applications/getApplicationDetailSlice"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import ApplicationDetailsLoading from "../components/reusable/loaders/ApplicationDetailsLoading"
import { getApplicantResume } from "../redux/features/applications/getApplicantResumeSlice"
import { resetUpdateApplicationError, resetUpdateApplicationSuccess } from "../redux/features/applications/updateApplicationSlice"
import { ToastEnum } from "../utils/enums"
import snackbar from "../components/reusable/snackbar"

const ApplicantDetails = () => {

    const { applicationId } = useParams()
    const dispatch = useDispatch()
    const { loading } = useSelector(state => state.applicationDetailReducer)
    const { successMessage, error } = useSelector(state => state.updateApplicationReducer)
    useEffect(() => {
        if (successMessage) {
            snackbar(ToastEnum.SUCCESS, successMessage)
            dispatch(resetUpdateApplicationSuccess())
        }
        if (error) {
            snackbar(ToastEnum.ERROR)
            dispatch(resetUpdateApplicationError())
        }
        dispatch(getApplicationDetails(applicationId))
        dispatch(getApplicantResume(applicationId))
    }, [successMessage, error])

    return (
        <div>
            {
                loading ? <ApplicationDetailsLoading /> :
                    <>
                        <ApplicantDetailsHeader />
                        <ApplicantsInfo />
                    </>
            }
        </div>
    )
}
export default ApplicantDetails