import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getEvents = createAsyncThunk('getEvents', async (body, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/drive-events`, body)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getEventsSlice = createSlice({
    name: "Drive Events",
    initialState: {
        loading: false,
        error: null,
        events: [],
    },
    extraReducers: (builder) => {
        builder.addCase(getEvents.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getEvents.fulfilled, (state, action) => {
            state.loading = false;
            state.events = action.payload
        })
        builder.addCase(getEvents.rejected, (state, action) => {
            state.loading = false;
        })

    }
})

export default getEventsSlice.reducer