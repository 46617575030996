import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getJobWiseApplicants = createAsyncThunk('getJobWiseApplicants', async (year, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/graphs/analytics/job/job-wise-applicants/${year}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getJobWiseApplicantsSlice = createSlice({
    name: "Job Applicants Graph",
    initialState: {
        loading: false,
        error: null,
        jobApplicantsGraph: null
    },
    extraReducers: (builder) => {
        builder.addCase(getJobWiseApplicants.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getJobWiseApplicants.fulfilled, (state, action) => {
            state.loading = false;
            state.jobApplicantsGraph = action.payload
        })
        builder.addCase(getJobWiseApplicants.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export default getJobWiseApplicantsSlice.reducer