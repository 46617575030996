import { Button } from "../../ui/button"
import CommonModal from "../../reusable/modals/CommonModal"
import { useDispatch, useSelector } from "react-redux"
import { setApplicantStatus, setRoundStatus } from "../../../redux/features/drive-template/getDriveTemplateDetailsSlice"
import { Icon } from "../../ui/icon"
import { Icons } from "../../../utils/icons"
import { Input } from "../../ui/input"
import { Label } from "../../ui/label"
import { useState } from "react"
import { X } from "lucide-react"
import { ApplicantStatuses, RoundStatuses, TemplateRoundStatus, ToastEnum } from "../../../utils/enums"
import { deleteTempApplicantStatus, deleteTempRoundStatus, resetRoundStatuses, setTempApplicantStatus, setTempRoundStatus } from "../../../redux/features/drive-template/updateDriveTemplateStatusSlice"
import { createApplicantStatus, createRoundStatus } from "../../../redux/features/drive-template/createDriveTemplateRoundStatusSlice"
import { useParams } from "react-router-dom"
import ButtonLoading from "../../reusable/loaders/ButtonLoading"
import { deleteApplicantStatus, deleteRoundStatus } from "../../../redux/features/drive-template/deleteDriveTemplateRoundStatusSlice"
import snackbar from "../../reusable/snackbar"

const TemplateRoundManage = ({ type, roundId }) => {

    const { id: templateId } = useParams()

    const { roundStatuses, roundApplicantStatuses, rounds, loading } = useSelector(state => state.driveTemplateReducer)
    const { tempRoundStatus, tempApplicantStatus } = useSelector(state => state.updateDriveTemplateReducer)
    const { loading: createRoundLoading } = useSelector(state => state.createDriveTemplateRoundStatusReducer)
    const dispatch = useDispatch()
    const [isAdding, setIsAdding] = useState(false)
    const [newStatus, setNewStatus] = useState('');

    const handleAddStatusClick = () => {
        setIsAdding(true);
    };

    const handleAddNewStatus = () => {
        if (newStatus === "") {
            return snackbar(ToastEnum.WARNING, "Status cannot be empty")
        }
        type === TemplateRoundStatus.ROUNDSTATUS ? dispatch(setTempRoundStatus({ title: newStatus, id: roundId })) : dispatch(setTempApplicantStatus({ title: newStatus, id: roundId }))
        setNewStatus('')
    }

    const handleSaveNewStatus = () => {
        setIsAdding(false);
        type === TemplateRoundStatus.ROUNDSTATUS ? dispatch(createRoundStatus({ roundId, templateId, tempRoundStatus })) : dispatch(createApplicantStatus({ roundId, templateId, tempApplicantStatus }))
        dispatch(resetRoundStatuses())
        setNewStatus('');
    };

    const handleDeleteSavedStatus = (statusId) => {
        type === TemplateRoundStatus.ROUNDSTATUS ? dispatch(deleteRoundStatus({ roundId, templateId, statusId })) : dispatch(deleteApplicantStatus({ roundId, templateId, statusId }))
    };

    const handleDeleteTempStatus = (statusId) => {
        type === TemplateRoundStatus.ROUNDSTATUS ? dispatch(deleteTempRoundStatus(statusId)) : dispatch(deleteTempApplicantStatus(statusId))
    }
    const title = type === TemplateRoundStatus.ROUNDSTATUS ? "Manage Round Statuses" : "Manage Applicant Statuses"

    const handleRoundDetails = () => {
        type === 'round-status' ? dispatch(setRoundStatus(roundId)) : dispatch(setApplicantStatus(roundId))
    }
    const triggerButton = () =>
        <Button onClick={handleRoundDetails} variant="ghost" className="flex items-center gap-x-1 font-semibold">
            <p className="text-primaryButtonColor">Manage</p>
            <Icon icon={Icons.EDIT} className="text-primaryButtonColor w-3 h-3" />
        </Button>

    const footerButton = () => {
        return (
            <div className="flex items-center gap-x-4">
                <Button onClick={isAdding ? handleAddNewStatus : handleAddStatusClick} variant="light" className="rounded-3xl shadow-md">Add Status</Button>
                {
                    createRoundLoading ? <ButtonLoading /> :
                        <Button onClick={handleSaveNewStatus} variant="primary" className="rounded-3xl shadow-md">Save</Button>
                }
            </div>
        )
    }

    return (
        <div>
            <CommonModal width={350} triggerButton={triggerButton()} title={title} footerButton={footerButton()}>
                <div className="flex items-center gap-y-2 flex-col w-full my-4">
                    {
                        loading ? <p>loading</p> :
                            type === 'round-status' ?
                                <>
                                    {roundStatuses?.map((roundStatus) =>
                                        <div key={roundStatus.id} className="flex w-full items-center justify-between gap-x-4">
                                            <h2 className="shadow-md px-2 py-2 text-base w-full text-black font-medium border rounded-lg">{roundStatus.title}</h2>
                                            {
                                                roundStatus &&
                                                roundStatus.title !== RoundStatuses.ONGOING &&
                                                roundStatus.title !== RoundStatuses.SCHEDULED &&
                                                roundStatus.title !== RoundStatuses.COMPLETED && (
                                                    <Icon onClick={() => handleDeleteSavedStatus(roundStatus.id)} icon={Icons.DELETE} className="text-black cursor-pointer" />
                                                )
                                            }

                                        </div>
                                    )}
                                    {tempRoundStatus?.filter(roundStatus => roundStatus.id === roundId).map((roundStatus, i) => (
                                        <div key={i} className="flex w-full items-center justify-between gap-x-4">
                                            <h2 className="shadow-md px-2 py-2 text-base w-full text-black font-medium border rounded-lg">{roundStatus.title}</h2>
                                            <Icon onClick={() => handleDeleteTempStatus(roundStatus.statusId)} icon={Icons.DELETE} className="text-black  cursor-pointer" />
                                        </div>
                                    ))}
                                </>
                                :
                                <>
                                    {roundApplicantStatuses?.map((applicantStatus) =>
                                        <div key={applicantStatus.id} className="flex w-full items-center justify-between gap-x-4">
                                            <h2 className="shadow-md px-2 py-2 text-base w-full text-black font-medium border rounded-lg">{applicantStatus.title}</h2>
                                            {
                                                applicantStatus &&
                                                applicantStatus.title !== ApplicantStatuses.ACCEPTED &&
                                                applicantStatus.title !== ApplicantStatuses.APPLIED &&
                                                applicantStatus.title !== ApplicantStatuses.REJECTED &&
                                                applicantStatus.title !== ApplicantStatuses.SHORTLISTED &&
                                                applicantStatus.title !== ApplicantStatuses.WITHDRAWN &&
                                                (
                                                    <Icon onClick={() => handleDeleteSavedStatus(applicantStatus.id)} icon={Icons.DELETE} className="text-black cursor-pointer" />
                                                )
                                            }
                                        </div>
                                    )}
                                    {tempApplicantStatus?.filter(applicantStatus => applicantStatus.id === roundId).map((applicantStatus, i) => (
                                        <div key={i} className="flex w-full items-center justify-between gap-x-4">
                                            <h2 className="shadow-md px-2 py-2 text-base w-full text-black font-medium border rounded-lg">{applicantStatus.title}</h2>
                                            <Icon onClick={() => handleDeleteTempStatus(applicantStatus.statusId)} icon={Icons.DELETE} className="text-black" />
                                        </div>
                                    ))}
                                </>
                    }
                    {isAdding && (
                        <div className="flex flex-col w-full gap-y-2 mt-2">
                            <Label>Status Title</Label>
                            <div className="flex items-center gap-x-4">
                                <Input onKeyPress={({ key }) => key === "Enter" && handleAddNewStatus()} value={newStatus} onChange={(e) => setNewStatus(e.target.value)} placeholder="Enter new status" />
                                <X onClick={() => setIsAdding(false)} className="cursor-pointer" />
                            </div>
                            <p className="text-secondaryColor text-sm ">Click on Add status button or hit enter to add the status</p>
                        </div>
                    )}
                </div>
            </CommonModal>
        </div>
    )
}
export default TemplateRoundManage