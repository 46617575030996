import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import CommonGraphCard from "../reusable/CommonGraphCard"
import { useSelector } from 'react-redux';
import GraphLoader from '../reusable/loaders/GraphLoader';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const HiringGraph = () => {

    const { hiringGraphStats, loading } = useSelector(state => state.hiringGraphReducer)

    const labels = hiringGraphStats?.trend.slice(1).map(item => item[0]);
    const drivesData = hiringGraphStats?.trend.slice(1).map(item => item[1]);
    const jobsData = hiringGraphStats?.trend.slice(1).map(item => item[2]);

    const data = {
        labels: labels,
        datasets: [
            {
                label: hiringGraphStats?.trend[0][1],
                data: drivesData,
                fill: false,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
            },
            {
                label: hiringGraphStats?.trend[0][2],
                data: jobsData,
                fill: false,
                backgroundColor: 'rgba(153,102,255,0.4)',
                borderColor: 'rgba(153,102,255,1)',
            }
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                min: 0
            }
        }
    };
    return (
        <div>
            <CommonGraphCard loading={loading} loader={<GraphLoader />} title={"Hiring Progress"}>
                <Line data={data} options={options} />
            </CommonGraphCard>
        </div>
    )
}
export default HiringGraph