import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const getHiringGraph = createAsyncThunk('getHiringGraph', async (year, { rejectWithValue }) => {
    try {
        const { data } = await apiService.get(`/graphs/analytics/hiring-graph/${year}`)
        return data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getHiringGraphSlice = createSlice({
    name: "Hiring Graph",
    initialState: {
        loading: false,
        error: null,
        hiringGraphStats: null
    },
    extraReducers: (builder) => {
        builder.addCase(getHiringGraph.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getHiringGraph.fulfilled, (state, action) => {
            state.loading = false;
            state.hiringGraphStats = action.payload
        })
        builder.addCase(getHiringGraph.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export default getHiringGraphSlice.reducer