import { useMemo, useState, useEffect } from "react"
import CommonDropdown from "../../reusable/dropdowns/CommonDropdown"
import { Button } from "../../ui/button"
import ViewDriveInvitationModal from "./ViewDriveInvitationModal"
import AcceptDriveInvitationModal from "./AcceptDriveInvitationModal"
import CommonAlertDialog from "../../reusable/modals/CommonAlertDialog"
import { useDispatch, useSelector } from "react-redux"
import { getCollegeBranch } from "../../../../src/redux/features/college/getCollegeBranchSlice"
import { rejectDriveInvite } from "../../../../src/redux/features/drives/rejectDriveInviteSlice"

const DriveInvitationDropdown = ({ drive, college, driveId, jobTitleItems, templateItems }) => {

    const [openRejectModal, setOpenRejectModal] = useState(false)
    const [openAcceptModal, setOpenAcceptModal] = useState(false)
    const [openViewInvitationModal, setOpenViewInvitationModal] = useState(false)
    const { branches } = useSelector(
        (state) => state.collegeBranchReducer
    );

    const branchDropdownItems =
        branches.length > 0
            ? branches.map((branch) => {
                return {
                    label: branch,
                    value: branch,
                };
            })
            : [];

    const dispatch = useDispatch()
    const dropdownItems = useMemo(() => [
        {
            name: "View Invitation",
            func: () => setOpenViewInvitationModal(true)
        },
        {
            name: "Accept",
            func: () => {
                setOpenAcceptModal(true);
                dispatch(getCollegeBranch({ collegeIds: [college.id] }));
            }
        },
        {
            name: "Reject",
            func: () => setOpenRejectModal(true)
        },
    ], [college.id, dispatch]);


    const handleRejectInvite = () => {
        dispatch(rejectDriveInvite(driveId))
    }
    const rejectActionButton = <Button variant="primary" onClick={handleRejectInvite}>Confirm</Button>

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <CommonDropdown items={dropdownItems}>
                <Button variant="primary" size="sm">View Invitation</Button>
            </CommonDropdown>

            <ViewDriveInvitationModal college={college} note={drive.requestNote} open={openViewInvitationModal} setOpen={setOpenViewInvitationModal} />
            <AcceptDriveInvitationModal branchDropdownItems={branchDropdownItems} jobTitleItems={jobTitleItems} templateItems={templateItems} open={openAcceptModal} setOpen={setOpenAcceptModal} college={college} drive={drive} />
            <CommonAlertDialog open={openRejectModal} setOpen={setOpenRejectModal} title={"Reject Invitation"} description={`This action can't be undone! Are you sure you want to reject invitation from ${college.title}`} actionButton={rejectActionButton} />
        </div>
    )
}
export default DriveInvitationDropdown