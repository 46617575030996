import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";

export const acceptApplication = createAsyncThunk('acceptApplication', async (obj, { rejectWithValue }) => {
    try {
        await apiService.post(`/job-applications/${obj.applicationId}/accept`, obj.body)
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})
export const rejectApplication = createAsyncThunk('rejectApplication', async (obj, { rejectWithValue }) => {
    try {
        await apiService.post(`/job-applications/${obj.applicationId}/reject`)
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const updateApplicationSlice = createSlice({
    name: "Update Application",
    initialState: {
        loading: false,
        error: null,
        success: false,
        successMessage: null
    },
    reducers: {
        resetUpdateApplicationSuccess: (state) => {
            state.successMessage = null;
            state.success = false
        },
        resetUpdateApplicationError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(acceptApplication.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(acceptApplication.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
            state.successMessage = "Applicant move to next round Successfully"
        })
        builder.addCase(acceptApplication.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data.message
        })
        builder.addCase(rejectApplication.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(rejectApplication.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true
            state.successMessage = "Applicant Rejected Successfully"
        })
        builder.addCase(rejectApplication.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.response.data.message
        })
    }
})
export const { resetUpdateApplicationSuccess, resetUpdateApplicationError } = updateApplicationSlice.actions
export default updateApplicationSlice.reducer