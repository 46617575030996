import { useSelector } from "react-redux"
import Common from "../reusable/Common"
import UpcomingEventsLoader from "../reusable/loaders/UpcomingEventsLoader"
import { formatDate } from "../../../src/utils/formatDate"

const UpcomingEvents = () => {

    const { loading, events } = useSelector(state => state.eventsReducer)


    const eventData = events.flatMap((drive) => [
        { name: `${drive.name} Starts`, date: drive.startDate },
        { name: `${drive.name} Campus Visit`, date: drive.driveDate },
        { name: `${drive.name} Ends`, date: drive.endDate },
    ]).filter((event) => event.date)
        .map(event => {
            return {
                name: event.name,
                date: new Date(event.date)
            }
        }).sort((a, b) => a.date - b.date);
    return (
        <div className="col-span-1 row-span-2">

            <Common hideButton={true} title={"Upcoming Events"} link={"/upcoming-events"}>
                {
                    loading ? <UpcomingEventsLoader /> :
                        <div className="flex flex-col gap-y-4 pb-4">
                            {eventData.length > 0 ? eventData.map((event, i) =>
                                <div key={i} className="flex items-center gap-x-2">
                                    <p className="font-semibold text-sm ">{formatDate(event.date)}</p>
                                    <p className="font-semibold text-sm">|</p>
                                    <p className="font-medium text-sm line-clamp-2">{event.name}</p>
                                </div>
                            ) : <h2 className="w-full text-center">
                                No Events
                            </h2>
                            }
                        </div>
                }
            </Common>
        </div>)
}
export default UpcomingEvents