import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/auth/authSlice";
import recentlyConnectedCollegeSlice from "./features/networks/recentlyConnectedCollegeSlice";
import getNotificationSlice from "./features/notifications/getNotificationSlice";
import getJobSlice from "./features/jobs/getJobSlice";
import getCampusRecruitmentSlice from "./features/drives/getCampusRecruitmentSlice";
import getJobTypeCtcSlice from "./features/analytics/getJobTypeCtcSlice";
import getDriveTemplateDetailsSlice from "./features/drive-template/getDriveTemplateDetailsSlice";
import createDriveTemplateSlice from "./features/drive-template/createDriveTemplateSlice";
import updateDriveTemplateStatusSlice from "./features/drive-template/updateDriveTemplateStatusSlice";
import createTemplateRoundSlice from "./features/drive-template/createTemplateRoundSlice";
import sidebarSlice from "./features/sidebarSlice";
import createDriveTemplateRoundStatusSlice from "./features/drive-template/createDriveTemplateRoundStatusSlice";
import deleteDriveTemplateRoundStatusSlice from "./features/drive-template/deleteDriveTemplateRoundStatusSlice";
import getDriveDetailsSlice from "./features/drives/getDriveDetailsSlice";
import getDriveApplicationsSlice from "./features/drives/getDriveApplicationsSlice";
import getJobLocationsSlice from "./features/jobs/getJobLocationsSlice";
import getDriveStatusSlice from "./features/drives/getDriveStatusSlice";
import getJobDetailsSlice from "./features/jobs/getJobDetailsSlice";
import getJobApplicationSlice from "./features/jobs/getJobApplicationSlice";
import getApplicationDetailSlice from "./features/applications/getApplicationDetailSlice";
import createJobsSlice from "./features/jobs/createJobsSlice";
import getApplicantResumeSlice from "./features/applications/getApplicantResumeSlice";
import updateApplicationSlice from "./features/applications/updateApplicationSlice";
import getDriveTypeSlice from "./features/drives/getDriveTypeSlice";
import getCollegeBranchSlice from "./features/college/getCollegeBranchSlice";
import getInviteCollegeSlice from "./features/college/getInviteCollegeSlice";
import createDriveSlice from "./features/drives/createDriveSlice";
import getAnalyticsStatsSlice from "./features/analytics/getAnalyticsStatsSlice";
import getHiringGraphSlice from "./features/analytics/getHiringGraphSlice";
import getCtcStatsSlice from "./features/analytics/getCtcStatsSlice";
import getJobOpeningsGraphSlice from "./features/analytics/getJobOpeningsGraphSlice";
import getJobWiseApplicantsSlice from "./features/analytics/getJobWiseApplicantsSlice";
import getJobsFilledSlice from "./features/analytics/getJobsFilledSlice";
import getGenderWiseApplicantsSlice from "./features/analytics/getGenderWiseApplicantsSlice";
import getNewRecurringDriveSlice from "./features/analytics/getNewRecurringDriveSlice";
import getAnalyticsNetworkSlice from "./features/analytics/getAnalyticsNetworkSlice";
import getCollegeNetworkDetailsSlice from "./features/networks/getCollegeNetworkDetailsSlice";
import createNetworkSlice from "./features/networks/createNetworkSlice";
import withdrawNetworkSlice from "./features/networks/withdrawNetworkSlice";
import getProfileDetailsSlice from "./features/profile/getProfileDetailsSlice";
import updateProfileSlice from "./features/profile/updateProfileSlice";
import getTeamSlice from "./features/team/getTeamSlice";
import getMemberDetailsSlice from "./features/team/getMemberDetailsSlice";
import addMemberSlice from "./features/team/addMemberSlice";
import updateMemberSlice from "./features/team/updateMemberSlice";
import createJobFormSlice from "./features/jobs_form/createJobFormSlice";
import getMemberDesignationSlice from "./features/team/getMemberDesignationSlice";
import getEventsSlice from "./features/drives/getEventsSlice";
import updateDriveSlice from "./features/drives/updateDriveSlice";
import updateDriveRoundStatusSlice from "./features/drives/updateDriveRoundStatusSlice";
import startDriveSlice from "./features/drives/startDriveSlice";
import endDriveSlice from "./features/drives/endDriveSlice";
import startNextRoundSlice from "./features/drives/startNextRoundSlice";
import updateConnectionStatusSlice from "./features/networks/updateConnectionStatusSlice";
import acceptDriveInviteSlice from './features/drives/acceptDriveInviteSlice'
import rejectDriveInviteSlice from './features/drives/rejectDriveInviteSlice'
export const store = configureStore({
    reducer: {
        //drawer
        sidebarReducer: sidebarSlice,

        //auth reducer
        authReducer: authSlice,

        //home page reducers
        recentlyConnectedCollegeReducer: recentlyConnectedCollegeSlice,
        notificationReducer: getNotificationSlice,
        campusRecruitmentReducer: getCampusRecruitmentSlice,
        jobTypeCtcReducer: getJobTypeCtcSlice,
        eventsReducer: getEventsSlice,
        //drive reducers
        createDriveReducer: createDriveSlice,
        driveDetailsReducer: getDriveDetailsSlice,
        driveApplicationReducer: getDriveApplicationsSlice,
        driveStatusReducer: getDriveStatusSlice,
        driveTypeReducer: getDriveTypeSlice,
        updateDriveReducer: updateDriveSlice,
        updateDriveRoundStatusReducer: updateDriveRoundStatusSlice,
        startDriveReducer: startDriveSlice,
        endDriveReducer: endDriveSlice,
        startNextRoundReducer: startNextRoundSlice,
        acceptDriveInviteReducer : acceptDriveInviteSlice,
        rejectDriveInviteReducer : rejectDriveInviteSlice,
        //college Reducers
        collegeBranchReducer: getCollegeBranchSlice,
        collegeInviteReducer: getInviteCollegeSlice,

        //template reducers
        driveTemplateReducer: getDriveTemplateDetailsSlice,
        createTemplateReducer: createDriveTemplateSlice,
        updateDriveTemplateReducer: updateDriveTemplateStatusSlice,
        createTemplateRoundReducer: createTemplateRoundSlice,
        createDriveTemplateRoundStatusReducer: createDriveTemplateRoundStatusSlice,
        deleteDriveTemplateRoundStatusReducer: deleteDriveTemplateRoundStatusSlice,

        //jobs Reducers
        jobsReducer: getJobSlice,
        jobLocationReducer: getJobLocationsSlice,
        jobDetailReducer: getJobDetailsSlice,
        jobApplicationReducer: getJobApplicationSlice,
        createJobReducer: createJobsSlice,
        createJobFormReducer: createJobFormSlice,

        //application reducers
        applicationDetailReducer: getApplicationDetailSlice,
        applicantResumeReducer: getApplicantResumeSlice,
        updateApplicationReducer: updateApplicationSlice,

        //analytics Reducers
        anayticsStatsReducer: getAnalyticsStatsSlice,
        hiringGraphReducer: getHiringGraphSlice,
        ctcStatsReducer: getCtcStatsSlice,
        getJobOpeningsReducer: getJobOpeningsGraphSlice,
        getJobApplicantsReducer: getJobWiseApplicantsSlice,
        jobsFilledReducer: getJobsFilledSlice,
        genderWiseApplicantsReducer: getGenderWiseApplicantsSlice,
        newRecurringDriveReducer: getNewRecurringDriveSlice,

        //network reducers
        networkDetailsReducer: getCollegeNetworkDetailsSlice,
        createNetworkReducer: createNetworkSlice,
        withdrawNetworkReducer: withdrawNetworkSlice,
        analyticsNetworkReducer: getAnalyticsNetworkSlice,
        updateConnectionStatusReducer: updateConnectionStatusSlice,
        //profile reducers
        getProfileDetailsReducer: getProfileDetailsSlice,
        updateProfileReducer: updateProfileSlice,

        //team reducers
        getTeamReducer: getTeamSlice,
        getMemberDetailsReducer: getMemberDetailsSlice,
        addMemberReducer: addMemberSlice,
        udpateMemberReducer: updateMemberSlice,
        designationReducer: getMemberDesignationSlice
    }
})