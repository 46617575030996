import { useSelector } from "react-redux"
import CommonSelect from "../reusable/dropdowns/CommonSelect"
import AnalyticsHeaderCard from "./AnalyticsHeaderCard"
import AnalyticsStatsLoader from "../reusable/loaders/AnalyticsStatsLoader"

const date = new Date()
const currentYear = date.getFullYear()
const yearItems = [
    currentYear,
    currentYear - 1,
    currentYear - 2,
    currentYear - 3,
]

const AnalyticsHeader = ({ changeSelectedYear }) => {

    const { stats, loading } = useSelector(state => state.anayticsStatsReducer)

    return (
        <div className="flex flex-col gap-y-4">
            <div className="flex items-center justify-between">
                <h1 className="font-semibold text-xl">Placement Statistics</h1>
                <CommonSelect defaultValue={currentYear} hideNone={true} items={yearItems} selectPlaceholder={"Select year"} handleSelectChange={changeSelectedYear} />
            </div>
            {
                loading ? <AnalyticsStatsLoader /> :
                    <>
                        <div className="grid grid-cols-6 gap-6">
                            {stats && Object?.entries(stats).map(([key, value]) => (
                                <div key={key}>
                                    <AnalyticsHeaderCard title={key} count={value} />
                                </div>
                            ))}
                        </div>
                    </>
            }
        </div>
    )
}
export default AnalyticsHeader