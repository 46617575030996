import { z } from "zod"
import CommonModal from "../reusable/modals/CommonModal"
import { Button } from "../ui/button"
import { useDispatch } from "react-redux"
import { addMember } from "../../redux/features/team/addMemberSlice"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useState } from "react"
import { Form } from "../ui/form"
import InputFormField from "../reusable/forms/InputFormField"
import ToggleButton from "../reusable/forms/ToggleButton"
import { generatePassword } from "../../utils/generatePassword"

const formSchema = z.object({
    email: z.string().email(),
    name: z.string(),
    designation: z.string(),
    password: z.string()
})
const AddMemberModal = () => {

    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const triggerButton = <Button onClick={() => setOpen(true)} variant="primary" className="rounded-3xl">
        + Add Member
    </Button>

    const [modifyUsers, setModifyUsers] = useState(false)
    const [scheduleDrive, setScheduleDrive] = useState(false)
    const [modifyDrive, setModifyDrive] = useState(false)
    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: "",
            name: "",
            designation: "",
            password: ""
        }
    })

    function onSubmit(values) {
        const body = {
            ...values,
            manageUsers: modifyUsers,
            canScheduleDrive: scheduleDrive,
            canModifyDrive: modifyDrive
        }
        dispatch(addMember(body))
        setOpen(false)
        form.reset({
            email: "",
            name: "",
            designation: "",
            password: ""
        })
    }

    const handleGeneratePassword = (e) => {
        e.preventDefault()
        const newPassword = generatePassword();
        form.setValue("password", newPassword);
    };
    return (
        <CommonModal open={open} setOpen={setOpen} className="min-w-[600px]" triggerButton={triggerButton} title={"Add Member"}>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2 mt-2">
                    <h2 className="font-semibold">System Details</h2>
                    <hr />

                    <div className="flex items-center justify-between gap-x-10">
                        <InputFormField className={"w-full"} name="email" control={form.control} placeholder={"Enter email"} label={"Email"} type={"email"} />
                    </div>
                    <div className="flex items-end justify-between gap-x-10">
                        <InputFormField disabled={true} className={"w-1/2"} name="password" control={form.control} placeholder={"Enter password"} label={"Password"} type={"password"} />
                        <Button onClick={handleGeneratePassword} variant="primary">
                            Generate Password
                        </Button>
                    </div>
                    <h2 className="font-semibold">Personal Details</h2>
                    <hr />

                    <div className="flex items-center justify-between gap-x-10">
                        <InputFormField className={"w-1/2"} name="name" control={form.control} placeholder={"Enter name"} label={"Name"} type={"text"} />
                        <InputFormField className={"w-1/2"} name="designation" control={form.control} placeholder={"Enter designation"} label={"Designation"} type={"text"} />
                    </div>

                    <h2 className="font-semibold">Permissions</h2>

                    <h2 className="font-medium text-secondaryColor">User Management</h2>
                    <hr />

                    <ToggleButton handleToggleChange={(e) => setModifyUsers(e.target.checked)} label={"Modify Users"} isChecked={modifyUsers} />

                    <h2 className="font-medium text-secondaryColor">Drive Management</h2>
                    <hr />

                    <div className="flex items-center gap-x-10">
                        <ToggleButton handleToggleChange={(e) => setScheduleDrive(e.target.checked)} label={"Can Schedule Drive"} isChecked={scheduleDrive} />
                        <ToggleButton handleToggleChange={(e) => setModifyDrive(e.target.checked)} label={"Can Modify Drive"} isChecked={modifyDrive} />
                    </div>

                    <div className="flex justify-end w-full">
                        <Button type="submit" className="rounded-3xl px-6 py-4 shadow-md" variant="primary">
                            Save
                        </Button>
                    </div>
                </form>
            </Form>
        </CommonModal>
    )
}
export default AddMemberModal