import axios from 'axios';
import { auth } from '../config/firebaseConfig';
import { BFF_URL } from '../utils/constants';

class ApiService {
    axiosInstance
    constructor() {
        this.axiosInstance = axios.create({
            baseURL: BFF_URL
        });

        // Request interceptor for adding Authorization header
        this.axiosInstance.interceptors.request.use(
            async config => {
                const token = await this.getHeaderToken();
                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );
    }
    async getHeaderToken() {
        const token = await auth.currentUser.getIdToken()
        return token
    }

    get(url, params) {
        return this.axiosInstance.get(url, { params });
    }

    // POST request
    post(url, data) {
        return this.axiosInstance.post(url, data);
    }

    // PUT request
    put(url, data) {
        return this.axiosInstance.put(url, data);
    }

    // PATCH request
    patch(url, data) {
        return this.axiosInstance.patch(url, data);
    }

    // DELETE request
    delete(url) {
        return this.axiosInstance.delete(url);
    }
}


export default new ApiService();
