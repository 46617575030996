import { Alert, AlertDescription, AlertTitle } from "../ui/alert"

const CommonAlert = ({ title, description, variant }) => {
    return (
        <Alert variant={variant}>
            <AlertTitle>{title}</AlertTitle>
            <AlertDescription>
                {description}
            </AlertDescription>
        </Alert>
    )
}
export default CommonAlert