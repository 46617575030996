import { useForm } from "react-hook-form"
import InputFormField from "../../reusable/forms/InputFormField"
import MultiSelectFormField from "../../reusable/forms/MultiSelectFormField"
import SelectFormField from "../../reusable/forms/SelectFormField"
import CommonModal from "../../reusable/modals/CommonModal"
import { zodResolver } from "@hookform/resolvers/zod"
import { conditionalSchema } from "../../../../src/form-schemas/drive-schema"
import { Form } from "../../ui/form"
import { useDispatch, useSelector } from "react-redux"
import TextAreaFormField from "../../reusable/forms/TextAreaFormField"
import { Button } from "../../ui/button"
import DatePicker from "react-multi-date-picker"
import { useEffect, useState } from "react"
import { Input } from "../../ui/input"
import { Label } from "../../ui/label"
import "react-multi-date-picker/styles/colors/red.css"
import { z } from "zod"
import { jobTitleSchema } from "../../../../src/form-schemas/job-title-schema"
import { branchSchema } from "../../../../src/form-schemas/branch-schema"
import snackbar from "../../reusable/snackbar"
import { ToastEnum } from "../../../../src/utils/enums"
import { format } from "date-fns"
import { acceptDriveInvite } from "../../../../src/redux/features/drives/acceptDriveInviteSlice"
import { Loader } from "lucide-react"

const invitationSchema = z.object({
  title: z.string().min(1, {
    message: "Drive Title is required"
  }),
  driveType: z.string().min(1, {
    message: "Drive Type is required"
  }),
  ctcMin: z.string().min(1, {
    message: "Min ctc is required"
  }),
  ctcMax: z.string().min(1, {
    message: "Max ctc is required"
  }),
  description: z.string().min(1, {
    message: "Description is required"
  }),
  openings: z.string().min(1, {
    message: "Openings is required"
  }),
  eligibilityCriteria: z.string().min(1, {
    message: "Eligibility Criteria is required"
  }),
  roundTemplateId: z.string().min(1, {
    message: "Select Round template"
  }),
  prefferedBranches: z.array(branchSchema).min(1, {
    message: "Preferred Branch is required"
  }),
  jobIds: z.array(jobTitleSchema).min(1, {
    message: "Select at least one Job"
  }),
})
const AcceptDriveInvitationModal = ({ open, setOpen, jobTitleItems, templateItems, branchDropdownItems, drive, college }) => {

  const [dateOfVisit, setDateOfVisit] = useState(new Date())
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const dispatch = useDispatch()
  const form = useForm({
    resolver: zodResolver(invitationSchema),
    defaultValues: {
      title: drive.title,
      driveType: drive.driveType,
      prefferedBranches: [],
      jobIds: [],
      roundTemplateId: "",
      openings: "",
      ctcMin: "",
      ctcMax: "",
      description: "",
      eligibilityCriteria: "",
    }
  })

  const onSubmit = (values) => {
    if (!startDate || !endDate) {
      return snackbar(ToastEnum.WARNING, "Start date or End date can't be empty")
    }

    const formattedBody = {
      ...values,
      dateOfVisit: format(new Date(dateOfVisit?.toString()), "yyyy-MM-dd"),
      startDate: format(new Date(startDate?.toString()), "yyyy-MM-dd"),
      endDate: format(new Date(endDate?.toString()), "yyyy-MM-dd"),
      prefferedBranches: values.prefferedBranches.map((branch) => branch.value),
      jobIds: values.jobIds.map((job) => job.value),
      ctcMin: Number(values.ctcMin),
      ctcMax: Number(values.ctcMax),
      openings: Number(values.openings),
    }

    const { driveType, ...rest } = formattedBody
    dispatch(acceptDriveInvite({ body: rest, driveId: drive.id }))
  }

  return (
    <div>
      <CommonModal
        className="max-w-2xl h-[70%] overflow-y-scroll"
        open={open} setOpen={setOpen} title={"Schedule Drive"}
      >
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div className="flex items-center gap-x-4">
              <InputFormField
                className={"w-1/2"}
                name="title"
                control={form.control}
                placeholder={"Enter Drive Title"}
                label={"Drive Title"}
                type={"text"}
              />
              <InputFormField
                className={"w-1/2"}
                disabled={true}
                name="driveType"
                control={form.control}
                placeholder={"Enter Drive Type"}
                label={"Drive Type"}
                type={"text"}
              />

            </div>
            {branchDropdownItems && branchDropdownItems.length > 0 && (
              <MultiSelectFormField
                className={"w-full"}
                name={"prefferedBranches"}
                control={form.control}
                placeholder="Select Preffered Branches for drive"
                label={"Select Preffered Branches"}
                options={branchDropdownItems}
              />
            )}
            <MultiSelectFormField
              className={"w-full"}
              name={"jobIds"}
              control={form.control}
              placeholder="Select Job roles for this drive"
              label={"Select Job Titles"}
              options={jobTitleItems}
            />
            <div className="flex items-center gap-x-4">
              <SelectFormField
                items={templateItems}
                name={"roundTemplateId"}
                control={form.control}
                placeholder={"Select Template"}
                label={"Template"}
                className={"w-1/2"}
              />
              <InputFormField
                className="w-1/2"
                name={"openings"}
                control={form.control}
                placeholder={"Enter Number of openings"}
                label={"No of openings"}
              />
            </div>
            <div className="flex items-center gap-x-4 w-full">
              <div className="w-full flex flex-col gap-y-3">
                <Label>Date of Visit</Label>
                <DatePicker
                  className="red"
                  value={dateOfVisit}
                  onChange={setDateOfVisit}
                  render={<Input className="w-full" placeholder="Enter Date of Visit" />}
                />
              </div>
            </div>
            <div className="flex items-center gap-x-4 w-full">
              <div className="w-1/2 flex flex-col gap-y-3">
                <Label>Start Date</Label>
                <DatePicker
                  className="red"
                  value={startDate}
                  onChange={setStartDate}
                  minDate={new Date()}
                  render={<Input className="" placeholder="Enter Starting date of drive" />}
                />
              </div>
              <div className="w-1/2 flex flex-col gap-y-3">
                <Label>End Date</Label>
                <DatePicker
                  className="red"
                  value={endDate}
                  onChange={setEndDate}
                  minDate={startDate}
                  render={<Input className="" placeholder="Enter Ending date of drive" />}
                />
              </div>
            </div>
            <div className="flex items-center gap-x-4">
              <InputFormField
                className="w-1/2"
                name={"ctcMin"}
                control={form.control}
                label={"Min Ctc"}
                placeholder={"Enter Min ctc"}
              />
              <InputFormField
                className="w-1/2"
                name={"ctcMax"}
                control={form.control}
                label={"Max Ctc"}
                placeholder={"Enter Max ctc"}
              />
            </div>
            <div className="flex items-center gap-x-4">
              <TextAreaFormField
                className="w-1/2"
                name={"description"}
                control={form.control}
                label={"Description"}
                placeholder={"Enter description of the job"}
              />
              <TextAreaFormField
                className="w-1/2"
                name={"eligibilityCriteria"}
                control={form.control}
                label={"Eligibility Criteria"}
                placeholder={"Enter eligibility criteria of the job"}
              />
            </div>

            <div className="flex justify-end w-full">
              <Button
                type="submit"
                variant="primary"
                className="rounded-3xl shadow-md"
              >
                Create
              </Button>
            </div>
          </form>
        </Form>
      </CommonModal>
    </div>
  )
}
export default AcceptDriveInvitationModal