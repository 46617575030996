import CommonModal from "../../reusable/modals/CommonModal";
import { Button } from "../../ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form } from "../../ui/form";

import InputFormField from "../../reusable/forms/InputFormField";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextAreaFormField from "../../reusable/forms/TextAreaFormField";
import DatePickerFormField from "../../reusable/forms/DatePickerFormField";
import SelectFormField from "../../reusable/forms/SelectFormField";
import MultiSelectFormField from "../../reusable/forms/MultiSelectFormField";
import { DriveTypes } from "../../../utils/enums";
import { conditionalSchema } from "../../../form-schemas/drive-schema";
import { getCollegeBranch } from "../../../redux/features/college/getCollegeBranchSlice";
import { createDrive } from "../../../redux/features/drives/createDriveSlice";
import ButtonLoading from "../../reusable/loaders/ButtonLoading";
import { addYears, format, addDays } from "date-fns";
import CommonAlert from "../../reusable/CommonAlert";

const CreateDriveModal = ({
  driveTypeDropdownItems,
  collegeDropdownItems,
  jobTitleDropdownItems,
  templateDropdownItems,
}) => {
  const { driveTypes } = useSelector((state) => state.driveTypeReducer);
  const { branches, loading } = useSelector(
    (state) => state.collegeBranchReducer
  );
  const { loading: createDriveLoading } = useSelector(
    (state) => state.createDriveReducer
  );

  const branchDropdownItems =
    branches.length > 0
      ? branches.map((branch) => {
        return {
          label: branch,
          value: branch,
        };
      })
      : [];
  const triggerButton = (
    <Button variant="primary" className="rounded-3xl">
      + Schedule Drive
    </Button>
  );

  const [open, setOpen] = useState(false);
  const [driveType, setDriveType] = useState(DriveTypes.ONCAMPUS);
  const dispatch = useDispatch();
  const form = useForm({
    resolver: zodResolver(conditionalSchema),
    defaultValues: {
      drive_name: "",
      drive_type: "",
      college_ids: [],
      preffered_branches: [],
      job_titles: [],
      round_template_id: "",
      openings: "",
      date_of_visit: null,
      start_date: null,
      end_date: null,
      ctc_min: "",
      ctc_max: "",
      description: "",
      eligibility_criteria: "",
    },
  });

  const driveTypeValue = form.watch("drive_type");
  const collegeIds = form.watch("college_ids");

  function onSubmit(values) {
    const formattedBody = {
      ...values,
      date_of_visit: format(values.date_of_visit, "yyyy-MM-dd"),
      start_date: format(values.start_date, "yyyy-MM-dd"),
      end_date: format(values.end_date, "yyyy-MM-dd"),
      preffered_branches: values.preffered_branches.map(
        (branch) => branch.value
      ),
      job_titles: values.job_titles.map((job) => job.value),
      college_ids:
        typeof values.college_ids === "string"
          ? values.college_ids.split()
          : values.college_ids.map((clg) => clg.value),
    };

    dispatch(createDrive(formattedBody));
  }

  useEffect(() => {
    const selectedDriveType = driveTypes.find(
      (drive) => drive.id === driveTypeValue
    );
    if (selectedDriveType?.label === DriveTypes.ONCAMPUS) {
      form.setValue("college_ids", "");
    } else {
      form.setValue("college_ids", []);
    }
    setDriveType(selectedDriveType?.label);
  }, [driveTypeValue, driveTypes, form]);

  useEffect(() => {
    if (Array.isArray(collegeIds) && collegeIds.length > 0) {
      const clgId = collegeIds.map((college) => college.value);
      dispatch(getCollegeBranch({ collegeIds: clgId }));
    } else if (typeof collegeIds === "string" && collegeIds != "") {
      dispatch(getCollegeBranch({ collegeIds: [collegeIds] }));
    }

    form.setValue("preffered_branches", []);
  }, [collegeIds, dispatch]);

  const today = new Date().toISOString().split("T")[0]; // Get today's date in yyyy-mm-dd format
  const oneYearFromToday = addYears(today, 1).toISOString().split("T")[0]; // Get one year from today in yyyy-mm-dd format
  const startDate = form.watch("start_date");

  useEffect(() => {
    if (startDate) {
      const minEndDate = addDays(new Date(startDate), 1)
        .toISOString()
        .split("T")[0];
      form.setValue("end_date", minEndDate);
    }
  }, [startDate, form]);

  useEffect(() => {
    if (driveTypeDropdownItems.length > 0) {
      form.reset({
        drive_type: driveTypeDropdownItems[1].value
      })
    }
  }, [driveTypeDropdownItems])

  return (
    <div>
      <CommonModal
        className="max-w-2xl h-[70%] overflow-y-scroll"
        triggerButton={triggerButton}
        title={"Create Drive"}
      >
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div className="flex items-center gap-x-4">
              <InputFormField
                className={"w-1/2"}
                name="drive_name"
                control={form.control}
                placeholder={"Enter Drive Title"}
                label={"Drive Title"}
                type={"text"}
              />
              <SelectFormField
                items={driveTypeDropdownItems}
                name={"drive_type"}
                control={form.control}
                placeholder={"Select Drive Type"}
                label={"Drive Type"}
                className={"w-1/2"}
              />
            </div>
            {driveType === DriveTypes.ONCAMPUS ? (
              <SelectFormField
                multiple={true}
                items={collegeDropdownItems}
                name={"college_ids"}
                control={form.control}
                placeholder={"Select College to schedule Drive"}
                label={"Select College"}
                className={"w-full"}
              />
            ) : (
              <MultiSelectFormField
                options={collegeDropdownItems}
                className={"w-full"}
                name={"college_ids"}
                control={form.control}
                placeholder="Select Colleges to schedule Drive"
                label={"Select Colleges"}
              />
            )}
            {
              collegeDropdownItems.length === 0 && <CommonAlert
                variant={"destructive"}
                title={"No Colleges available"}
                description={"Make sure that you are connected with college and there are no ongoing or scheduled drives with the college"} />
            }
            {branchDropdownItems.length > 0 && (
              <MultiSelectFormField
                className={"w-full"}
                name={"preffered_branches"}
                control={form.control}
                placeholder="Select Preffered Branches for drive"
                label={"Select Preffered Branches"}
                options={loading ? [] : branchDropdownItems}
              />
            )}
            <MultiSelectFormField
              className={"w-full"}
              name={"job_titles"}
              control={form.control}
              placeholder="Select Job roles for this drive"
              label={"Select Job Titles"}
              options={jobTitleDropdownItems}
            />
            <div className="flex items-center gap-x-4">
              <SelectFormField
                items={templateDropdownItems}
                name={"round_template_id"}
                control={form.control}
                placeholder={"Select Template"}
                label={"Template"}
                className={"w-1/2"}
              />
              <InputFormField
                className="w-1/2"
                name={"openings"}
                control={form.control}
                placeholder={"Enter Number of openings"}
                label={"No of openings"}
              />
            </div>
            <div className="flex items-center gap-x-4">
              <InputFormField
                className={"w-1/2"}
                name={"date_of_visit"}
                control={form.control}
                label={"Date of Visit"}
                placeholder={"Enter Date of visit"}
                type={"date"}
              />
            </div>
            <div className="flex items-center gap-x-4">
              <InputFormField
                className={"w-1/2"}
                name={"start_date"}
                control={form.control}
                label={"Start Date"}
                placeholder={"Enter Start Date"}
                type={"date"}
                min={today}
                max={oneYearFromToday}
              />
              <InputFormField
                className={"w-1/2"}
                name={"end_date"}
                control={form.control}
                label={"End Date"}
                placeholder={"Enter End Date"}
                type={"date"}
                min={
                  startDate
                    ? addDays(new Date(startDate), 1)
                      .toISOString()
                      .split("T")[0]
                    : today
                }
                max={oneYearFromToday}
              />
            </div>
            <div className="flex items-center gap-x-4">
              <InputFormField
                className="w-1/2"
                name={"ctc_min"}
                control={form.control}
                label={"Min Ctc"}
                placeholder={"Enter Min ctc"}
              />
              <InputFormField
                className="w-1/2"
                name={"ctc_max"}
                control={form.control}
                label={"Max Ctc"}
                placeholder={"Enter Max ctc"}
              />
            </div>
            <div className="flex items-center gap-x-4">
              <TextAreaFormField
                className="w-1/2"
                name={"description"}
                control={form.control}
                label={"Description"}
                placeholder={"Enter description of the job"}
              />
              <TextAreaFormField
                className="w-1/2"
                name={"eligibility_criteria"}
                control={form.control}
                label={"Eligibility Criteria"}
                placeholder={"Enter eligibility criteria of the job"}
              />
            </div>

            <div className="flex justify-end w-full">
              {createDriveLoading ? (
                <ButtonLoading />
              ) : (
                <Button
                  type="submit"
                  variant="primary"
                  className="rounded-3xl shadow-md"
                >
                  Save
                </Button>
              )}
            </div>
          </form>
        </Form>
      </CommonModal>
    </div>
  );
};
export default CreateDriveModal;
