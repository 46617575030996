import CommonModal from "../../reusable/modals/CommonModal"
import { Button } from "../../ui/button"
import { Form } from "../../ui/form"
import { Label } from "../../ui/label"
import { Textarea } from "../../ui/textarea"

const ViewDriveInvitationModal = ({ note, college, open, setOpen }) => {

    return (
        <div>
            <CommonModal title={`Invitation from ${college?.title}`} open={open} setOpen={setOpen}>
                <div className="flex flex-col gap-y-2 mt-2">
                    <Label className="text-base font-medium">
                        Note
                    </Label>
                    {
                        note ?
                            <Textarea className="h-[150px]" disabled={true}>{note}</Textarea> :
                            <p>No Note attached</p>
                    }
                </div>
            </CommonModal>
        </div>
    )
}
export default ViewDriveInvitationModal