import NetworkDetailsHeader from "../../components/networks/NetworkDetailsHeader"
import { getCollegeNetworkDetails } from "../../redux/features/networks/getCollegeNetworkDetailsSlice"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import NetworkDetailsInfo from "../../components/networks/NetworkDetailsInfo"
import NetworkDetailsLoader from "../../components/reusable/loaders/NetworkDetailsLoader"

const NetworkDetails = () => {

  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.networkDetailsReducer)
  const { collegeId } = useParams()
  useEffect(() => {
    dispatch(getCollegeNetworkDetails(collegeId))
  }, [])

  return (
    <div>
      {
        loading ? <NetworkDetailsLoader /> :
          <>
            <NetworkDetailsHeader />
            <NetworkDetailsInfo />
          </>
      }
    </div>
  )
}
export default NetworkDetails