import { useDispatch, useSelector } from "react-redux"
import CommonModal from "../../reusable/modals/CommonModal"
import { Button } from "../../ui/button"
import { Textarea } from "../../ui/textarea"
import { updateConnectionStatus } from "../../../redux/features/networks/updateConnectionStatusSlice"

const ViewInvitationModal = ({ collegeId, description, connectionId, collegeName }) => {

    const dispatch = useDispatch()
    const { loading } = useSelector(state => state.updateConnectionStatusReducer)

    const updateStatus = (type) => {
        dispatch(updateConnectionStatus({ connectionId, type }))
    }
    const triggerButton = <Button variant="primary" size="sm">View Invitation</Button>
    const footerButton = <div className="flex items-center justify-end gap-x-4">
        <Button variant="outline" onClick={() => updateStatus("reject")}>Reject</Button>
        <Button variant="primary" onClick={() => updateStatus("accept")}>Accept</Button>
    </div>

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <CommonModal title={`Invitation from ${collegeName}`} footerButton={footerButton} triggerButton={triggerButton}>
                <Textarea disabled={true}>{description}</Textarea>
            </CommonModal>
        </div>
    )
}
export default ViewInvitationModal