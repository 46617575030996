import { useSelector } from "react-redux"
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import CommonGraphCard from "../reusable/CommonGraphCard";
import PieChartLoader from "../reusable/loaders/PieChartLoader";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);
const JobsFilledGraph = () => {
  const { jobsFilledGraph, loading } = useSelector(state => state.jobsFilledReducer)

  const labels = jobsFilledGraph?.trends.slice(1).map(item => item[0]);
  const dataCounts = jobsFilledGraph?.trends.slice(1).map(item => item[1]);

  const data = {
    labels,
    datasets: [
      {
        label: 'Jobs Filled',
        data: dataCounts,
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
          'rgba(255, 159, 64, 0.6)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },

    },
  };

  return (
    <div>
      <CommonGraphCard loading={loading} loader={<PieChartLoader />} title={"Jobs Filled"}>
        <Pie data={data} options={options} />
      </CommonGraphCard>
    </div>
  );
}
export default JobsFilledGraph