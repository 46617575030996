import { useSelector } from "react-redux";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import CommonGraphCard from "../reusable/CommonGraphCard";
import PieChartLoader from "../reusable/loaders/PieChartLoader";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
);

const AnalyticsNetwork = () => {
    const { network, loading } = useSelector(state => state.analyticsNetworkReducer)

    const labels = network?.trends.slice(1).map(item => item[0]);
    const dataCounts = network?.trends.slice(1).map(item => item[1]);

    const data = {
        labels,
        datasets: [
            {
                label: 'Network',
                data: dataCounts,
                backgroundColor: [
                    '#FEB8B9',
                    '#D77275',
                ],
                borderWidth: 0
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };

    return (
        <div>
            <CommonGraphCard title={"Network statistics"}>
                {
                    loading ? <PieChartLoader /> :
                        <Pie data={data} options={options} />
                }
            </CommonGraphCard>
        </div>
    );
}
export default AnalyticsNetwork